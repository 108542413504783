import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import Nav from "../../Common/Nav";
import ClientNav from "../Common/ClientNav";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import moment from "moment";
import { Link } from "react-router-dom";

const Inplay = () => {
  const [matchData, setMatchData] = useState([]);
  useEffect(() => {
    getAllMatches();

    return () => {};
  }, []);

  const getAllMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setMatchData(resp.data);
      }
    });
  };

  const generateShortName = (matchName) => {
    // Example logic to generate short names
    const teams = matchName.split(" v ");

    // Custom logic for short names
    const shortName = teams
      .map((team) => {
        // Remove descriptors like "Women" and split the name into words
        const words = team
          .replace(/(Women|Men)\s*/i, "")
          .trim()
          .split(" ");

        // Take the first two characters of the first word
        let shortTeamName = words[0].slice(0, 2).toUpperCase();

        // If there is a second word, take the first two characters from it
        if (words.length > 1) {
          shortTeamName += words[1].slice(0, 2).toUpperCase();
        } else {
          // If there is no second word, take the next two characters from the first word
          shortTeamName += words[0].slice(2, 4).toUpperCase();
        }

        return shortTeamName;
      })
      .join(" v ");

    return shortName;
  };

  const inPlayMatchList = () => {
    const currentTime = moment();
    const startOfToday = currentTime.clone().startOf("day");

    const matchesToday = matchData.filter((item) => {
      const matchStartTime = moment(item.open_date);

      // Check if the match is today and before the current time
      return (
        matchStartTime.isSameOrBefore(currentTime) &&
        matchStartTime.isSame(startOfToday, "day") &&
        item.decleared === "NO"
      );
    });
    return matchesToday.map((item) => {
      const shortName = generateShortName(item.match_name);
      console.log("soneonono", item);
      return (
        <div className="col-md-3 col-sm-3 col-6 more-space">
          <Link to={`/client/event/${item.match_id}`}>
            <div className="game-outer">
              <div className="img-outer position-relative">
                <img
                  src="/assets/img/cricket-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="game-type position-absolute">
                  <p>
                    <b>
                      Declared :{" "}
                      {item.match_running_status === "Y" ? "NO" : "YES"}
                    </b>
                  </p>
                </div>
              </div>
              <div className="content-outer">
                <h4>{shortName}</h4>
                <div className="game-date d-flex justify-content-between align-items-center">
                  <div className="game-team">
                    <p>{item.match_name}</p>
                  </div>
                  <div className="game-date">
                    <p>{moment(item.open_date).format("lll")}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>InPlay</h1>
          </div>
          <div className="game-detail">
            <div className="row">
              {inPlayMatchList()}
              {/* <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Declared : No</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Football</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6 more-space">
                <div className="game-outer">
                  <div className="img-outer position-relative">
                    <img
                      src="/assets/img/cricket-img1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="game-type position-absolute">
                      <p>
                        <b>Football</b>
                      </p>
                    </div>
                  </div>
                  <div className="content-outer">
                    <h4>SR vs BA</h4>
                    <div className="game-date d-flex justify-content-between align-items-center">
                      <div className="game-team">
                        <p>SRI LANKA v BANGLADESH T10</p>
                      </div>
                      <div className="game-date">
                        <p>December 2, 2023, 12:00 am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inplay;
