import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import socketIOClient from "socket.io-client";
import { baseUrl, socketUrl } from "../Common/BaseUrl";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

const AdminBetting = () => {
  const matchId = useParams();
  const clientRef = useRef(null);
  const [bookMakerData, setBookMakerData] = useState([]);
  const [isMatchCompleted, setIsMatchCompleted] = useState(false);
  const [winningTeam, setWinningTeam] = useState("");
  const [scoreResult, setScoreResult] = useState([]);
  const [matchName, setMatchName] = useState("");
  const [matchShortname, setMatchShortname] = useState("");
  const [startTime, setStartTime] = useState("");
  const [isLiveTvEnable, setIsLiveTvEnable] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [isMatchOdds, setIsMatchOdds] = useState("");
  const [isSession, setIsSession] = useState("");
  const [isBookMaker, setIsBookMaker] = useState("");
  const [matchOdds, setMatchOdds] = useState([]);
  const [makerBets, setMakerBets] = useState([]);
  const [betsData, setBetsData] = useState([]);
  const [userTotalGroup, setUserTotalGroup] = useState([]);
  const [userTotalClaulations, setUserTotalClaulations] = useState([]);
  const [matchTotal, setMatchTotal] = useState([]);

  useEffect(() => {
    if (!clientRef.current) {
      clientRef.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("s_token"),
        },
      });
      clientRef.current.emit("get_match_data_by_id", matchId.matchId);
      clientRef.current.emit("get_scores_by_id", matchId.matchId);
      clientRef.current.emit("get_match_odds_by_id", matchId.matchId);
      clientRef.current.emit("get_match_inner_details", matchId.matchId);
      clientRef.current.emit("get_book_maker_admin", matchId.matchId);
      clientRef.current.emit("get_session_bets_admin", matchId.matchId);
      clientRef.current.emit("get_team_bets_admin", matchId.matchId);
      handleSocketData(clientRef.current);
    }
    const handlePageUnload = () => {
      if (clientRef.current) {
        console.log("WebSocket connection will be closed on page unload.");
        clientRef.current.disconnect();
      }
    };

    window.addEventListener("beforeunload", handlePageUnload);
    getMatchInformation();
    fetchUserDataByGroupTotal();
    return () => {
      if (clientRef.current && !window.location.pathname.includes("/bets")) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.disconnect();
      }
      window.removeEventListener("beforeunload", handlePageUnload);
    };
  }, []);

  const handleSocketData = (socket) => {
    socket.off("result_match_data_by_id");
    socket.on("result_match_data_by_id", (data) => {
      setSessionData(data.data);
    });
    socket.off("result_get_scores_by_id");
    socket.on("result_get_scores_by_id", (data) => {
      setScoreResult(data.data);
    });

    socket.off("result_match_odds_by_id");
    socket.on("result_match_odds_by_id", (data) => {});
    socket.off("result_match_inner_details");
    socket.on("result_match_inner_details", (data) => {});

    socket.off("result_book_maker_admin");
    socket.on("result_book_maker_admin", (data) => {
      setBookMakerData(data);
    });
    socket.off("result_session_bets_admin");
    socket.on("result_session_bets_admin", (data) => {
      setBetsData(data.data);
    });
    socket.off("result_team_bets_admin");
    socket.on("result_team_bets_admin", (data) => {
      setMakerBets(data.data);
    });
  };

  const getMatchInformation = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios.post(baseUrl + "/api/match-info-by-id", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setMatchName(resp.data.match_name);
        const shortName = generateShortName(resp.data.match_name);
        setMatchShortname(shortName);
        setStartTime(resp.data.open_date);
        setIsMatchOdds(resp.data.is_matchodds);
        setIsBookMaker(resp.data.is_bookmaker);
        setIsSession(resp.data.is_session);
        if (resp.data.match_running_status === "N") {
          setIsMatchCompleted(true);
          setWinningTeam(resp.data.winning_team);
          clientRef.current.disconnect();
        } else {
          setIsMatchCompleted(false);
        }
      }
    });
  };

  const generateShortName = (matchName) => {
    // Example logic to generate short names
    const teams = matchName.split(" v ");

    // Custom logic for short names
    const shortName = teams
      .map((team) => {
        // Remove descriptors like "Women" and split the name into words
        const words = team
          .replace(/(Women|Men)\s*/i, "")
          .trim()
          .split(" ");

        // Take the first two characters of the first word
        let shortTeamName = words[0].slice(0, 2).toUpperCase();

        // If there is a second word, take the first two characters from it
        if (words.length > 1) {
          shortTeamName += words[1].slice(0, 2).toUpperCase();
        } else {
          // If there is no second word, take the next two characters from the first word
          shortTeamName += words[0].slice(2, 4).toUpperCase();
        }

        return shortTeamName;
      })
      .join(" v ");

    return shortName;
  };

  const handleClick = (e, type) => {
    if (type === "live_tv") {
      setIsLiveTvEnable(!isLiveTvEnable);
    }
  };

  const sessioNMap = () => {
    const reversedSessionData = sessionData.slice().reverse();
    return reversedSessionData.map((item, index) => {
      return (
        <tr key={index}>
          <td
            style={
              item.n_rate > 0
                ? {
                    color: "white",
                    backgroundColor: "#438bff",
                    borderColor: "white",
                  }
                : {}
            }
          >
            {item.title}
          </td>
          {item.n_rate == 0 ? (
            <td className="text-center">SUSPENDED</td>
          ) : (
            <td
              className="text-center"
              style={
                item.n_rate > 0
                  ? {
                      color: "white",
                      backgroundColor: "#438bff",
                      borderColor: "white",
                    }
                  : {}
              }
            >
              {item.n_rate} <span className="small-box">{item.n_run}</span>
            </td>
          )}
          {item.y_rate == 0 ? (
            <td className="text-center">SUSPENDED</td>
          ) : (
            <td
              className="text-center"
              style={
                item.y_rate > 0
                  ? {
                      color: "white",
                      backgroundColor: "#438bff",
                      borderColor: "white",
                    }
                  : {}
              }
            >
              {item.y_rate} <span className="small-box">{item.y_run}</span>
            </td>
          )}
        </tr>
      );
    });
  };

  const matchOddsMap = () => {
    const reversedSessionData = matchOdds.slice().reverse();
    return reversedSessionData.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.RunnerName}</td>
          {item.LayPrice1 == 0 ? (
            <td>SUSPENDED</td>
          ) : (
            <td>
              {item.LayPrice1} <span>{item.LaySize1}</span>
            </td>
          )}
          {item.BackPrice1 == 0 ? (
            <td>SUSPENDED</td>
          ) : (
            <td>
              {item.BackPrice1} <span>{item.BackPrice1}</span>
            </td>
          )}
          \
        </tr>
      );
    });
  };

  const convertToDecimal = (value) => {
    // Extract the decimal part and convert to a number
    const decimalPart = parseFloat(
      `0.${value.toString().split(".")[1] || "0"}`
    );
    return decimalPart.toFixed(2);
  };

  const boookMakerTable = () => {
    return bookMakerData.map((item) => {
      const reversedRunners = [...item.runners].reverse();
      return reversedRunners.map((runer) => {
        const backOdds = convertToDecimal(runer.back[0].price1);
        const layOdds = convertToDecimal(runer.lay[0].price1);
        let plusMinus;
        if (runer.totalPlusMinus > 0) {
          plusMinus = -runer.totalPlusMinus;
        } else {
          plusMinus = Math.abs(runer.totalPlusMinus);
        }

        return (
          <tr>
            <td>
              {runer.runnerName} <br />
              <span>
                <small>({parseInt(plusMinus)})</small>
              </span>
            </td>
            <td>{runer.status === "SUSPENDED" ? "SUSPENDED" : backOdds}</td>
            <td>{runer.status === "SUSPENDED" ? "SUSPENDED" : layOdds}</td>
          </tr>
        );
      });
    });
  };

  const scoreBoardMap = () => {
    return (
      <div className="scoreboard">
        <div className="scoreboard-top scoreboard-inner">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="total-score">
                <span>
                  {scoreResult.teams && scoreResult.teams[1].team_short_name}
                </span>
                <h5>{scoreResult.teams && scoreResult.teams[1].score}</h5>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="total-score">
                <span>
                  {scoreResult.teams && scoreResult.teams[0].team_short_name}
                </span>
                <h5>{scoreResult.teams && scoreResult.teams[0].score}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="scoreboard-middle scoreboard-inner">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="total-score current-rate">
                <span>CRR {scoreResult.currentRunRate}</span>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="total-score bowl-score">
                <ul className="nav score-count">
                  {scoreResult.last24balls &&
                    scoreResult.last24balls.map((item) => {
                      return <li>{item.score_card}</li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="scoreboard-bottom scoreboard-inner">
          <div className="winning-run text-center">
            <p>
              <b>{scoreResult.msg}</b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderAccordionItems = () => {
    const groupedBets = betsData.reduce((accumulator, bet) => {
      const existingSession = accumulator.find(
        (item) => item.session === bet.session
      );
      if (existingSession) {
        existingSession.bets.push(bet);
      } else {
        accumulator.push({ session: bet.session, bets: [bet] });
      }
      return accumulator;
    }, []);
    return groupedBets.map((session, index) => (
      <div className="accordion-item" key={index}>
        <input type="checkbox" id={`accordion${index}`} />
        <label htmlFor={`accordion${index}`} className="accordion-item-title">
          {session.bets[0]?.declared === "YES" ? (
            <span className="ico">🏆 {session.bets[0]?.decl}</span>
          ) : (
            <span className="icon"></span>
          )}

          {session.session}
        </label>
        <div className="accordion-item-desc">
          <table className="w-100">
            <thead>
              <tr>
                <th>Client</th>
                <th>-</th>
                <th>-</th>
                <th>Amount</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {session.bets.map((bet, betIndex) => (
                <tr key={betIndex}>
                  <td>{bet.username}</td>
                  <td>{bet.run}</td>
                  <td>
                    {bet.session_mode} ({bet.session_rate})
                  </td>
                  <td>{bet.amount}</td>
                  <td>
                    {moment(bet.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ));
  };

  const clientbettingShow = () => {
    return makerBets.map((item) => {
      return (
        <tr>
          <td>
            {item.name} ({item.username})
          </td>
          <td className="take">{item.amount}</td>
          <td className="take">{item.rate}</td>
          <td className="take">
            {item.mode} {item.team}
          </td>
          <td className="take">
            {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </td>
        </tr>
      );
    });
  };

  const fetchUserDataByGroupTotal = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios.post(baseUrl + "/api/fetch-total-bets", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setUserTotalGroup(resp.data);
      }
    });
  };

  const totalSumGroud = () => {
    if (userTotalGroup.length > 0) {
      let match,
        session,
        total,
        mCom,
        sCom,
        lettCom,
        netAmnt,
        shrAmnt,
        gTotal = 0;
      let i = 0;
      return userTotalGroup.map((item) => {
        i++;
        const totalBets = item.totalBetsTeam + item.totalBetsSession;
        const totalCommission =
          item.calculateMatchCommission + item.calculateSessionCommission;
        const netAmount = totalCommission + totalBets;
        const sharePercentage = parseFloat(
          (netAmount * item.userSharePercentage) / 100
        ).toFixed(2);
        const grandTotal = parseFloat(netAmount - sharePercentage).toFixed(2);
        match = match + item.totalBetsTeam;
        session = session + item.totalBetsSession;
        total = total + totalBets;
        mCom = mCom + item.calculateMatchCommission;
        sCom = sCom + item.calculateSessionCommission;
        lettCom = lettCom + totalCommission;
        netAmnt = netAmnt + netAmount;
        shrAmnt = shrAmnt + sharePercentage;
        gTotal = gTotal + grandTotal;
        console.log("i================", i);
        // if(i == userTotalGroup.length){
        //   setMatchTotal({match:match, session:session, total:total, mCom:mCom, sCom:sCom, lettCom:lettCom, netAmnt:netAmnt, shrAmnt:shrAmnt, gTotal:gTotal});
        // }
        return (
          <tr>
            <td>{item.childUsername}</td>
            <td
              style={
                item.totalBetsTeam < 0 ? { color: "red" } : { color: "green" }
              }
            >
              {item.totalBetsTeam}
            </td>
            <td
              style={
                item.totalBetsSession < 0
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {item.totalBetsSession}
            </td>
            <td style={totalBets < 0 ? { color: "red" } : { color: "green" }}>
              {totalBets}
            </td>
            <td
              style={
                item.calculateMatchCommission < 0
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {item.calculateMatchCommission}
            </td>
            <td
              style={
                item.calculateSessionCommission < 0
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {item.calculateSessionCommission}
            </td>
            <td
              style={
                totalCommission < 0 ? { color: "red" } : { color: "green" }
              }
            >
              {totalCommission}
            </td>
            <td style={netAmount < 0 ? { color: "red" } : { color: "green" }}>
              {netAmount}
            </td>
            <td
              style={
                sharePercentage < 0 ? { color: "red" } : { color: "green" }
              }
            >
              {sharePercentage}
            </td>
            <td style={grandTotal < 0 ? { color: "red" } : { color: "green" }}>
              {grandTotal}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            No Data Found
          </td>
        </tr>
      );
    }
  };

  const userTotalCalculation = () => {
    if (userTotalClaulations.length > 0) {
      return userTotalClaulations.map((item) => {
        return (
          <tr>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            No Data Found
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="match-detail">
          <div className="section-heading text-center">
            <h1>{matchName}</h1>
            <p>{matchShortname}</p>
            <div className="game-date">
              <p>({moment(startTime).format("MMMM Do YYYY, h:mm:ss a")})</p>
            </div>
            {isMatchCompleted === true && (
              <div className="game-date">
                <p>({winningTeam})</p>
              </div>
            )}
          </div>

          <div className="super-agent">
            <div className="super-outer">
              <div className="score-live d-flex flex-wrap aligh-items-center justify-content-between pt-3">
                <div>
                  <button
                    className="btn btn-primary"
                    //   onClick={(e) => handleFormChange(e, "create")}
                  >
                    Score
                  </button>
                </div>
                <div className="client-tab">
                  <nav>
                    <div
                      className="nav nav-tabs mb-3"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="btn btn-primary"
                        onClick={(e) => handleClick(e, "live_tv")}
                      >
                        Live Tv
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
              {isLiveTvEnable === true && (
                <div className="row mb-3">
                  <div className="col-12">
                    <iframe
                      src="https://www.youtube.com/embed/a3ICNMQW7Ok?si=DLwH5r80rkwsP8TO"
                      title="W3Schools Free Online Web Tutorials"
                      width="100%"
                    ></iframe>
                  </div>
                </div>
              )}
              {scoreBoardMap()}
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  {isBookMaker === "Y" && (
                    <div className="super-inner super-left mb-3">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Back</th>
                              <th>Lay</th>
                            </tr>
                          </thead>
                          <tbody>{boookMakerTable()}</tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {isMatchOdds === "Y" && (
                    <div className="super-inner super-left mb-3">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Back</th>
                              <th>Lay</th>
                            </tr>
                          </thead>
                          <tbody>{matchOddsMap()}</tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="super-inner super-left mb-3">
                    <h6 className="text-center">MATCH</h6>
                    <div className="table-responsive">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>-</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>{clientbettingShow()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {isSession === "Y" && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Session</th>
                              <th>Not</th>
                              <th>Yes</th>
                            </tr>
                          </thead>

                          <tbody>{sessioNMap()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                {isMatchOdds === "Y" && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <h6 className="text-center">MATCH</h6>
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Back</th>
                              <th>Lay</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>( 12 ) India Women</td>
                              <td className="take">4,361</td>
                              <td className="take">4,313</td>
                            </tr>
                            <tr>
                              <td>( -60 ) England Women</td>
                              <td className="take">4,361</td>
                              <td className="take">4,313</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6 col-12">
                  <div className="super-inner super-left">
                    <div className="accordion">{renderAccordionItems()}</div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 mb-5">
                <div className="super-inner super-left">
                  <div className="table-responsive">
                    <div className="col-12">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Match (+/-)</th>
                            <th>Session (+/-)</th>
                            <th>Total</th>
                            <th>M.Com</th>
                            <th>S.Com</th>
                            <th>T.Com</th>
                            <th>NET.AMT</th>
                            <th>SHR.AMT</th>
                            <th>G. Total</th>
                          </tr>
                        </thead>
                        <tbody>{totalSumGroud()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* total record table */}
              <div className="row mt-2 mb-5">
                <div className="super-inner super-left">
                  <div className="table-responsive">
                    <div className="col-12">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Match (+/-)</th>
                            <th>Session (+/-)</th>
                            <th>Total</th>
                            <th>M.Com</th>
                            <th>S.Com</th>
                            <th>T.Com</th>
                            <th>NET.AMT</th>
                            <th>SHR.AMT</th>
                            <th>G. Total</th>
                          </tr>
                        </thead>
                        {/* <tbody>{totalSumGroud()}</tbody> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBetting;
