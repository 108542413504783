import React, { useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import ReactDatatable from "@mkikets/react-datatable";

const AllSuperAgentReport = () => {
  const [clientRecords, setClientRecords] = useState([]);

  const columns = [
    {
      key: "",
      text: "S.Agent",
      className: "super_agent",
      align: "left",
      sortable: true,
    },
    {
      key: "match",
      text: "Match (+/-)",
      className: "match",
      align: "left",
      sortable: true,
    },
    {
      key: "session",
      text: "Session (+/-)",
      className: "session",
      align: "left",
      sortable: true,
    },
    {
      key: "total",
      text: "Total",
      className: "total",
      align: "left",
      sortable: true,
    },

    {
      key: "m_commission",
      text: "M.Com",
      className: "m_commission",
      align: "left",
      sortable: true,
    },
    {
      key: "s_commission",
      text: "S.Com",
      className: "s_commission",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{0}%</span>;
      },
    },
    {
      key: "t_commission",
      text: "T.Com",
      className: "t_commission",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{0}%</span>;
      },
    },
    {
      key: "g_total",
      text: "G. Total",
      className: "g_total",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{0}</span>;
      },
    },
    {
      key: "sa_share",
      text: "SA. Share",
      className: "sa_share",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{0}%</span>;
      },
    },
    {
      key: "balance",
      text: "Balance",
      className: "balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{0}%</span>;
      },
    },
    {
      key: "",
      text: "",
      className: "mat",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <button></button>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="client-form mb-5">
              <div className="row">
                <div className="col-md-6 col-12">
                  <label>Start Date</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="col-md-6 col-12">
                  <label>End Date</label>
                  <input type="date" className="form-control" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <select className="form-control">
                    <option>Select Super Agent</option>
                  </select>
                </div>
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSuperAgentReport;
