import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactDatatable from "@mkikets/react-datatable";
import { baseUrl } from "../Common/BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import { Modal, Button, Table, Dropdown } from "react-bootstrap";
import moment from "moment";
import AdminNav from "../Common/AdminNav";

const SuperAdmin = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [master_user, setMaster_user] = useState("");
  const [master_user_limit, setMaster_user_limit] = useState("0");
  const [super_agent_limit, setSuper_agent_limit] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [super_agent_share, setSuper_agent_share] = useState("");
  const [match_commission, setMatch_commission] = useState("");
  const [session_commission, setSession_commission] = useState("");
  const [clientRecords, setClientRecords] = useState([]);
  const [nameErro, setNameErro] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [super_agent_limitErr, setSuper_agent_limitErr] = useState("");
  const [match_commissionErr, setMatch_commissionErr] = useState("");
  const [session_commissionErr, setSession_commissionErr] = useState("");
  const [super_agent_shareErr, setSuper_agent_shareErr] = useState("");
  const [isPanActive, setIsPanActive] = useState("tab-pane fade");
  const [isUpdatePanActive, setIsUpdatePanActive] = useState("tab-pane fade");
  const [isChildPanOpen, setIsChildPanOpen] = useState("tab-pane fade");
  const [avaliableBal, setAvaliableBal] = useState("0.00");
  const [avaliableBalChild, setAvaliableBalChild] = useState("0.00");
  const [superAdminLimits, setSuperAdminLimits] = useState([]);
  const [childLimits, setChildLimits] = useState([]);
  const [selectedSuperAdmin, setSelectedSuperAdmin] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [childName, setChildName] = useState("");
  const [isEditPanActive, setIsEditPanActive] = useState("tab-pane fade");
  const [childId, setChildId] = useState("");
  const [parentSharePercent, setParentSharePercent] = useState("");
  const [formData, setFormData] = useState({
    total_limit: "",
    current_limit: "",
  });
  const [childFormData, setChildFormData] = useState({
    total_limit: "",
    current_limit: "",
  });
  const [clientReports, setClientReports] = useState([]);

  const [commissionList, setCommissionList] = useState([]);
  const [showCommissionModel, setShowCommissionModel] = useState(false);
  const [shareList, setShareList] = useState([]);
  const [showShareModel, setShowShareModel] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name &&
      !password &&
      !mobile &&
      !super_agent_limit &&
      !super_agent_share &&
      !match_commission &&
      !session_commission
    ) {
      setNameErro("This field is required");
      setMobileErr("This field is required");
      setPasswordErr("This field is required");
      setSuper_agent_limitErr("This field is required");
      setMatch_commissionErr("This field is required");
      setSession_commissionErr("This field is required");
      setSuper_agent_shareErr("This field is required");
      return false;
    }

    if (!name) {
      setNameErro("This field is required");
      return false;
    }

    if (!password) {
      setPasswordErr("This field is required");
      return false;
    }

    if (!mobile) {
      setMobileErr("This field is required");
      return false;
    }

    if (!super_agent_limit) {
      setSuper_agent_limitErr("This field is required");
      return false;
    }

    if (!super_agent_share) {
      setSuper_agent_shareErr("This field is required");
      return false;
    }

    if (!match_commission) {
      setMatch_commissionErr("This field is required");

      return false;
    }

    if (!session_commission) {
      setSession_commissionErr("This field is required");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const agentData = {
      username: userName,
      parent_user: master_user,
      parent_user_limit: master_user_limit,
      name: name,
      password: password,
      mobile: mobile,
      user_limit: super_agent_limit,
      share_percentage: super_agent_share,
      match_commission: match_commission,
      session_commission: session_commission,
    };

    axios
      .post(baseUrl + "/api/add-super-admin", agentData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setUserName("");
          setName("");
          setPassword("");
          setMobile("");
          setSuper_agent_limit("");
          setSuper_agent_share("");
          setMatch_commission("");
          setSession_commission("");
          setIsPanActive("tab-pane fade");
          getSuperAdmin();
        } else {
          toast.error(resp.message);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      setName(value);
      setNameErro("");
    }

    if (name === "password") {
      setPassword(value);
      setPasswordErr("");
    }

    if (name === "mobile") {
      setMobile(value);
      setMobileErr("");
    }

    if (name === "super_agent_limit") {
      setSuper_agent_limit(value);
      setSuper_agent_limitErr("");
    }

    if (name === "super_agent_share") {
      setSuper_agent_share(value);
      setSuper_agent_shareErr("");
    }

    if (name === "match_commission") {
      setMatch_commission(value);
      setMatch_commissionErr("");
    }

    if (name === "session_commission") {
      setSession_commission(value);
      setSession_commissionErr("");
    }
  };

  const handleFormChange = (e, actionType) => {
    e.preventDefault();
    setIsChildPanOpen("tab-pane fade");
    setIsEditPanActive("tab-pane fade");
    if (actionType === "create") {
      // Handle create action
      console.log("Create action");
      setIsPanActive("tab-pane fade active show");
      setIsUpdatePanActive("tab-pane fade");
      // Add any other logic specific to the create action
    } else if (actionType === "close") {
      // Handle close action
      console.log("Close action");
      setIsPanActive("tab-pane fade");
      setName("");
      setMobile("");
      setPassword("");
      setSuper_agent_limit("");
      setMatch_commission("");
      setSession_commission("");
      setSuper_agent_share("");
      setNameErro("");
      setMobileErr("");
      setPasswordErr("");
      setSuper_agent_limitErr("");
      setMatch_commissionErr("");
      setSession_commissionErr("");
      setSuper_agent_shareErr("");
      // Add any other logic specific to the close action
    }
  };

  const handleFormChangeLimit = (e, actionType) => {
    e.preventDefault();
    setIsChildPanOpen("tab-pane fade");
    setIsEditPanActive("tab-pane fade");
    if (actionType === "create") {
      // Handle create action
      console.log("Create action");
      setIsUpdatePanActive("tab-pane fade active show");
      setIsPanActive("tab-pane fade");
      // Add any other logic specific to the create action
    } else if (actionType === "close") {
      // Handle close action
      console.log("Close action");
      setSelectedSuperAdmin("all");
      setIsUpdatePanActive("tab-pane fade");
      setName("");
      setMobile("");
      setPassword("");
      setSuper_agent_limit("");
      setMatch_commission("");
      setSession_commission("");
      setSuper_agent_share("");
      setNameErro("");
      setMobileErr("");
      setPasswordErr("");
      setSuper_agent_limitErr("");
      setMatch_commissionErr("");
      setSession_commissionErr("");
      setSuper_agent_shareErr("");
      // Add any other logic specific to the close action
    }
  };

  const getSuperAdmin = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-super-admin", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const getAllSuperAdminWalletLimit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(baseUrl + "/api/get-all-super-admin-wallet-limit", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setSuperAdminLimits(resp.data);
        }
      });
  };

  const getUserInfo = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setMaster_user(resp.data.username);
        setParentSharePercent(resp.data.share_percentage);
      }
    });
  };

  const handleDropdownSelect = (event, record) => {
    console.log("event", event, record);
    setIsPanActive("tab-pane fade");
    setIsUpdatePanActive("tab-pane fade");

    if(event=="match-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isMatch,
        field:"isMatch"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        

    }

    if(event=="match-lock"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isMatchLock,
        field:"isMatchLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        

    }
    if(event=="fancy-lock"){
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancyLock,
        field:"isFancyLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        
    }

    if(event=="fancy-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancy,
        field:"isFancy"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        

    }
    if(event=="casino-lock"){
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isCasinoLock,
        field:"isCasinoLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
    }

    if(event=="casino-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isCasino,
        field:"isCasino"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        

    }

    if(event=="bm-lock"){
      alert(record.isBMLock)
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isBMLock,
        field:"isBMLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
      
    }

    if(event=="bm-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancy,
        field:"isBM"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getSuperAdmin();
          }
        });
        

    }

    if (event === "updateLimit") {
      setAvaliableBalChild("0.00");
      getCurrenChildLimit(record.username);
      setChildName(record.username);
      getAllMiniAdminWalletLimit(record.username);
    }
    if (event === "edit") {
      fetchChildInfo(record.id);
    }
    if (event === "deactivate") {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.is_active,
      };
      axios
        .post(baseUrl + "/api/deactivate-child", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            getSuperAdmin();
          }
        });
    }
    if (event === "limit-updates") {
      fetchReportByIdEvent(record.id);
    }
    if(event==='share-details'){
      fetchShareCommissionReport(record.id,"1");

    }
    if(event==='comm-update'){
      fetchShareCommissionReport(record.id,"2");
    }
  };

  const fetchChildInfo = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      userId: id,
    };
    axios.post(baseUrl + "/api/fetch-child-info", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setChildId(resp.data.id);
        setIsEditPanActive("tab-pane fade active show");
        setIsChildPanOpen("tab-pane fade");
        setIsPanActive("tab-pane fade");
        setIsUpdatePanActive("tab-pane fade");
        setMaster_user(resp.data.parent_user);
        setUserName(resp.data.username);
        setMobile(resp.data.mobile_no);
        setSuper_agent_limit(resp.data.current_limit);
        setName(resp.data.name);
        setSuper_agent_share(
          resp.data.share_percentage ? resp.data.share_percentage : "0"
        );
        setMatch_commission(
          resp.data.match_commission_percentage
            ? resp.data.match_commission_percentage
            : "0"
        );
        setSession_commission(
          resp.data.session_commission_percentage
            ? resp.data.session_commission_percentage
            : "0"
        );
      }
    });
  };

  const getAllMiniAdminWalletLimit = (username) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const selectedUserData = {
      clientUserName: username,
    };
    axios
      .post(
        baseUrl + "/api/get-all-child-wallet-limit",
        selectedUserData,
        config
      )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const filteredRecords = resp.data.filter(
            (record) => record.parent_user === username
          );
          setChildLimits(filteredRecords);
          setIsChildPanOpen("tab-pane fade active show");
        }
      });
  };

  const getCurrenChildLimit = (userName) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const selectedUserData = {
      clientUserName: userName,
    };

    axios
      .post(baseUrl + "/api/fetch-user-limit-child", selectedUserData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          if (resp.data.length == 0 || resp.data[0].user_limit == null) {
            setAvaliableBalChild("0.00");
          } else {
            setAvaliableBalChild(resp.data[0].user_limit);
          }
        }
      });
  };

  const columns = [
    {
      key: "",
      text: "",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        const deactivateText =
          record.is_active === "Y" ? "Deactivate" : "Activate";
        return (
          <span>
            {/* Dropdown */}
            <Dropdown onSelect={(event) => handleDropdownSelect(event, record)}>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
              ></Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="updateLimit"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Mini Admin Limit Update
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="edit"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Edit ({record.username})
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="deactivate"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {deactivateText} ({record.username})
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="match-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                 {record?.isMatchLock=="N"?'Match Lock':"Match UnLock" } 
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="match-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                 {record?.isMatch=="N"?'Match ON':"Match OFF" } 
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="fancy-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isFancyLock=="N"?'Fancy Lock':"Fancy UnLock" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="fancy-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isFancy=="N"?'Fancy ON':"Fancy OFF" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="casino-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isCasinoLock=="N"?'Casino Lock':"Casino UnLock" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="casino-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isCasino=="N"?'Casino ON':"Casino OFF" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="bm-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                {record?.isBMLock=="N"?'BM Lock':"BM UnLock" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="bm-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isBM=="N"?'Bm ON':"Bm OFF" } 
                  
                </Dropdown.Item>


                <Dropdown.Item
                  eventKey="limit-updates"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Limit updates details
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="share-details"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Share updates details
                </Dropdown.Item>
                <Dropdown.Item eventKey="comm-update">Comm updates details</Dropdown.Item>
                {/* Add more items as needed */}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        );
      },
    },
    {
      key: "username",
      text: "User",
      className: "user",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            <Link to={`/admin/mini-admin?id=${btoa(record.username)}`}>
              {record.username}
            </Link>
          </span>
        );
      },
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            <Link to={`/admin/mini-admin?id=${btoa(record.username)}`}>
              {record.name}
            </Link>
          </span>
        );
      },
    },
    {
      key: "lock",
      text: "Lock",
      className: "lock",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <Link to={''}>
              {record?.isMatchLock=="N"?  <img
              title="match unlock"
              src="/assets/img/match_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              title="match lock"
              src="/assets/img/match_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>
         
            <Link to={''}>
              {record?.isFancyLock=="N"?  <img
                title="fancy unlock"
              src="/assets/img/fancy_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              title="fancy lock"
              src="/assets/img/fancy_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

            <Link to={''}>
              {record?.isCasinoLock=="N"?  <img
              title="casino unlock"
              src="/assets/img/casino_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              title="casino lock"
              src="/assets/img/casino_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

            <Link to={''}>
              {record?.isBMLock=="N"?  <img
               title="bm unlock"
              src="/assets/img/bm_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              title="bm lock"
              src="/assets/img/bm_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

          </span>
        );
      },
    },
    {
      key: "mobile_no",
      text: "Mobile No.",
      className: "mobile_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.mobile_no}
          </span>
        );
      },
    },

    {
      key: "unc_pass",
      text: "Password",
      className: "password",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.unc_pass}
          </span>
        );
      },
    },
    {
      key: "s_agent_percent",
      text: "Admin",
      className: "password",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {parseFloat(parentSharePercent) -
              parseFloat(record.share_percentage)}
            %
          </span>
        );
      },
    },
    {
      key: "agent_id",
      text: "S.Admin",
      className: "agent_id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.share_percentage}%
          </span>
        );
      },
    },
    {
      key: "current",
      text: "Current",
      className: "current",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.current_limit ? record.current_limit : "0"}
          </span>
        );
      },
    },
    {
      key: "engaged",
      text: "Mini Admins",
      className: "engaged",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.sum_current_limit ? record.sum_current_limit : 0}
          </span>
        );
      },
    },
    {
      key: "sess.",
      text: "Sess.",
      className: "sess",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.session_commission_percentage
              ? record.session_commission_percentage
              : "0"}
            %
          </span>
        );
      },
    },
    {
      key: "mat",
      text: "Mat.",
      className: "mat",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.match_commission_percentage
              ? record.match_commission_percentage
              : "0"}
            %
          </span>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/admin");
    } else {
      getSuperAdmin();
      generateUserName();
      getUserInfo();
      getCurrentUserLimit();
      getAllSuperAdminWalletLimit();
    }
  }, []);

  const getCurrentUserLimit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/fetch-user-limit", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        if (resp.data.length == 0 || resp.data[0].user_limit == null) {
          setAvaliableBal("0.00");
          setMaster_user_limit("0");
        } else {
          setAvaliableBal(resp.data[0].user_limit);
          setMaster_user_limit(resp.data[0].user_limit);
        }
      }
    });
  };

  const generateUserName = () => {
    axios.get(baseUrl + "/api/generate-username-s-admin", {}).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data);
      }
    });
  };

  const fetchReportById = (e, id) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
    };
    axios
      .post(baseUrl + "/api/fetch-report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setShowModal(true);
          setClientReports(resp.data);
        }
      });
  };

  const fetchReportByIdEvent = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
    };
    axios
      .post(baseUrl + "/api/fetch-report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setShowModal(true);
          setClientReports(resp.data);
        }else{
          toast.error('data not found')
        }
      });
  };


  
  const fetchShareCommissionReport = (id,type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
      type:type
    };
    axios
      .post(baseUrl + "/api/fetch-share_com_report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          //type =>1 sharing 2 commision 
          if(type=="1"){
            setShowShareModel(true);
            setShareList(resp.data);
          }else{
            setShowCommissionModel(true);
            setCommissionList(resp.data);
         
          }
         
        }else{
          toast.error('data not found')
        }
      });
  };


  const pageChange = () => {
    console.log("something");
  };

  const updateLimit = (e, data) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const updateData = {
      clientId: data.id, // Assuming you have a unique identifier for each client
      total_limit: formData.total_limit,
      current_limit: formData.current_limit,
    };

    axios
      .post(baseUrl + "/api/update-limit-admin", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getCurrentUserLimit();
          getAllSuperAdminWalletLimit();
        } else {
          toast.error(resp.message);
        }
      });
  };

  const handleChangeLimit = (event, item) => {
    const { name, value } = event.target;

    // Update the superAdminLimits array with the new current_limit value
    const updatedLimits = superAdminLimits.map((limitItem) =>
      limitItem === item ? { ...limitItem, current_limit: value } : limitItem
    );

    // Log the updatedLimits array to check if it's being modified correctly

    // Update the state with the modified superAdminLimits array
    setSuperAdminLimits(updatedLimits);

    // Update the formData state
    setFormData({
      ...formData,
      total_limit: item.total_limit,
      current_limit: value,
    });
  };

  const handleChangeLimitChild = (event, item) => {
    const { name, value } = event.target;

    // Update the superAdminLimits array with the new current_limit value
    const updatedLimits = childLimits.map((limitItem) =>
      limitItem === item ? { ...limitItem, current_limit: value } : limitItem
    );

    // Log the updatedLimits array to check if it's being modified correctly

    // Update the state with the modified superAdminLimits array
    setChildLimits(updatedLimits);

    // Update the formData state
    setChildFormData({
      ...childFormData,
      total_limit: item.total_limit,
      current_limit: value,
    });
  };

  const handleChangeSelect = (event) => {
    setSelectedSuperAdmin(event.target.value);
  };
  const filteredSuperAdmins =
    selectedSuperAdmin === "all"
      ? superAdminLimits
      : superAdminLimits.filter((item) => item.id == selectedSuperAdmin);
  const filteredChild =
    selectedSuperAdmin === "all"
      ? childLimits
      : childLimits.filter((item) => item.id == selectedSuperAdmin);

  const handleCloseModal = () => setShowModal(false);
  const handleCloseCommissionModel = () => setShowCommissionModel(false);
  const handleCloseShareModel = () => setShowShareModel(false);


  const updateLimitChild = (e, data) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const updateData = {
      parent_username: childName,
      clientId: data.id, // Assuming you have a unique identifier for each client
      total_limit: childFormData.total_limit,
      current_limit: childFormData.current_limit,
    };

    axios
      .post(baseUrl + "/api/update-limit-child", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          getCurrenChildLimit(resp.data);
          getAllMiniAdminWalletLimit(resp.data);
        } else {
          toast.error(resp.message);
        }
      });
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    if (
      !name &&
      !password &&
      !mobile &&
      !super_agent_limit &&
      !super_agent_share &&
      !match_commission &&
      !session_commission
    ) {
      setNameErro("This field is required");
      setMobileErr("This field is required");
      setPasswordErr("This field is required");
      setSuper_agent_limitErr("This field is required");
      setMatch_commissionErr("This field is required");
      setSession_commissionErr("This field is required");
      setSuper_agent_shareErr("This field is required");
      return false;
    }

    if (!name) {
      setNameErro("This field is required");
      return false;
    }

    if (!password) {
      setPasswordErr("This field is required");
      return false;
    }

    if (!mobile) {
      setMobileErr("This field is required");
      return false;
    }

    if (!super_agent_limit) {
      setSuper_agent_limitErr("This field is required");
      return false;
    }

    if (!super_agent_share) {
      setSuper_agent_shareErr("This field is required");
      return false;
    }

    if (!match_commission) {
      setMatch_commissionErr("This field is required");

      return false;
    }

    if (!session_commission) {
      setSession_commissionErr("This field is required");
      return false;
    }

    const agentData = {
      child_id: childId,
      name: name,
      password: password,
      mobile: mobile,
      user_limit: super_agent_limit,
      share_percentage: super_agent_share,
      match_commission: match_commission,
      session_commission: session_commission,
    };

    axios.post(baseUrl + "/api/update-user", agentData, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        setUserName("");
        setName("");
        setPassword("");
        setMobile("");
        setSuper_agent_limit("");
        setSuper_agent_share("");
        setMatch_commission("");
        setSession_commission("");
        setIsEditPanActive("tab-pane fade");
        getSuperAdmin();
        updateLimitOnEdit(childId, super_agent_limit);
      } else {
        toast.error(resp.message);
      }
    });
  };

  const updateLimitOnEdit = (id, limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const updateData = {
      clientId: id, // Assuming you have a unique identifier for each client
      current_limit: limit,
    };

    axios
      .post(baseUrl + "/api/update-limit-admin", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          // toast.success(resp.message);
          getCurrentUserLimit();
          getAllSuperAdminWalletLimit();
        } else {
          toast.error(resp.message);
        }
      });
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Super Admin</h1>
              <div className="client-tab">
                <nav>
                  <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={(e) => handleFormChangeLimit(e, "create")}
                    >
                      Update Limit
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleFormChange(e, "create")}
                    >
                      Create
                    </button>
                  </div>
                </nav>
              </div>
            </div>
            <div className="client-form mb-5">
              <div
                className="tab-content p-3 border bg-light"
                id="nav-tabContent"
              >
                <div
                  className={isPanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            name="username"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Admin"
                            name="master_user"
                            value={master_user}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Admin Limit"
                            name="master_user_limit"
                            value={master_user_limit}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            name="name"
                            value={name}
                          />
                          {nameErro && (
                            <span className="" style={{ color: "red" }}>
                              {nameErro}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            name="password"
                            value={password}
                          />
                          {passwordErr && (
                            <span className="" style={{ color: "red" }}>
                              {passwordErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Mobile"
                            onChange={handleChange}
                            name="mobile"
                            value={mobile}
                          />
                          {mobileErr && (
                            <span className="" style={{ color: "red" }}>
                              {mobileErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Super Admin Limit"
                            onChange={handleChange}
                            name="super_agent_limit"
                            value={super_agent_limit}
                          />
                          {super_agent_limitErr && (
                            <span className="" style={{ color: "red" }}>
                              {super_agent_limitErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Super Admin Share (%)"
                            onChange={handleChange}
                            name="super_agent_share"
                            value={super_agent_share}
                          />
                          {super_agent_shareErr && (
                            <span className="" style={{ color: "red" }}>
                              {super_agent_shareErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Match Commission (%)"
                            onChange={handleChange}
                            name="match_commission"
                            value={match_commission}
                          />
                          {match_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {match_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Session Commission (%)"
                            onChange={handleChange}
                            name="session_commission"
                            value={session_commission}
                          />
                          {session_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {session_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={(e) => handleFormChange(e, "close")}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success bg-dark p-3"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={isUpdatePanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <select
                          className="form-select"
                          onChange={handleChangeSelect}
                          value={selectedSuperAdmin}
                        >
                          <option value={"all"}>All Super Admin</option>
                          {superAdminLimits.map((item) => {
                            return (
                              <option value={item.id}>{item.username}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12 mb-2">
                        <h2 className="text-center">
                          {avaliableBal == null
                            ? "0"
                            : avaliableBal.toLocaleString()}
                        </h2>
                      </div>
                    </div>
                    {filteredSuperAdmins.map((item) => {
                      return (
                        <div className="row">
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <h4>
                                {item.username} ({item.name})
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Total Limit"
                                name="total_limit"
                                disabled
                                value={item.total_limit ? item.total_limit : ""}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Current Limit"
                                name="current_limit"
                                onChange={(e) => handleChangeLimit(e, item)}
                                value={
                                  item.current_limit ? item.current_limit : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "10px" }}
                                onClick={(e) => updateLimit(e, item)}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => fetchReportById(e, item.id)}
                              >
                                REPORT
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-12">
                      <div className="form-button d-flex justify-content-end">
                        <button
                          className="btn btn-primary me-3"
                          onClick={(e) => handleFormChangeLimit(e, "close")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={isChildPanOpen}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <select
                          className="form-select"
                          onChange={handleChangeSelect}
                          value={selectedSuperAdmin}
                        >
                          <option value={"all"}>All Mini Admin</option>
                          {childLimits.map((item) => {
                            return (
                              <option value={item.id}>{item.username}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12 mb-2">
                        <h2 className="text-center">
                          {avaliableBalChild == null
                            ? "0"
                            : avaliableBalChild.toLocaleString()}
                        </h2>
                      </div>
                    </div>
                    {filteredChild.map((item) => {
                      return (
                        <div className="row">
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <h4>
                                {item.username} ({item.name})
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Total Limit"
                                name="total_limit"
                                disabled
                                value={item.total_limit ? item.total_limit : ""}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Current Limit"
                                name="current_limit"
                                onChange={(e) =>
                                  handleChangeLimitChild(e, item)
                                }
                                value={
                                  item.current_limit ? item.current_limit : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group">
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "10px" }}
                                onClick={(e) => updateLimitChild(e, item)}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => fetchReportById(e, item.id)}
                              >
                                REPORT
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-12">
                      <div className="form-button d-flex justify-content-end">
                        <button
                          className="btn btn-primary me-3"
                          onClick={(e) => handleFormChangeLimit(e, "close")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={isEditPanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form onSubmit={handleSubmitEdit}>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            name="username"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Admin"
                            name="master_user"
                            value={master_user}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Admin Limit"
                            name="master_user_limit"
                            value={master_user_limit}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            name="name"
                            value={name}
                          />
                          {nameErro && (
                            <span className="" style={{ color: "red" }}>
                              {nameErro}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            name="password"
                            value={password}
                          />
                          {passwordErr && (
                            <span className="" style={{ color: "red" }}>
                              {passwordErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Mobile"
                            onChange={handleChange}
                            name="mobile"
                            value={mobile}
                          />
                          {mobileErr && (
                            <span className="" style={{ color: "red" }}>
                              {mobileErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Super Admin Limit"
                            onChange={handleChange}
                            name="super_agent_limit"
                            value={super_agent_limit}
                          />
                          {super_agent_limitErr && (
                            <span className="" style={{ color: "red" }}>
                              {super_agent_limitErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Super Admin Share (%)"
                            onChange={handleChange}
                            name="super_agent_share"
                            value={super_agent_share}
                          />
                          {super_agent_shareErr && (
                            <span className="" style={{ color: "red" }}>
                              {super_agent_shareErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Match Commission (%)"
                            onChange={handleChange}
                            name="match_commission"
                            value={match_commission}
                          />
                          {match_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {match_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Session Commission (%)"
                            onChange={handleChange}
                            name="session_commission"
                            value={session_commission}
                          />
                          {session_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {session_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={(e) => handleFormChange(e, "close")}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success bg-dark p-3"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>LIMIT UPDATE REPORT</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Date & Time</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientReports.map((item) => {
                          return (
                            <tr>
                              <td>{item.amount_type}</td>
                              <td>{item.amount}</td>
                              <td>{moment(item.date).format("lll")}</td>
                              <td>{`limit ${item.amount_type.toLowerCase()} by ${
                                item.name
                              } (${item.username})`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* commission Model */}
                <Modal show={showCommissionModel} onHide={handleCloseCommissionModel}>
                  <Modal.Header closeButton>
                    <Modal.Title>COMMISSION UPDATE REPORT</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Date & Time</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commissionList.map((item) => {
                          return (
                            <tr>
                              <td>{'Commission'}</td>
                              <td>{item.match_commission_percentage}</td>
                              <td>{moment(item.created_at).format("lll")}</td>
                              <td>{`limit ${item.match_commission_percentage} by ${
                                item.username
                              } (${item.username})`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseCommissionModel}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* commission end */}

                  {/* share Report Model */}
                  <Modal show={showShareModel} onHide={handleCloseShareModel}>
                  <Modal.Header closeButton>
                    <Modal.Title>Share UPDATE REPORT</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Date & Time</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shareList.map((item) => {
                          return (
                            <tr>
                              <td>{'Commission'}</td>
                              <td>{item.share_percentage}</td>
                              <td>{moment(item.created_at).format("lll")}</td>
                              <td>{`limit ${item.share_percentage} by ${
                                item.username
                              } (${item.username})`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseShareModel}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* share Report end */}
                
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              onPageChange={pageChange}
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default SuperAdmin;
