import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import ClientNav from "../Common/ClientNav";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import ReactDatatable from "@mkikets/react-datatable";
import { useParams } from "react-router-dom";

const CompletedMatchUser = () => {
  const params = useParams();
  const [matchRecords, setMatchRecords] = useState([]);
  const [sessionRecords, setSessionRecords] = useState();
  const [matchBetTotal, setMatchBetTotal] = useState(0);
  const [sessionBetTotal, setSessionBetTotal] = useState(0);
  const [matchComm, setMatchComm] = useState(0);
  const [sessionComm, setSessionComm] = useState(0);
  const [wonBy, setWonBy] = useState("");

  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  const [team1Short, setTeam1Short] = useState("");
  const [team2Short, setTeam2Short] = useState("");

  const [matchName, setMatchName] = useState("");

  useEffect(() => {
    getMatchBet();
    getSessionBet();
    getCommission();
  }, []);

  // const columnsMatch = [
  //   {
  //     key: "",
  //     text: "SR No.",
  //     className: "action",
  //     align: "left",
  //     sortable: true,
  //     cell: (row, index) => index + 1,
  //   },

  //   {
  //     key: "rate",
  //     text: "Rate",
  //     className: "rate",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.rate}</span>;
  //     },
  //   },

  //   {
  //     key: "amount",
  //     text: "Amount",
  //     className: "amount",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.amount}</span>;
  //     },
  //   },

  //   {
  //     key: "mode",
  //     text: "Mode",
  //     className: "mode",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.mode}</span>;
  //     },
  //   },

  //   {
  //     key: "team",
  //     text: "Team",
  //     className: "team",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.team}</span>;
  //     },
  //   },

  //   {
  //     key: "syth",
  //     text: "Team 1",
  //     className: "syth",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       let showAmount = 0;
  //       let show = "";
  //       // all data will be declared ones

  //       if  = record.team)) {
  //         // above if is for dynamic name of title team 1 equals to database betting team
  //         if (record.mode == "L") {
  //           if (record.team == "WinningTeam") {
  //             // a if will be given that will match if the team == winning team
  //             show = "Green";
  //             showAmount = parseInt(record.rate * record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.amount);
  //           }
  //         } else {
  //           // mode is K
  //           if (record.team == "WinningTeam") {
  //             show = "Green";
  //             showAmount = parseInt(record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.rate * record.amount);
  //           }
  //         }
  //       } else {
  //         // team 2
  //         if (record.mode == "L") {
  //           if (record.team == "WinningTeam") {
  //             // a if will be given that will match if the team == winning team
  //             show = "Green";
  //             showAmount = parseInt(record.rate * record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.amount);
  //           }
  //         } else {
  //           // mode is K
  //           if (record.team == "WinningTeam") {
  //             show = "Green";
  //             showAmount = parseInt(record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.rate * record.amount);
  //           }
  //         }
  //       }
  //       return <span style={{ color: show }}>{showAmount}</span>;
  //     },
  //   },
  //   {
  //     key: "adst",
  //     text: "Team 2",
  //     className: "adst",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       let showAmount = 0;
  //       let show = "";
  //       // all data will be declared ones

  //       if ((record.team1 = record.team)) {
  //         // above if is for dynamic name of title team 1 equals to database betting team
  //         if (record.mode == "L") {
  //           if (record.team == "WinningTeam") {
  //             // a if will be given that will match if the team == winning team
  //             show = "Green";
  //             showAmount = parseInt(record.rate * record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.amount);
  //           }
  //         } else {
  //           // mode is K
  //           if (record.team == "WinningTeam") {
  //             show = "Green";
  //             showAmount = parseInt(record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.rate * record.amount);
  //           }
  //         }
  //       } else {
  //         // team 2
  //         if (record.mode == "L") {
  //           if (record.team == "WinningTeam") {
  //             // a if will be given that will match if the team == winning team
  //             show = "Green";
  //             showAmount = parseInt(record.rate * record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.amount);
  //           }
  //         } else {
  //           // mode is K
  //           if (record.team == "WinningTeam") {
  //             show = "Green";
  //             showAmount = parseInt(record.amount);
  //           } else {
  //             show = "Red";
  //             showAmount = parseInt(record.rate * record.amount);
  //           }
  //         }
  //       }
  //       return <span style={{ color: show }}>{showAmount}</span>;
  //     },
  //   },
  // ];

  const matchBettingTable = () => {
    if (matchRecords.length > 0) {
      return matchRecords.map((record, index) => {
        let showAmount = 0;
        let show = "";

        let showAmount2 = 0;
        let show2 = "";
        // all data will be declared ones

        if (team1 == record.team) {
          console.log(team1 == record.team, " team1 == record.team");
          // above if is for dynamic name of title team 1 equals to database betting team
          if (record.mode == "L") {
            console.log(
              "record.team == record.winning_team-L",
              record.team == record.winning_team
            );
            if (record.team == record.winning_team) {
              // a if will be given that will match if the team == winning team
              show2 = "Green";
              show = "Red";
              showAmount2 = Number(record.rate) * Number(record.amount);
              showAmount = Number(record.amount);
            } else {
              show2 = "Red";
              show = "Green";
              showAmount2 = Number(record.amount);
              showAmount = Number(record.rate) * Number(record.amount);
            }
          } else {
            // mode is K
            console.log(
              "record.team == record.winning_team-K",
              record.team,
              record.winning_team
            );
            console.log(
              "record.team == record.winning_team-K",
              record.team == record.winning_team
            );

            if (record.team == record.winning_team) {
              show2 = "Green";
              show = "Red";
              showAmount2 = Number(record.amount);
              showAmount = Number(record.rate) * Number(record.amount);
            } else {
              show2 = "Green";
              show = "Red";
              showAmount2 = Number(record.amount);
              showAmount = Number(record.rate) * Number(record.amount);
            }
          }
        } else {
          console.log(team2 == record.team, " team2 == record.team");

          // team 2
          if (record.mode == "L") {
            console.log(
              "record.team == record.winning_team-L",
              record.team == record.winning_team
            );

            if (record.team == record.winning_team) {
              // a if will be given that will match if the team == winning team
              show = "Red";
              show2 = "Green";
              showAmount = Number(record.amount);
              showAmount2 = Number(record.rate) * Number(record.amount);
            } else {
              show = "Green";
              show2 = "Red";
              showAmount2 = Number(record.amount);
              showAmount = Number(record.rate) * Number(record.amount);
            }
          } else {
            // mode is K
            console.log(
              "record.team == record.winning_team-K",
              record.team == record.winning_team
            );

            if (record.team == record.winning_team) {
              show = "Green";
              show2 = "Red";
              showAmount = Number(record.amount);
              showAmount2 = Number(record.rate) * Number(record.amount);
            } else {
              show = "Red";
              show2 = "Green";
              showAmount = Number(record.rate * record.amount);
              showAmount2 = Number(record.amount);
            }
          }
        }

        // if (team2 == record.team) {
        //   console.log(team1 == record.team, " team1 == record.team");
        //   // above if is for dynamic name of title team 1 equals to database betting team
        //   if (record.mode == "L") {
        //     console.log(
        //       "record.team == record.winning_team-L",
        //       record.team == record.winning_team
        //     );
        //     if (record.team == record.winning_team) {
        //       // a if will be given that will match if the team == winning team
        //       show2 = "Green";
        //       showAmount2 = parseInt(record.rate * record.amount);
        //     } else {
        //       show2 = "Red";
        //       showAmount2 = parseInt(record.amount);
        //     }
        //   } else {
        //     // mode is K
        //     console.log(
        //       "record.team == record.winning_team-L",
        //       record.team == record.winning_team
        //     );

        //     if (record.team == record.winning_team) {
        //       show2 = "Green";
        //       showAmount2 = parseInt(record.amount);
        //     } else {
        //       show2 = "Red";
        //       showAmount2 = parseInt(record.rate) * parseInt(record.amount);
        //     }
        //   }
        // } else {
        //   console.log(team2 == record.team, " team2 == record.team");
        //   // team 1
        //   if (record.mode == "L") {
        //     console.log(
        //       "record.team == record.winning_team-L",
        //       record.team == record.winning_team
        //     );
        //     if (record.team == record.winning_team) {
        //       // a if will be given that will match if the team == winning team
        //       show2 = "Green";
        //       showAmount2 = parseInt(record.rate) * parseInt(record.amount);
        //     } else {
        //       show2 = "Red";
        //       showAmount2 = parseInt(record.amount);
        //     }
        //   } else {
        //     // mode is K
        //     console.log(
        //       "record.team == record.winning_team-L",
        //       record.team == record.winning_team
        //     );

        //     if (record.team == record.winning_team) {
        //       show2 = "Green";
        //       showAmount2 = parseInt(record.amount);
        //     } else {
        //       show2 = "Red";
        //       showAmount2 = parseInt(record.rate * record.amount);
        //     }
        //   }
        // }

        return (
          <tr>
            <td>{index + 1}</td>
            <td>{Number(record.rate)}</td>
            <td>{record.amount}</td>
            <td>{record.mode}</td>
            <td>{record.team}</td>
            <td style={{ color: show }}>{Math.round(showAmount)}</td>
            <td style={{ color: show2 }}>{Math.round(showAmount2)}</td>
            <td>{"The draw is static for now"}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <td>No Data Found</td>
          <td>-</td>
        </tr>
      );
    }
  };

  // const configMatch = {
  //   page_size: 10,
  //   length_menu: [10, 20, 50],
  //   filename: "Fund Request List",
  //   no_data_text: "No user found!",
  //   button: {
  //     print: false,
  //     csv: false,
  //   },
  //   language: {
  //     length_menu: "Show _MENU_ result per page",
  //     filter: "Filter in records...",
  //     info: "Showing _START_ to _END_ of _TOTAL_ records",
  //     pagination: {
  //       first: "First",
  //       previous: "Previous",
  //       next: "Next",
  //       last: "Last",
  //     },
  //   },
  //   show_length_menu: true,
  //   show_filter: true,
  //   show_pagination: true,
  //   show_info: true,
  // };

  const getMatchBet = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(
        baseUrl + "/api/get-user-match-bet",
        { matchId: params.matchId },
        config
      )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let match_name = resp.data[0].match_name;
          const [teamA, teamB] = match_name.split(" v ");

          setTeam1(teamA);
          setTeam2(teamB);

          const trimTeamA = teamA.split(" ");
          const trimTeamB = teamB.split(" ");

          let shortTeamNameA = trimTeamA[0].slice(0, 2).toUpperCase();

          // If there is a second word, take the first two characters from it
          if (trimTeamA.length > 1) {
            shortTeamNameA += trimTeamA[1].slice(0, 2).toUpperCase();
          } else {
            // If there is no second word, take the next two characters from the first word
            shortTeamNameA += trimTeamA[0].slice(2, 4).toUpperCase();
          }

          let shortTeamNameB = trimTeamB[0].slice(0, 2).toUpperCase();

          // If there is a second word, take the first two characters from it
          if (trimTeamB.length > 1) {
            shortTeamNameB += trimTeamB[1].slice(0, 2).toUpperCase();
          } else {
            // If there is no second word, take the next two characters from the first word
            shortTeamNameB += trimTeamB[0].slice(2, 4).toUpperCase();
          }

          setTeam1Short(shortTeamNameA);
          setTeam2Short(shortTeamNameB);

          let filteredData = resp.data.filter(filterMatch);

          setMatchRecords(filteredData);

          setMatchName(resp.data[0].match_name);
          setWonBy(resp.data[0].winning_team);
        }
      });
  };

  // const columnsSession = [
  //   {
  //     key: "",
  //     text: "SR No.",
  //     className: "action",
  //     align: "left",
  //     sortable: true,
  //     cell: (row, index) => index + 1,
  //   },
  //   {
  //     key: "session",
  //     text: "Session",
  //     className: "session",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.session}</span>;
  //     },
  //   },
  //   {
  //     key: "session_rate",
  //     text: "Rate",
  //     className: "session_rate",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.session_rate}</span>;
  //     },
  //   },

  //   {
  //     key: "amount",
  //     text: "Amount",
  //     className: "amount",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.amount}</span>;
  //     },
  //   },
  //   {
  //     key: "run",
  //     text: "Runs",
  //     className: "run",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.run}</span>;
  //     },
  //   },

  //   {
  //     key: "session_mode",
  //     text: "Mode",
  //     className: "session_mode",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.session_mode}</span>;
  //     },
  //   },
  //   {
  //     key: "declared",
  //     text: "Decl",
  //     className: "declared",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       return <span>{record.decl}</span>;
  //     },
  //   },

  //   {
  //     key: "profit",
  //     text: "Profit & Loss",
  //     className: "profit",
  //     align: "left",
  //     sortable: true,
  //     cell: (record) => {
  //       let show = "";
  //       let showAmount = 0;

  //       if (record.session_mode == "YES") {
  //         if (parseInt(record.run) <= parseInt(record.decl)) {
  //           show = "Green";
  //           showAmount = record.amount * record.session_rate;
  //         } else {
  //           show = "Red";
  //           showAmount = record.amount;
  //         }
  //       } else {
  //         if (parseInt(record.run) > parseInt(record.decl)) {
  //           show = "Green";
  //           showAmount = record.amount;
  //         } else {
  //           show = "Red";
  //           showAmount = record.amount * record.session_rate;
  //         }
  //       }

  //       return <span style={{ color: show }}>{showAmount}</span>;
  //     },
  //   },
  // ];

  const sessionBettingTable = () => {
    if (sessionRecords?.length > 0) {
      return sessionRecords.map((record, index) => {
        let showAmount = 0;
        let show = "";

        if (record.session_mode == "YES") {
          if (parseInt(record.run) <= parseInt(record.decl)) {
            show = "Green";
            showAmount = Number(record.amount) * Number(record.session_rate);
          } else {
            show = "Red";
            showAmount = Number(record.amount);
          }
        } else {
          if (parseInt(record.run) > parseInt(record.decl)) {
            show = "Green";
            showAmount = Number(record.amount);
          } else {
            show = "Red";
            showAmount = Number(record.amount) * Number(record.session_rate);
          }
        }

        return (
          <tr>
            <td>{index + 1}</td>
            <td>{record.session}</td>
            <td>{record.session_rate}</td>
            <td>{record.amount}</td>
            <td>{record.run}</td>
            <td>{record.session_mode}</td>
            <td>{record.decl}</td>
            <td style={{ color: show }}>{Math.round(showAmount)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <td>No Data Found</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
  };

  // const configSession = {
  //   page_size: 10,
  //   length_menu: [10, 20, 50],
  //   filename: "Fund Request List",
  //   no_data_text: "No user found!",
  //   button: {
  //     print: false,
  //     csv: false,
  //   },
  //   language: {
  //     length_menu: "Show _MENU_ result per page",
  //     filter: "Filter in records...",
  //     info: "Showing _START_ to _END_ of _TOTAL_ records",
  //     pagination: {
  //       first: "First",
  //       previous: "Previous",
  //       next: "Next",
  //       last: "Last",
  //     },
  //   },
  //   show_length_menu: true,
  //   show_filter: true,
  //   show_pagination: true,
  //   show_info: true,
  // };

  const getCommission = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setMatchComm(resp.data[0].match_commission_percentage);
        setSessionComm(resp.data[0].session_commission_percentage);
      }
    });
  };

  const getSessionBet = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(
        baseUrl + "/api/get-user-session-bet",
        { matchId: params.matchId },
        config
      )
      .then((res) => {
        const resp = res.data;
        console.log("resp", resp);
        if (resp.success === true) {
          console.log("resp.data", resp.data);

          let filteredData = resp.data.filter(filterSession);
          setSessionRecords(filteredData);
          setMatchName(resp.data[0].match_name);
        }
      });
  };

  const filterMatch = (data) => {
    return data.match_declared == "YES";
  };

  const filterSession = (data, type) => {
    return data.declared == "YES";
  };

  const matchTotalHtml = () => {
    let sum = 0;

    if (matchRecords && matchRecords.length > 0) {
      let filteredData = matchRecords.filter((data) => {
        return data.winning_team == data.team;
      });

      for (let i = 0; i < filteredData.length; i++) {
        const record = filteredData[i];

        if (record.mode == "L") {
          sum = sum + Number(record.rate) * Number(record.amount);
        } else {
          sum = sum - Number(record.rate) * Number(record.amount);
        }
      }
    }

    return (
      <h3>
        {" "}
        {Math.round(sum) > 0 ? "You Won" : "You Lost"}{" "}
        {Math.round(Math.abs(sum))}{" "}
      </h3>
    );
  };

  const sessionTotalHtml = () => {
    let sum = 0;

    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        if (element.session_mode == "YES") {
          if (Number(element.run) <= Number(element.decl)) {
            sum += Number(element.amount) * Number(element.session_rate);
          } else {
            sum -= Number(element.amount);
          }
        } else {
          if (Number(element.run) > Number(element.decl)) {
            sum += Number(element.amount);
          } else {
            sum -= Number(element.amount) * Number(element.session_rate);
          }
        }
      }
    }

    return (
      <h3>
        {" "}
        {Math.round(sum) > 0 ? "You Won" : "You Lost"}{" "}
        {Math.round(Math.abs(sum))}{" "}
      </h3>
    );
  };

  const matchSessionTotal = () => {
    let sumMatch = 0;
    let sumSession = 0;
    let total = 0;

    if (matchRecords && matchRecords.length > 0) {
      let filteredData = matchRecords.filter((data) => {
        return data.winning_team == data.team;
      });

      for (let i = 0; i < filteredData.length; i++) {
        const record = filteredData[i];

        if (record.mode == "L") {
          sumMatch = sumMatch + Number(record.rate) * Number(record.amount);
        } else {
          sumMatch = sumMatch - Number(record.rate) * Number(record.amount);
        }
      }
    }

    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        if (element.session_mode == "YES") {
          if (Number(element.run) <= Number(element.decl)) {
            sumSession += Number(element.amount) * Number(element.session_rate);
          } else {
            sumSession -= Number(element.amount);
          }
        } else {
          if (Number(element.run) > Number(element.decl)) {
            sumSession += Number(element.amount);
          } else {
            sumSession -= Number(element.amount) * Number(element.session_rate);
          }
        }
      }
    }

    total = Number(sumMatch) + Number(sumSession);

    return (
      <h3>
        {" "}
        {Math.round(total) > 0 ? "You Won" : "You Lost"}{" "}
        {Math.round(Math.abs(total))} Coins{" "}
      </h3>
    );
  };

  const sessionCommissionTotalHtml = () => {
    let sum = 0;

    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        sum += Number(element.amount);
      }
    }
    let commission = (Number(sum) * Number(sessionComm)) / 100;

    return <h3>Your Commission is {Math.abs(Math.round(commission))}</h3>;
  };

  const matchCommissionTotalHtml = () => {
    let sumMatch = 0;

    if (matchRecords && matchRecords.length > 0) {
      let filteredData = matchRecords.filter((data) => {
        return data.winning_team == data.team;
      });

      for (let i = 0; i < filteredData.length; i++) {
        const record = filteredData[i];

        if (record.mode == "L") {
          sumMatch = sumMatch + Number(record.rate) * Number(record.amount);
        } else {
          sumMatch = sumMatch - Number(record.rate) * Number(record.amount);
        }
      }
    }

    let commission = 0;

    if (Number(sumMatch) < 0) {
      commission = (Number(sumMatch) * Number(matchComm)) / 100;
    } else {
      commission = 0;
    }

    return <h3>Your Commission is {Math.round(commission)}</h3>;
  };

  const matchSessionCommissionTotalHtml = () => {
    let sumMatch = 0;
    let sumSession = 0;

    if (matchRecords && matchRecords.length > 0) {
      let filteredData = matchRecords.filter((data) => {
        return data.winning_team == data.team;
      });

      for (let i = 0; i < filteredData.length; i++) {
        const record = filteredData[i];

        if (record.mode == "L") {
          sumMatch += Number(record.rate) * Number(record.amount);
        } else {
          sumMatch -= Number(record.rate) * Number(record.amount);
        }
      }
    }

    let commissionMatch = 0;

    if (Number(sumSession) < 0) {
      commissionMatch = (Number(sumMatch) * Number(matchComm)) / 100;
    } else {
      commissionMatch = 0;
    }

    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        sumSession += Number(element.amount);
      }
    }
    let commissionSession = (Number(sumSession) * Number(sessionComm)) / 100;

    const totalComm =
      Math.round(Math.abs(commissionSession)) +
      Math.round(Math.abs(commissionMatch));
    return <h3>Your Total Commission is {Math.round(totalComm)}</h3>;
  };

  const netPlusMinusHtml = () => {
    let sumMatch = 0;
    let sumSession = 0;

    if (matchRecords && matchRecords.length > 0) {
      let filteredData = matchRecords.filter((data) => {
        return data.winning_team == data.team;
      });

      for (let i = 0; i < filteredData.length; i++) {
        const record = filteredData[i];

        if (record.mode == "L") {
          sumMatch = sumMatch + Number(record.rate) * Number(record.amount);
        } else {
          sumMatch = sumMatch - Number(record.rate) * Number(record.amount);
        }
      }
    }

    let commissionMatch = 0;

    if (Number(sumMatch) < 0) {
      commissionMatch = (Number(sumMatch) * Number(matchComm)) / 100;
    } else {
      commissionMatch = 0;
    }

    // for calculation of session total
    let sessionTotalCalculation = 0;
    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        if (element.session_mode == "YES") {
          if (Number(element.run) <= Number(element.decl)) {
            sessionTotalCalculation +=
              Number(element.amount) * Number(element.session_rate);
          } else {
            sessionTotalCalculation -= Number(element.amount);
          }
        } else {
          if (Number(element.run) > Number(element.decl)) {
            sessionTotalCalculation += Number(element.amount);
          } else {
            sessionTotalCalculation -=
              Number(element.amount) * Number(element.session_rate);
          }
        }
      }
    }

    // for calculation of session commission

    if (sessionRecords && sessionRecords.length > 0) {
      for (let i = 0; i < sessionRecords.length; i++) {
        const element = sessionRecords[i];

        sumSession += Number(element.amount);
      }
    }

    let commissionSession = (Number(sumSession) * Number(sessionComm)) / 100;

    const totalComm =
      Math.round(Math.abs(commissionSession)) +
      Math.round(Math.abs(commissionMatch));

    // match and session total without comm
    console.log("sumMatch", sumMatch);
    console.log("sumSession", sessionTotalCalculation);

    console.log(
      "Number(sumMatch) + Number(sessionTotalCalculation)",
      Number(sumMatch),
      Number(sessionTotalCalculation)
    );

    const total = Number(sumMatch) + Number(sessionTotalCalculation);

    const netPlusMinus = total - totalComm;

    return <h3>Net Plus Minus is {Math.round(netPlusMinus)}</h3>;
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="super-agent">
            <div className="super-outer">
              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Name</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{matchName}</div>
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Bet(s) Won BY : {wonBy}</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">
                    <div className="col-12">
                      <div className="super-inner super-left">
                        <div className="table-responsive">
                          <table className="w-100 mb-2">
                            <thead>
                              <tr>
                                <th>SR No.</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Mode</th>
                                <th>Team</th>
                                <th>{team1Short}</th>
                                <th>{team2Short}</th>
                                <th>The Draw</th>
                              </tr>
                            </thead>
                            <tbody>{matchBettingTable()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {/* <ReactDatatable
                      config={configMatch}
                      records={matchRecords}
                      columns={columnsMatch}
                      //   onPageChange={this.pageChange.bind(this)}
                    /> */}
                  </div>
                  {matchTotalHtml()}
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Session Bet(s)</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">
                    <div className="col-12">
                      <div className="super-inner super-left">
                        <div className="table-responsive">
                          <table className="w-100 mb-2">
                            <thead>
                              <tr>
                                <th>SR No.</th>
                                <th>Session</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Runs</th>
                                <th>Mode</th>
                                <th>Decl</th>
                                <th>Profit & Loss</th>
                              </tr>
                            </thead>
                            <tbody>{sessionBettingTable()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {/* <ReactDatatable
                      config={configSession}
                      records={sessionRecords}
                      columns={columnsSession}
                      //   onPageChange={this.pageChange.bind(this)}
                    /> */}
                  </div>

                  {sessionTotalHtml()}
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Session Plus Minus</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{matchSessionTotal()}</div>
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Commission</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{matchCommissionTotalHtml()}</div>
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Session Commission</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{sessionCommissionTotalHtml()}</div>
                </div>
              </div>

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Total Commission</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">
                    {matchSessionCommissionTotalHtml()}
                  </div>
                </div>
              </div>

              {/* <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Amount After Comm.</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{netPlusMinusHtml()}</div>
                </div>
              </div> */}

              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Net Plus Minus</h4>
                </div>
              </div>

              <div className="row mb-3">
                <div className="leader-main">
                  <div className="leader-outer">{netPlusMinusHtml()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedMatchUser;
