import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import socketIOClient from "socket.io-client";
import { baseUrl, socketUrl } from "../Common/BaseUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const AdminMatchSession = () => {
  const navigate = useNavigate();
  const matchId = useParams();
  const clientRef = useRef(null);
  const [clientRecords, setClientRecords] = useState([]);


  useEffect(() => {
    // alert(JSON.stringify(matchId));
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    }
    fetchMatches();
  }, []);

  const fetchMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-sports-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
      navigate(`/admin/matchSession/${value}`);
   
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="match-detail">
        <div className="col-md-8 col-12">
                      <div className="form-group">
                        <label htmlFor="event">Choose Match:</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="recipient"
                        >
                          {clientRecords.map((item) => {
                            return (
                              <option value={item.match_id}>
                                {item.match_name}
                              </option>
                            );
                          })}
                      
                        </select>
                      
                      </div>
                    </div>

          <div class="col-md-12 col-12">
            <div class="row res-table">
              <table class="table table-striped table-bordered table-responsive mobile-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="9" class="text-center bg-dark text-white">CLIENT P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">AGENT P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">SUPER AGENT P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">MASTER P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">MINI ADMIN P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">SUPER ADMIN P/L</th>
                    <th colspan="6" class="text-center bg-dark text-white">ADMIN P/L</th>
                  </tr>
                  <tr class="">
                    <th class="small" >Client</th>
                    <th class="small" >M.AMT</th>
                    <th class="small">S.AMT</th>
                    <th class="small" >TOT.AMT</th>

                    <th class="small">M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small">TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small">SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                    <th class="small" >M.COM</th>
                    <th class="small" >S.COM</th>
                    <th class="small" >TOT.COM</th>
                    <th class="small" >NET.AMT</th>
                    <th class="small" >SHR.AMT</th>
                    <th class="small" >FINAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="small">Client 5</td>
                    <td class="small text-danger">-500</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>

                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-300</td>
                    <td class="small text-danger">-200</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-375</td>
                    <td class="small text-danger">-125</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-400</td>
                    <td class="small text-danger">-100</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-450</td>
                    <td class="small text-danger">-50</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-475</td>
                    <td class="small text-danger">-25</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-500</td>
                    <td class="small text-danger">-500</td>
                    <td class="small ">0</td>
                  </tr>
                  <tr>
                    <td class="small">testa</td>
                    <td class="small text-danger">-21</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>

                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-6</td>
                    <td class="small text-danger">-15</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-13</td>
                    <td class="small text-danger">-8</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-15</td>
                    <td class="small text-danger">-6</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-17</td>
                    <td class="small text-danger">-4</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-19</td>
                    <td class="small text-danger">-2</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small ">0</td>
                    <td class="small text-danger">-21</td>
                    <td class="small text-danger">-21</td>
                    <td class="small ">0</td>
                  </tr>
                  <tr>
                    <td class="text-right bg-dark text-white">TOTAL</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>

                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-306</td>
                    <td class="small text-danger" >-215</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-388</td>
                    <td class="small text-danger" >-133</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-415</td>
                    <td class="small text-danger" >-106</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-467</td>
                    <td class="small text-danger" >-54</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-494</td>
                    <td class="small text-danger" >-27</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small " >0</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small text-danger" >-521</td>
                    <td class="small " >0</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default AdminMatchSession;
