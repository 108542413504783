import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });
  useEffect(()=>{
    setTimeout(() => {
      if (userRole === "CLIENT") {
        navigate("/");
      } else {
        navigate("/admin");
      }

    }, 2000);
  },[])

  return (
    <div className="not_found">
      <div>
        <img src="/assets/img/not_found.png" className="img-fluid" alt="" />
        <h1 className="mt-4">OOPS! PAGE NOT FOUND</h1>
        <a className="btn btn_man mt-4  w100px mt-4" href="/">
          Go Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
