import React, { useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import { useNavigate } from "react-router-dom";

const AllSuperAgentLedger = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="match-detail">
        <div className="super-agent">
          <div className="section-heading">
            <h1>All Super Agent Ledger</h1>
          </div>
          <div className="super-outer">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="super-inner super-left">
                  <h6 className="text-center">Payment Receiving From (Lena Hai)</h6>
                  <div className="table-responsive">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>Agent</th>
                          <th>Amount</th>
                          <th>Settled</th>
                          <th>Final</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="take">4,361</td>
                          <td className="take">4,313</td>
                          <td className="take">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="take">4,361</td>
                          <td className="take">4,313</td>
                          <td className="take">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="take">4,361</td>
                          <td className="take">4,313</td>
                          <td className="take">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="take">4,361</td>
                          <td className="take">4,313</td>
                          <td className="take">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Lena Hai</td>
                          <td className="take">1,23,488</td>
                          <td className="take">1,19,813</td>
                          <td className="take">3,675</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="super-inner super-right">
                  <h6 className="text-center">Payment Paid To (Dena Hai)</h6>
                  <div className="table-responsive">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>Agent</th>
                          <th>Amount</th>
                          <th>Settled</th>
                          <th>Final</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="give">4,361</td>
                          <td className="give">4,313</td>
                          <td className="give">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="give">4,361</td>
                          <td className="give">4,313</td>
                          <td className="give">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="give">4,361</td>
                          <td className="give">4,313</td>
                          <td className="give">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>SA60 (Lucky 90)</td>
                          <td className="give">4,361</td>
                          <td className="give">4,313</td>
                          <td className="give">48</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-primary"
                            >
                              Settlement
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Lena Hai</td>
                          <td className="give">1,23,488</td>
                          <td className="give">1,19,813</td>
                          <td className="give">3,675</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default AllSuperAgentLedger;
