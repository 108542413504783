import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import ReactDatatable from "@mkikets/react-datatable";
import { useNavigate } from "react-router-dom";
import AdminNav from "../Common/AdminNav";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import moment from "moment";

const MyLedger = () => {
  const navigate = useNavigate();
  const [clientRecords, setClientRecords] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    } else {
      fetchMyLadgerData();
      generateUserLeadger()
    }
  }, []);

  const fetchMyLadgerData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    axios.post(baseUrl + "/api/fetch-my-leadger", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const generateUserLeadger = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    axios.post(baseUrl + "/api/generate-my-leadger", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        fetchMyLadgerData();
        // setClientRecords(resp.data);
      }
    });
  };

  const columns = [
    {
      key: "added_at",
      text: "DATE",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{moment(record.added_at).format("lll")}</span>;
      },
    },
    {
      key: "won",
      text: "CREDIT",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span style={{ color: "green" }}>{Math.abs(record.won)}</span>;
      },
    },
    {
      key: "lost",
      text: "DEBIT",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span style={{ color: "red" }}>{record.lost}</span>;
      },
    },
    {
      key: "balance",
      text: "BALANCE.",
      className: "balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        let total = record.won + record.lost;
        return (
          <span style={total > 0 ? { color: "green" } : { color: "red" }}>
            {total}
          </span>
        );
      },
    },

    {
      key: "won_by",
      text: "WINNER / Remark",
      className: "winner",
      align: "left",
      sortable: true,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>My Ledger</h1>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <div className="total-footer position-fixed">
        <div className="row align-items-center">
          <div className="col-md-6 col-6">
            <div className="total-left text-center">
              <p>TOTAL</p>
            </div>
          </div>
          <div className="col-md-6 col-6">
            <div className="total-right plus text-center">
              <p>4,352</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLedger;
