import React from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";

const ClientLedger = () => {
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Client Ledger</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLedger;
