import React, { useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import { useNavigate } from "react-router-dom";

const AllAgentLedger = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>All Agent Ledger</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAgentLedger;
