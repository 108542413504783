import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { useNavigate } from "react-router-dom";
import ClientNav from "./ClientNav";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const tc = localStorage.getItem("tc_verified");
    if (tc === "false") {
      navigate("/client/tc");
    } else {
      navigate("/client/dashboard");
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    navigate("/client/dashboard");
    localStorage.setItem("tc_verified", "true");
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Terms & Conditions</h1>
          </div>
          <div className="leader-main terms-main">
            {/* <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="leader-outer terms-outer">
              <h4>Heading Goes Here</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div> */}
            <p>1.⁠ ⁠लोगिन करने के बाद अपना पासवर्ड बदल लें।</p>
            <p>
              2.⁠ गेम रद्द होने या टाई होने पर मैच के सभी सौदे रद्द माने जायेंगे
              और जो सेशन पुरे हो चुके हे, उनके हिसाब से ही <b>Coins</b> कम या
              ज्यादा होंगे।
            </p>
            <p>
              3.⁠ ⁠मैच के दौरान भाव को देख व समझ के ही सौदा करे। किये गए किसी भी
              सौदे को हटाया या बदला नहीं जाएगा। सभी सौदे के लिए स्वयं आप ही
              जिम्मेदार होंगे।
            </p>
            <p>
              4.⁠ ⁠मैच या सेशन भाव गलत चलने पर जो भी मैच या सेशन के सौदे हुए हे
              वह स्वतः हट जायेंगे।
            </p>
            <p>
              5.⁠ ⁠मैच में जो सेशन कम्पलीट होंगे सिर्फ उनके हिसाब से कॉइन कम या
              ज्यादा होंगे और जो सेशन कम्पलीट नहीं हुए है बो सारे सेशन रद्द हो
              जाएंगे।
            </p>
            <p>
              6.⁠ ⁠मैच मैं ओवर कम होने पर एडवांस सेसन फैंसी कैंसल हो जाएंगी।
            </p>
            <p>
              7.⁠ ⁠मैच में ओवर कम होने पर अगर सेम टीम दुबारा खेलने के लिए आती है
              तो जो रनिंग में प्लेयर के रन और पार्टनरशीप डिक्लेयर होगी। अगर ओवर
              कम होने पर दूसरी टीम खेलने के लिए आती है तो जो रनिंग में प्लेयर
              रन, पार्टनरशीप रद्द हो जाएंगे।
            </p>
            <p>
              8.⁠ ⁠प्लेयर के रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर के रन रद्द
              माने जाएंगे| अगर बो प्लेयर दुबारा खेलने के लिए आता है तो उसके रन
              डिक्लेअर कर दिए जायेंगे।
            </p>
            <p>
              9.⁠ ⁠सेशन को डिक्लेअर और कैंसिल करने के लिए कंपनी का निर्णय अन्तिम
              होगा| ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं होगा।
            </p>
            <p>
              10.⁠ ⁠टेस्ट में पारी डिक्लेअर होने पर जो सेशन रनिंग में हे उस सेशन
              को डिक्लेअर करने के लिए दूसरी टीम के ओवर या बॉल काउंट किये
              जायेंगे।
            </p>
            <p>
              नोट : सर्वर या वेबसाईट में किसी तरह की खराबी आने या बंद हो जाने पर
              केवल किये गए सौदे ही मान्य होंगे। ऐसी स्थिति में किसी भी तरह का
              वाद-विवाद मान्य नहीं होगा।
            </p>
          </div>
          <div className="col-12">
            <button
              className="btn btn-primary w-100 mt-2"
              onClick={handleClick}
            >
              Continue
            </button>
          </div>
          <div
            className={`modal ${showModal ? "show" : ""}`}
            style={{ display: showModal ? "block" : "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {/* Modal Header */}
                <div className="modal-header text-center">
                  <h5 className="modal-title">Welcome To 11 Leoexch</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleCloseModal}
                  ></button>
                </div>

                {/* Modal Body */}
                <div className="modal-body"></div>

                {/* Modal Footer */}
                <div className="modal-footer justify-content-center">
                  <h4>Thanks For Visiting Our Site</h4>
                </div>
              </div>
            </div>
          </div>

          {/* Modal backdrop */}
          <div
            className={`modal-backdrop ${showModal ? "show" : ""}`}
            style={{ display: showModal ? "block" : "none" }}
            onClick={handleCloseModal}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
