import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { Link,useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { Modal, Button, Table, Dropdown } from "react-bootstrap";
import moment from "moment";
import AdminNav from "../Common/AdminNav";

const AdminClientMaster = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [age_limit, setAge_limit] = useState("0");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [client_limit, setClient_limit] = useState("");
  const [match_commission, setMatch_commission] = useState("");
  const [session_commission, setSession_commission] = useState("");
  const [isPanActive, setIsPanActive] = useState("tab-pane fade");
  const [clientRecords, setClientRecords] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedAgentErr, setSelectedAgentErr] = useState("");
  const [nameErro, setNameErro] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [clientLimitError, setClientLimitError] = useState("");
  const [match_commissionErr, setMatch_commissionErr] = useState("");
  const [session_commissionErr, setSession_commissionErr] = useState("");
  const [isParamsTrue, setIsParamsTrue] = useState(false);
  const [isUpdatePanActive, setIsUpdatePanActive] = useState("tab-pane fade");
  const [avaliableBal, setAvaliableBal] = useState("0.00");
  const [childWalletLimit, setChildWalletLimit] = useState([]);
  const [selectedAgentUser, setSelectedAgentUser] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [clientReports, setClientReports] = useState([]);
  const [isEditPanActive, setIsEditPanActive] = useState("tab-pane fade");
  const [childId, setChildId] = useState("");
  const [commissionList, setCommissionList] = useState([]);
  const [showCommissionModel, setShowCommissionModel] = useState(false);
  const [shareList, setShareList] = useState([]);
  const [showShareModel, setShowShareModel] = useState(false);

  
  const [formData, setFormData] = useState({
    total_limit: "",
    current_limit: "",
  });

  const getCurrentUserLimit = (userName) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const selectedUserData = {
      clientUserName: userName,
    };
    axios
      .post(baseUrl + "/api/fetch-user-limit-child", selectedUserData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          if (resp.data.length == 0 || resp.data[0].user_limit == null) {
            setAvaliableBal("0.00");
            setAge_limit("0");
          } else {
            setAge_limit(resp.data && resp.data[0].user_limit);
            setAvaliableBal(resp.data && resp.data[0].user_limit);
          }
        }
      });
  };

  const columns = [
    {
      key: "",
      text: "",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <button className="btn"></button>
          </span>
        );
      },
    },
    {
      key: "",
      text: "",
      className: "options",
      align: "left",
      sortable: true,
      cell: (record) => {
        const deactivateText =
          record.is_active === "Y" ? "Deactivate" : "Activate";
        return (
          <span>
            <Dropdown onSelect={(event) => handleDropdownSelect(event, record)}>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
              ></Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="edit"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Edit ({record.username})
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="deactivate"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {deactivateText} ({record.username})
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="match-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                 {record?.isMatchLock=="N"?'Match Lock':"Match UnLock" } 
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="match-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                 {record?.isMatch=="N"?'Match ON':"Match OFF" } 
                </Dropdown.Item>


                <Dropdown.Item
                  eventKey="fancy-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isFancyLock=="N"?'Fancy Lock':"Fancy UnLock" } 
                  
                </Dropdown.Item>


                <Dropdown.Item
                  eventKey="fancy-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isFancy=="N"?'Fancy ON':"Fancy OFF" } 
                  
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="casino-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isCasinoLock=="N"?'Casino Lock':"Casino UnLock" } 
                  
                </Dropdown.Item>



                <Dropdown.Item
                  eventKey="casino-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isCasino=="N"?'Casino ON':"Casino OFF" } 
                  
                </Dropdown.Item>




                <Dropdown.Item
                  eventKey="bm-lock"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                {record?.isBMLock=="N"?'BM Lock':"BM UnLock" } 
                  
                </Dropdown.Item>


                <Dropdown.Item
                  eventKey="bm-enable"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  {record?.isBM=="N"?'Bm ON':"Bm OFF" } 
                  
                </Dropdown.Item>



                <Dropdown.Item
                  eventKey="limit-updates"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Limit updates details
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="share-details"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Share updates details
                </Dropdown.Item>
                <Dropdown.Item eventKey="comm-update">Comm updates details</Dropdown.Item>
                
                <Dropdown.Item
                  eventKey=""
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Send Login Details
                </Dropdown.Item>
                {/* Add more items as needed */}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        );
      },
    },
    {
      key: "username",
      text: "User",
      className: "user_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.username}
          </span>
        );
      },
    },
    {
      key: "parent_user",
      text: "Agent",
      className: "user_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.parent_user}
          </span>
        );
      },
    },

    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.name}
          </span>
        );
      },
    },
    {
      key: "lock",
      text: "Lock",
      className: "lock",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <Link to={''}>
              {record?.isMatchLock=="N"?  <img
              src="/assets/img/match_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              src="/assets/img/match_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>
         
            <Link to={''}>
              {record?.isFancyLock=="N"?  <img
              src="/assets/img/fancy_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              src="/assets/img/fancy_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

            <Link to={''}>
              {record?.isCasinoLock=="N"?  <img
              src="/assets/img/casino_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              src="/assets/img/casino_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

            <Link to={''}>
              {record?.isBMLock=="N"?  <img
              src="/assets/img/bm_unlock.png"
              alt="Description of image"
              className="img-fluid leager-img"
              />: <img
              src="/assets/img/bm_lock.png"
              alt="Description of image"
              className="img-fluid leager-img"
            />}
            
            </Link>

          </span>
        );
      },
    },
    {
      key: "mobile_no",
      text: "Mobile No.",
      className: "mobile_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.mobile_no}
          </span>
        );
      },
    },

    {
      key: "unc_pass",
      text: "Password",
      className: "password",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.unc_pass}
          </span>
        );
      },
    },
    {
      key: "current",
      text: "Current",
      className: "current",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.current_limit ? record.current_limit : 0}
          </span>
        );
      },
    },
    {
      key: "engaged",
      text: "Engaged",
      className: "engaged",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {0}
          </span>
        );
      },
    },
    {
      key: "session_commission_percentage",
      text: "Sess.",
      className: "sess",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.session_commission_percentage
              ? record.session_commission_percentage
              : "0"}
            %
          </span>
        );
      },
    },
    {
      key: "mat",
      text: "Mat.",
      className: "mat",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span
            style={{
              textDecoration: record.is_active === "N" ? "line-through" : "",
            }}
          >
            {record.match_commission_percentage
              ? record.match_commission_percentage
              : "0"}
            %
          </span>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const getAgent = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-agent", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setAgentList(resp.data);
      }
    });
  };

  const getClient = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-clients", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        if (Object.keys(params).length > 0) {
          const decodedUsername = atob(params.id);
          setSelectedAgent(decodedUsername);
          getCurrentUserLimit(decodedUsername);
          getAllClientWalletLimit(decodedUsername);
          setIsParamsTrue(true);
          const filteredRecords = resp.data.filter(
            (record) => record.parent_user === decodedUsername
          );
          setClientRecords(filteredRecords);
        } else {
          setClientRecords(resp.data);
        }
      }
    });
  };

  const getAllClientWalletLimit = (username) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const selectedUserData = {
      clientUserName: username,
    };
    axios
      .post(
        baseUrl + "/api/get-all-client-wallet-limit",
        selectedUserData,
        config
      )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const filteredRecords = resp.data.filter(
            (record) => record.parent_user === username
          );
          setChildWalletLimit(filteredRecords);
        }
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/admin");
    } else {
      generateUserName();
      getAgent();
      getClient();
    }
  }, []);

  const generateUserName = () => {
    axios.get(baseUrl + "/api/generate-username", {}).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    }
    if (name === "mobile") {
      setMobile(value);
    }
    if (name === "password") {
      setPassword(value);
    }
    if (name === "client_limit") {
      setClient_limit(value);
    }
    if (name === "match_commission") {
      setMatch_commission(value);
    }
    if (name === "session_commission") {
      setSession_commission(value);
    }
    if (name === "agent") {
      getCurrentUserLimit(value);
      setSelectedAgent(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name &&
      !password &&
      !mobile &&
      !match_commission &&
      !session_commission &&
      !selectedAgent &&
      !client_limit
    ) {
      setNameErro("This filed is require");
      setMobileErr("This filed is require");
      setPasswordErr("This filed is require");
      setMatch_commissionErr("This filed is require");
      setSession_commissionErr("This filed is require");
      setSelectedAgentErr("This filed is require");
      setClientLimitError("This filed is require");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const agentData = {
      username: userName,
      parent_user: selectedAgent,
      parent_user_limit: age_limit,
      name: name,
      password: password,
      mobile: mobile,
      user_limit: client_limit,
      match_commission: match_commission,
      session_commission: session_commission,
    };

    axios.post(baseUrl + "/api/add-client", agentData, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        toast.success(resp.message);
        setUserName("");
        setName("");
        setPassword("");
        setMobile("");
        setMatch_commission("");
        setSession_commission("");
        setSelectedAgent("");
        setClient_limit("");
        setIsPanActive("tab-pane fade");
        getClient();
      } else {
        toast.error(resp.message);
      }
    });
  };

  const handleFormChange = (e, actionType) => {
    e.preventDefault();
    setIsEditPanActive("tab-pane fade");
    if (actionType === "create") {
      // Handle create action
      console.log("Create action");
      setIsPanActive("tab-pane fade active show");
      setIsUpdatePanActive("tab-pane fade");
      // Add any other logic specific to the create action
    } else if (actionType === "close") {
      // Handle close action
      console.log("Close action");
      setIsPanActive("tab-pane fade");
      setNameErro("");
      setMobileErr("");
      setPasswordErr("");
      setMatch_commissionErr("");
      setSession_commissionErr("");
      // Add any other logic specific to the close action
    }
  };

  const handleFormChangeLimit = (e, actionType) => {
    e.preventDefault();
    setIsEditPanActive("tab-pane fade");
    if (actionType === "create") {
      // Handle create action
      console.log("Create action");
      setIsUpdatePanActive("tab-pane fade active show");
      setIsPanActive("tab-pane fade");
      // Add any other logic specific to the create action
    } else if (actionType === "close") {
      // Handle close action
      console.log("Close action");
      setSelectedAgentUser("all");
      setIsUpdatePanActive("tab-pane fade");
      setNameErro("");
      setMobileErr("");
      setPasswordErr("");
      setMatch_commissionErr("");
      setSession_commissionErr("");
      // Add any other logic specific to the close action
    }
  };

  const handleChangeLimit = (event, item) => {
    const { name, value } = event.target;

    // Update the superAdminLimits array with the new current_limit value
    const updatedLimits = childWalletLimit.map((limitItem) =>
      limitItem === item ? { ...limitItem, current_limit: value } : limitItem
    );

    // Log the updatedLimits array to check if it's being modified correctly
    console.log(updatedLimits);

    // Update the state with the modified superAdminLimits array
    setChildWalletLimit(updatedLimits);

    // Update the formData state
    setFormData({
      ...formData,
      total_limit: item.total_limit,
      current_limit: value,
    });
  };

  const updateLimit = (e, data) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const updateData = {
      parent_username: selectedAgent,
      clientId: data.id, // Assuming you have a unique identifier for each client
      total_limit: formData.total_limit,
      current_limit: formData.current_limit,
    };
    axios
      .post(baseUrl + "/api/update-limit-child", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getCurrentUserLimit(resp.data);
          getAllClientWalletLimit(resp.data);
        } else {
          toast.error(resp.message);
        }
      });
  };

  const handleChangeSelect = (event) => {
    setSelectedAgentUser(event.target.value);
  };

  const filteredMasterUser =
    selectedAgentUser === "all"
      ? childWalletLimit
      : childWalletLimit.filter((item) => item.id == selectedAgentUser);

  const fetchReportById = (e, id) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
    };
    axios
      .post(baseUrl + "/api/fetch-report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setShowModal(true);
          setClientReports(resp.data);
        }
      });
  };

  const handleCloseModal = () => setShowModal(false);

  const handleDropdownSelect = (event, record) => {
    setIsPanActive("tab-pane fade");
    setIsUpdatePanActive("tab-pane fade");
    setIsEditPanActive("tab-pane fade");

    if(event=="match-lock"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isMatchLock,
        field:"isMatchLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getClient();
          }
        });
        

    }


    if(event=="match-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isMatch,
        field:"isMatch"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getClient();
          }
        });
        

    }

    if(event=="fancy-lock"){
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancyLock,
        field:"isFancyLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            getClient();
          }
        });
        
    }

    if(event=="fancy-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancy,
        field:"isFancy"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getClient();
          }
        });
        

    }

    if(event=="casino-lock"){
    
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isCasinoLock,
        field:"isCasinoLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getClient();
          }
        });
    }

    if(event=="casino-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancy,
        field:"isCasino"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
             getClient();
          }
        });
        

    }

    if(event=="bm-lock"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isBMLock,
        field:"isBMLock"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
            getClient();
          }
        });
      
    }

    if(event=="bm-enable"){
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.isFancy,
        field:"isBM"
      };
      axios
        .post(baseUrl + "/api/lock-unlock-setting", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(resp.message);
             getClient();
          }
        });
        

    }


    if (event === "edit") {
      fetchChildInfo(record.id);
    }
    if (event === "deactivate") {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        user_id: record.id,
        current_status: record.is_active,
      };
      axios
        .post(baseUrl + "/api/deactivate-child", data, config)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            getClient();
          }
        });
    }
    if (event === "limit-updates") {
      fetchReportByIdEvent(record.id);
    }
    if(event==='share-details'){
      fetchShareCommissionReport(record.id,"1");

    }
    if(event==='comm-update'){
      fetchShareCommissionReport(record.id,"2");
    }
  };

  const fetchChildInfo = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      userId: id,
    };
    axios.post(baseUrl + "/api/fetch-child-info", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setChildId(resp.data.id);
        setIsEditPanActive("tab-pane fade active show");
        setIsPanActive("tab-pane fade");
        setIsUpdatePanActive("tab-pane fade");
        setSelectedAgent(resp.data.parent_user);
        setUserName(resp.data.username);
        setMobile(resp.data.mobile_no);
        setClient_limit(resp.data.current_limit);
        setName(resp.data.name);
        setMatch_commission(
          resp.data.match_commission_percentage
            ? resp.data.match_commission_percentage
            : "0"
        );
        setSession_commission(
          resp.data.session_commission_percentage
            ? resp.data.session_commission_percentage
            : "0"
        );
      }
    });
  };
  const fetchReportByIdEvent = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
    };
    axios
      .post(baseUrl + "/api/fetch-report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setShowModal(true);
          setClientReports(resp.data);
        }
      });
  };

  const fetchShareCommissionReport = (id,type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      clientId: id,
      type:type
    };
    axios
      .post(baseUrl + "/api/fetch-share_com_report-by-id", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          //type =>1 sharing 2 commision 
          if(type=="1"){
            setShowShareModel(true);
            setShareList(resp.data);
          }else{
            setShowCommissionModel(true);
            setCommissionList(resp.data);
         
          }
         
        }else{
          toast.error('data not found')
        }
      });
  };


  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const agentData = {
      child_id: childId,
      name: name,
      password: password,
      mobile: mobile,
      user_limit: client_limit,
      match_commission: match_commission,
      session_commission: session_commission,
    };

    axios.post(baseUrl + "/api/update-user", agentData, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        setUserName("");
        setName("");
        setPassword("");
        setMobile("");
        setMatch_commission("");
        setSession_commission("");
        setIsEditPanActive("tab-pane fade");
        getClient();
        updateLimitOnEdit(childId, client_limit);
      } else {
        toast.error(resp.message);
      }
    });
  };

  const updateLimitOnEdit = (id, limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const updateData = {
      parent_username: selectedAgent,
      clientId: id, // Assuming you have a unique identifier for each client
      current_limit: limit,
    };

    axios
      .post(baseUrl + "/api/update-limit-child", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          // toast.success(resp.message);
          getCurrentUserLimit(selectedAgent);
          getAllClientWalletLimit(selectedAgent);
        } else {
          toast.error(resp.message);
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Clients</h1>
              <div className="client-tab">
                <nav>
                  <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    {isParamsTrue === true && (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleFormChangeLimit(e, "create")}
                      >
                        Update Limit
                      </button>
                    )}
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleFormChange(e, "create")}
                    >
                      Create
                    </button>
                  </div>
                </nav>
              </div>
            </div>
            <div className="client-form mb-5">
              <div
                className="tab-content p-3 border bg-light"
                id="nav-tabContent"
              >
                <div
                  className={isPanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            onChange={handleChange}
                            name="username"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          {isParamsTrue === true ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              name="username"
                              value={selectedAgent}
                              disabled
                            />
                          ) : (
                            <select
                              className="form-control"
                              onChange={handleChange}
                              name="agent"
                            >
                              <option>- Agent -</option>
                              {agentList.map((item) => {
                                return (
                                  <option value={item.username}>
                                    {item.username}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          {selectedAgentErr && (
                            <span className="" style={{ color: "red" }}>
                              {selectedAgentErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Agent Limit"
                            onChange={handleChange}
                            name="age_limit"
                            value={age_limit}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            name="name"
                            value={name}
                          />
                          {nameErro && (
                            <span className="" style={{ color: "red" }}>
                              {nameErro}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            name="password"
                            value={password}
                          />
                          {passwordErr && (
                            <span className="" style={{ color: "red" }}>
                              {passwordErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile"
                            onChange={handleChange}
                            name="mobile"
                            value={mobile}
                          />
                          {mobileErr && (
                            <span className="" style={{ color: "red" }}>
                              {mobileErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Client Limit"
                            onChange={handleChange}
                            name="client_limit"
                            value={client_limit}
                          />
                          {clientLimitError && (
                            <span className="" style={{ color: "red" }}>
                              {clientLimitError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Match Commission (%)"
                            onChange={handleChange}
                            name="match_commission"
                            value={match_commission}
                          />
                          {match_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {match_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Session Commission (%)"
                            onChange={handleChange}
                            name="session_commission"
                            value={session_commission}
                          />
                          {session_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {session_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={(e) => handleFormChange(e, "close")}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success bg-dark p-3"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={isUpdatePanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <select
                          className="form-select"
                          onChange={handleChangeSelect}
                          value={selectedAgentUser}
                        >
                          <option value={"all"}>All Client User</option>
                          {clientRecords.map((item) => {
                            return (
                              <option value={item.id}>{item.username}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12 mb-2">
                        <h2 className="text-center">
                          ({avaliableBal.toLocaleString()})
                        </h2>
                      </div>
                    </div>
                    {filteredMasterUser.map((item) => {
                      return (
                        <div className="row">
                          <div className="col-md-2 col-12">
                            <div className="form-group">
                              <h4>
                                {item.username} ({item.name})
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-2 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Total Limit"
                                name="total_limit"
                                disabled
                                value={item.total_limit ? item.total_limit : ""}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Current Limit"
                                name="current_limit"
                                onChange={(e) => handleChangeLimit(e, item)}
                                value={
                                  item.current_limit ? item.current_limit : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Engaged Limit"
                                name="additional_input"
                                value={0}
                                disabled
                                // Add any necessary event handlers or values for this input
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "10px" }}
                                onClick={(e) => updateLimit(e, item)}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => fetchReportById(e, item.id)}
                              >
                                REPORT
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-12">
                      <div className="form-button d-flex justify-content-end">
                        <button
                          className="btn btn-primary me-3"
                          onClick={(e) => handleFormChangeLimit(e, "close")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={isEditPanActive}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form onSubmit={handleSubmitEdit}>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            name="username"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Admin"
                            name="master_user"
                            value={selectedAgent}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Admin Limit"
                            name="master_user_limit"
                            value={age_limit}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            name="name"
                            value={name}
                          />
                          {nameErro && (
                            <span className="" style={{ color: "red" }}>
                              {nameErro}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            name="password"
                            value={password}
                          />
                          {passwordErr && (
                            <span className="" style={{ color: "red" }}>
                              {passwordErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile"
                            onChange={handleChange}
                            name="mobile"
                            value={mobile}
                          />
                          {mobileErr && (
                            <span className="" style={{ color: "red" }}>
                              {mobileErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Client User Limit"
                            onChange={handleChange}
                            name="client_limit"
                            value={client_limit}
                          />
                          {clientLimitError && (
                            <span className="" style={{ color: "red" }}>
                              {clientLimitError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Match Commission (%)"
                            onChange={handleChange}
                            name="match_commission"
                            value={match_commission}
                          />
                          {match_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {match_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Session Commission (%)"
                            onChange={handleChange}
                            name="session_commission"
                            value={session_commission}
                          />
                          {session_commissionErr && (
                            <span className="" style={{ color: "red" }}>
                              {session_commissionErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={(e) => handleFormChange(e, "close")}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success bg-dark p-3"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>LIMIT UPDATE REPORT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Date & Time</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientReports.map((item) => {
                        return (
                          <tr>
                            <td>{item.amount_type}</td>
                            <td>{item.amount}</td>
                            <td>{moment(item.date).format("lll")}</td>
                            <td>{`limit ${item.amount_type.toLowerCase()} by ${
                              item.name
                            } (${item.username})`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
        <Toaster />
      </div>
    </div>
  );
};

export default AdminClientMaster;
