import React, { Fragment, useEffect, useRef, useState } from "react";
import Sidebar from "../Common/Sidebar";
import AdminNav from "../Common/AdminNav";
import { useParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { baseUrl, socketUrl } from "../Common/BaseUrl";
import axios from "axios";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import ReactDatatable from "@mkikets/react-datatable";

const PuserInPlayRecords = () => {
  const { match_id } = useParams();
  const clientRef = useRef(null);
  const [matchName, setMatchName] = useState("");
  const [matchShortname, setMatchShortname] = useState("");
  const [startTime, setStartTime] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [isMatchOdds, setIsMatchOdds] = useState("");
  const [isSession, setIsSession] = useState("");
  const [isBookMaker, setIsBookMaker] = useState("");
  const [bookMakerData, setBookMakerData] = useState([]);
  const [matchOdds, setMatchOdds] = useState([]);
  const [matchOddsMin, setMatchOddsMin] = useState("");
  const [matchOddsMax, setMatchOddsMax] = useState("");
  const [sessionMin, setSessionMin] = useState("");
  const [sessionMax, setSessionMax] = useState("");
  const [makerMin, setMakerMin] = useState("");
  const [makerMax, setMakerMax] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [declareTeam, setDeclareTeam] = useState("");
  const [teamA, setTeamA] = useState("");
  const [tecmB, setTecmB] = useState("");

  useEffect(() => {
    if (!clientRef.current) {
      clientRef.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("token"),
        },
      });
      clientRef.current.emit("get_match_data_by_id", match_id);
      clientRef.current.emit("get_match_odds_by_id", match_id);
      clientRef.current.emit("get_match_inner_details", match_id);
      clientRef.current.emit("get_book_maker", match_id);
      handleSocketData(clientRef.current);
    }
    getMatchInformation();
    return () => {
      if (
        clientRef.current &&
        !window.location.pathname.includes("/in-play-game")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.disconnect();
      }
    };
  }, []);

  const getMatchInformation = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: match_id,
    };
    axios.post(baseUrl + "/api/match-info-by-id", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setMatchName(resp.data.match_name);
        const shortName = generateShortName(resp.data.match_name);
        setMatchShortname(shortName);
        setStartTime(resp.data.open_date);
        setIsMatchOdds(resp.data.is_matchodds);
        setIsBookMaker(resp.data.is_bookmaker);
        setIsSession(resp.data.is_session);
        setMatchOddsMin(resp.data.odds_min);
        setMatchOddsMax(resp.data.odds_max);
        setSessionMin(resp.data.session_min);
        setSessionMax(resp.data.session_max);
        setMakerMin(resp.data.maker_min);
        setMakerMax(resp.data.maker_max);
        splitAndAbbreviate(resp.data.match_name);
      }
    });
  };

  const splitAndAbbreviate = (fullName) => {
    // Assuming the full name is in the format "Team A v Team B"
    const [teamA, teamB] = fullName.split(" v ");

    // Abbreviate team names (e.g., first three letters)
    const abbreviatedTeamA = teamA.slice(0, 3).toUpperCase();
    const abbreviatedTeamB = teamB.slice(0, 3).toUpperCase();
    setTeamA(abbreviatedTeamA);
    setTecmB(abbreviatedTeamB);
    // return {
    //   teamA: abbreviatedTeamA,
    //   teamB: abbreviatedTeamB,
    // };
  };

  const generateShortName = (matchName) => {
    // Example logic to generate short names
    const teams = matchName.split(" v ");

    // Custom logic for short names
    const shortName = teams
      .map((team) => {
        // Remove descriptors like "Women" and split the name into words
        const words = team
          .replace(/(Women|Men)\s*/i, "")
          .trim()
          .split(" ");

        // Take the first two characters of the first word
        let shortTeamName = words[0].slice(0, 2).toUpperCase();

        // If there is a second word, take the first two characters from it
        if (words.length > 1) {
          shortTeamName += words[1].slice(0, 2).toUpperCase();
        } else {
          // If there is no second word, take the next two characters from the first word
          shortTeamName += words[0].slice(2, 4).toUpperCase();
        }

        return shortTeamName;
      })
      .join(" v ");

    return shortName;
  };

  const handleSocketData = (socket) => {
    socket.off("result_match_data_by_id");
    socket.on("result_match_data_by_id", (data) => {
      setSessionData(data);
      
      // updateSessionTable(data)
    });

    socket.off("result_match_odds_by_id");
    socket.on("result_match_odds_by_id", (data) => {
      console.log("oddsResult", data);
    });
    socket.off("result_match_inner_details");
    socket.on("result_match_inner_details", (data) => {
      console.log("someomosmosmsomd", data);
    });

    socket.off("result_book_maker");
    socket.on("result_book_maker", (data) => {
      console.log("result_book_maker", data);
      setBookMakerData(data);
    });
  };

  const convertToDecimal = (value) => {
    // Extract the decimal part and convert to a number
    const decimalPart = parseFloat(
      `0.${value.toString().split(".")[1] || "0"}`
    );
    return decimalPart.toFixed(2);
  };

  const boookMakerTable = () => {
    if (bookMakerData.length > 0) {
      return bookMakerData.map((item) => {
        return item.runners.map((runer) => {
          const backOdds = convertToDecimal(runer.back[0].price1);
          const layOdds = convertToDecimal(runer.lay[0].price1);
          return (
            <tr>
              <td>{runer.runnerName}</td>
              <td>{runer.status === "SUSPENDED" ? "SUSPENDED" : backOdds}</td>
              <td>{runer.status === "SUSPENDED" ? "SUSPENDED" : layOdds}</td>
            </tr>
          );
        });
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
  };

  const matchOddsMap = () => {
    if (matchOdds.length > 0) {
      const reversedSessionData = matchOdds.slice().reverse();
      return reversedSessionData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.RunnerName}</td>
            {item.LayPrice1 == 0 ? (
              <td>SUSPENDED</td>
            ) : (
              <td>
                {item.LayPrice1} <span>{item.LaySize1}</span>
              </td>
            )}
            {item.BackPrice1 == 0 ? (
              <td>SUSPENDED</td>
            ) : (
              <td>
                {item.BackPrice1} <span>{item.BackPrice1}</span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
  };

  const sessioNMap = () => {
    if (sessionData.length > 0) {
      const reversedSessionData = sessionData.slice().reverse();
      return reversedSessionData.map((item, index) => {
        console.log("itesm", item);
        return (
          <tr key={index}>
            <td>{item.RunnerName}</td>
            {item.LayPrice1 == 0 ? (
              <td className="text-center">SUSPENDED</td>
            ) : (
              <td className="text-center">
                {item.LayPrice1}{" "}
                <span className="small-box">{item.LaySize1}</span>
              </td>
            )}
            {item.BackPrice1 == 0 ? (
              <td className="text-center">SUSPENDED</td>
            ) : (
              <td className="text-center">
                {item.BackPrice1}{" "}
                <span className="small-box">{item.BackSize1}</span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
  };

  const handleChangeStatus = (e, match_type) => {
    const { value } = e.target;
    if (match_type === "book_maker") {
      setIsBookMaker(value);
    } else if (match_type === "match_session") {
      setIsSession(value);
    } else if (match_type === "match_odds") {
      setIsMatchOdds(value);
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      status: value,
      match_type: match_type,
      match_id: match_id,
    };
    axios
      .post(baseUrl + "/api/update-match-status-by-type", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getMatchInformation();
        }
      });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleToggleSelection = (row) => {
    const isSelected = selectedRows.some((r) => r.match_id === row.match_id);

    if (isSelected) {
      // If row is already selected, remove it
      setSelectedRows(selectedRows.filter((r) => r.match_id !== row.match_id));
    } else {
      // If row is not selected, add it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAll = () => {
    const allChecked = !selectAllChecked;
    setSelectAllChecked(allChecked);
    const allRows = sessionData; // Replace with your actual data array
    const selectedRows = allChecked ? allRows : [];
    if (selectedRows.length > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    setSelectedRows(selectedRows);
  };

  const columns = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          className="select-checkbox"
          checked={selectedRows.some((r) => r.match_id === row.match_id)}
          onChange={() => handleToggleSelection(row)}
        />
      ),
      key: "checkbox",
      text: (
        <div>
          <input
            type="checkbox"
            className="select-all-checkbox"
            checked={selectAllChecked}
            onChange={() => handleSelectAll()}
          />
          S.No
        </div>
      ),
      className: "checkbox",
      sortable: false,
    },
    {
      key: "RunnerName",
      text: "Name",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "match_id",
      text: "Max",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <input
              className="form-control"
              value={record.max}
              onChange={(e) => handleMaxInputChange(e, record.match_id)}
            />
          </Fragment>
        );
      },
    },
    {
      key: "open_date",
      text: "Min",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <input
              className="form-control"
              value={record.min}
              onChange={(e) => handleMinInputChange(e, record.match_id)}
            />
          </Fragment>
        );
      },
    },
    {
      key: "series_id",
      text: "Update",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button className="btn btn-primary">Update</button>
          </Fragment>
        );
      },
    },

    {
      key: "series_id",
      text: "Action",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <select
              className="form-select"
              onClick={(e) => handleStatusInputChange(e, record.match_id)}
            >
              <option value={"Active"}>Active</option>
              <option value={"InActive"}>InActive</option>
            </select>
          </Fragment>
        );
      },
    },
  ];

  const handleMaxInputChange = (e, matchId) => {
    const updatedTableData = sessionData.map((item) => {
      if (item.match_id === matchId) {
        return {
          ...item,
          max: e.target.value,
        };
      }
      return item;
    });

    setSessionData(updatedTableData);
    // Handle the change for max input
  };

  const handleMinInputChange = (e, matchId) => {
    // Handle the change for min input
    const updatedTableData = sessionData.map((item) => {
      if (item.match_id === matchId) {
        return {
          ...item,
          min: e.target.value,
        };
      }
      return item;
    });

    setSessionData(updatedTableData);
  };

  const handleStatusInputChange = (e, matchId) => {
    const updatedTableData = sessionData.map((item) => {
      if (item.match_id === matchId) {
        return {
          ...item,
          status: e.target.value,
        };
      }
      return item;
    });

    setSessionData(updatedTableData);
    // Handle the change for status input
  };

  const updateBookMaker = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      min: makerMin,
      max: makerMax,
      matchId: match_id,
    };
    axios
      .post(baseUrl + "/api/update-match-bookmaker", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getMatchInformation();
        }
      });
  };

  const updateMatchOdds = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      min: matchOddsMin,
      max: matchOddsMax,
      matchId: match_id,
    };
    axios.post(baseUrl + "/api/update-match-odds", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        getMatchInformation();
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "makerMin") {
      setMakerMin(value);
    }
    if (name === "makerMax") {
      setMakerMax(value);
    }
    if (name === "matchOddsMin") {
      setMatchOddsMin(value);
    }
    if (name === "matchOddsMax") {
      setMatchOddsMax(value);
    }
    if (name === "winner-team") {
      setDeclareTeam(value);
    }
  };

  const declareMatch = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      team: declareMatch,
      matchId: match_id,
    };
    axios
      .post(baseUrl + "/api/declare-match-manually", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getMatchInformation();
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="match-detail">
          <div className="section-heading text-center">
            <h1>{matchName}</h1>
            <p>{matchShortname}</p>
            <div className="game-date">
              <p>({moment(startTime).format("lll")})</p>
            </div>
          </div>

          <div className="section-heading text-center">
            <div className="game-date">
              <h4>Book Makers</h4>
            </div>
          </div>
          <div className="super-agent">
            <div className="super-outer">
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <select
                    className="form-select"
                    onChange={(e) => handleChangeStatus(e, "book_maker")}
                    value={isBookMaker}
                  >
                    <option value={"Y"}>On</option>
                    <option value={"N"}>Off</option>
                  </select>
                </div>
                <div className="col-md-6 col-12">
                  <div className="super-inner super-left">
                    <div className="table-responsive">
                      <table className="w-100 mb-2">
                        <thead>
                          <tr>
                            <th>Team</th>
                            <th>Back</th>
                            <th>Lay</th>
                          </tr>
                        </thead>
                        <tbody>{boookMakerTable()}</tbody>
                      </table>
                      <table>
                        <thead>
                          <tr>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className="form-control"
                                value={makerMin}
                                onChange={handleChange}
                                name="makerMin"
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                value={makerMax}
                                onChange={handleChange}
                                name="makerMax"
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={updateBookMaker}
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Odds</h4>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <select
                    className="form-select"
                    onChange={(e) => handleChangeStatus(e, "match_odds")}
                    value={isMatchOdds}
                  >
                    <option value={"Y"}>On</option>
                    <option value={"N"}>Off</option>
                  </select>
                </div>
                <div className="col-md-6 col-12">
                  <div className="super-inner super-left">
                    <div className="table-responsive">
                      <table className="w-100 mb-2">
                        <thead>
                          <tr>
                            <th>Team</th>
                            <th>Back</th>
                            <th>Lay</th>
                          </tr>
                        </thead>
                        <tbody>{matchOddsMap()}</tbody>
                      </table>
                      <table>
                        <thead>
                          <tr>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className="form-control"
                                value={matchOddsMin}
                                onChange={handleChange}
                                name="matchOddsMin"
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                value={matchOddsMax}
                                onChange={handleChange}
                                name="matchOddsMax"
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={updateMatchOdds}
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Manual Declare</h4>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 col-12">
                  <h4>Result :-</h4>
                </div>
                <div className="col-md-3 col-12">
                  <input
                    className="form-control"
                    disabled
                    value={declareTeam}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <select
                    className="form-select"
                    value={declareTeam}
                    onChange={handleChange}
                    name="winner-team"
                  >
                    <option value={""}>Select Team</option>
                    <option value={teamA}>{teamA}</option>
                    <option value={tecmB}>{tecmB}</option>
                    <option value={"draw"}>Draw</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px" }}
                  >
                    Cancle
                  </button>
                  <button className="btn btn-primary" onClick={declareMatch}>
                    Declare
                  </button>
                </div>
              </div>
              <div className="section-heading text-center">
                <div className="game-date">
                  <h4>Match Sessions</h4>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <select
                    className="form-select"
                    onChange={(e) => handleChangeStatus(e, "match_session")}
                    value={isSession}
                  >
                    <option value={"Y"}>On</option>
                    <option value={"N"}>Off</option>
                  </select>
                </div>
                <div className="col-md-6 col-12">
                  <div className="super-inner super-left">
                    <div className="table-responsive">
                      {/* <table className="w-100">
                        <thead>
                          <tr>
                            <th>Session</th>
                            <th>Max</th>
                            <th>Min</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>{sessioNMap()}</tbody>
                      </table> */}
                      {console.log("sessionData", sessionData)}
                      <ReactDatatable
                        config={config}
                        records={sessionData}
                        columns={columns}
                        //   onPageChange={this.pageChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default PuserInPlayRecords;
