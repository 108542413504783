import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import AdminNav from "../Common/AdminNav";

const Announcement = () => {
  const [clientRecords, setClientRecords] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [eventErr, setEventErr] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState("All");
  
  const [msg, setMsg] = useState("");
  const columns = [
    {
      key: "event",
      text: "Event",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>{record.event === "all" ? "All System" : record.event}</span>
        );
      },
    },
    {
      key: "content",
      text: "Active Msg",
      className: "user_name",
      align: "left",
      sortable: true,
    },
    {
      key: "",
      text: "Action",
      className: "mat",
      align: "left",
      sortable: true,
      cell: (record) => {
        const text = record.status === "inactive" ? "Activate" : "Deactivate";
        return (
          <span>
            <button
              className="btn btn-primary mr-2"
              onClick={() => handleDeactivate(record.id)}
            >
              {text}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleDelete(record.id)}
            >
              Delete
            </button>
          </span>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      event: selectedEvent,
      msg: msg,
      recipient :selectedRecipient,
    };

    axios.post(baseUrl + "/api/add-announcement", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        fetchAllEvent();
        setMsg("");
        setSelectedEvent("");
        setSelectedRecipient("")
      } else {
        toast.error(resp.message);
      }
    });
  };

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/api/delete-announcemtnt", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          fetchAllEvent();
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "event") {
      setSelectedEvent(value);
    }
    if (name === "msg") {
      setMsg(value);
    }
    if(name==="recipient"){
      setSelectedRecipient(value);
    }
  };

  useEffect(() => {
    fetchAllEvent();
  }, []);

  const fetchAllEvent = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/fetch-announcemtnt", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }else{
        setClientRecords([]);
      }
    });
  };

  const handleDeactivate = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/api/change-status-accouncment", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          fetchAllEvent();
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="client-form mb-5">
              <div className="border bg-light p-3 mt-3">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 col-12">
                      <div className="form-group">
                        <label htmlFor="event">Choose recipient:</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="recipient"
                        >
                          <option value={"All"}>All</option>
                          <option value={"Client"}>Client </option>
                          <option value={"Other"}>Other</option>
                        
                      
                        </select>
                        {eventErr && (
                          <span className="" style={{ color: "red" }}>
                            {eventErr}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3 col-12">
                      <div className="form-group">
                        <label htmlFor="event">Select Event:</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="event"
                        >
                          <option>- Select Event -</option>
                          <option value={"all"}>All Pages</option>
                          {eventList.map((item) => {
                            return (
                              <option value={item.username}>
                                {item.username}
                              </option>
                            );
                          })}
                        </select>
                        {eventErr && (
                          <span className="" style={{ color: "red" }}>
                            {eventErr}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4 col-12">
                      <div className="form-group">
                        <label htmlFor="msg">Message (2000 char.):</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="msg"
                          value={msg}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-button d-flex">
                        <button
                          type="submit"
                          className="btn btn-success bg-dark p-3"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Announcement;
