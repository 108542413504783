import React, { useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import ReactDatatable from "@mkikets/react-datatable";

const MasterLeadger = () => {
  const [clientRecords, setClientRecords] = useState([]);
  const columns = [
    {
      key: "date",
      text: "DATE",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "credit",
      text: "CREDIT",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "debit",
      text: "DEBIT",
      className: "debit",
      align: "left",
      sortable: true,
    },
    {
      key: "balance",
      text: "BALANCE",
      className: "balance",
      align: "left",
      sortable: true,
    },

    {
      key: "winner",
      text: "WINNER / Remark",
      className: "winner",
      align: "left",
      sortable: true,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Master Ledger</h1>
            </div>
            <div className="client-form mb-5">
              <div className="row mt-3">
                <div className="col-12">
                  <select className="form-control">
                    <option>Select Mastre</option>
                  </select>
                </div>
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterLeadger;
