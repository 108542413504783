import React, { useState } from "react";
import Sidebar from "../Common/Sidebar";
import AdminNav from "../Common/AdminNav";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

const AdminLeadger = () => {
  const [show, setShow] = useState(false);
  const [sessionType, setSessionType] = useState("");
  const handleClose = () => {
    setShow(false);
  };
  const handleChnage = () => {};

  const handleSettlement = (type) => {
    setShow(true);
    setSessionType(type);
  };

  const changeType = () => {
    if (sessionType === "credit") {
      setSessionType("debit");
    } else {
      setSessionType("credit");
    }
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3 mb-3">
              <h1>All Super Admin Ledger</h1>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="super-inner super-left">
                  <h6 className="text-center">
                    PAYMENT RECEIVING FROM ( LENA HAI )
                  </h6>
                  <div className="table-responsive">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>AGENT</th>
                          <th>AMOUNT</th>
                          <th>SETTLED</th>
                          <th></th>
                          <th>FINAL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>LENA HAI</td>
                          <td className="take">0</td>
                          <td className="take">0</td>
                          <td className="take"></td>
                          <td className="take">0</td>
                          <td className="take">
                            <button
                              className="btn btn-primary"
                              onClick={() => handleSettlement("credit")}
                            >
                              <img
                                src="/assets/img/money-img.png"
                                alt=""
                                className="img-fluid"
                                style={{ width: "20px" }}
                              />{" "}
                              Settlement
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="super-inner super-left">
                  <h6 className="text-center">PAYMENT PAID TO ( DENA HAI )</h6>
                  <div className="table-responsive">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>AGENT</th>
                          <th>AMOUNT</th>
                          <th>SETTLED</th>
                          <th></th>
                          <th>FINAL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>DENA HAI</td>
                          <td className="take">0</td>
                          <td className="take">0</td>
                          <td className="take"></td>
                          <td className="take">0</td>
                          <td className="take">
                            <button
                              className="btn btn-primary"
                              onClick={() => handleSettlement("debit")}
                            >
                              <img
                                src="/assets/img/money-img.png"
                                alt=""
                                className="img-fluid"
                                style={{ width: "20px" }}
                              />{" "}
                              Settlement
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Settlement Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Settlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <lable>Agent:</lable>
                <input
                  type="text"
                  disabled
                  value={""}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <lable>
                  Amount:
                  <span
                    onClick={changeType}
                    className={
                      sessionType === "credit"
                        ? "badge-success"
                        : "badge-danger"
                    }
                  >
                    {sessionType === "credit"
                      ? "Credit (लेना)"
                      : "Debit (देना)"}
                  </span>
                </lable>
                <input
                  type="text"
                  disabled
                  value={""}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <lable>
                  Settled Amount:
                  <span
                    onClick={changeType}
                    className={
                      sessionType === "credit"
                        ? "badge-success"
                        : "badge-danger"
                    }
                  >
                    {sessionType === "credit"
                      ? "Credit (लेना)"
                      : "Debit (देना)"}
                  </span>
                </lable>
                <input type="text" value={""} className="form-control" />
              </div>
              <div className="col-md-6">
                <lable>Remark:</lable>
                <input type="text" value={""} className="form-control" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onChange={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={"placeBet"}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Settlement Modal */}
    </div>
  );
};

export default AdminLeadger;
