import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import moment from "moment";
import MasterNav from "../Common/MasterNav";

const MasterDashboard = () => {
  const [matchData, setMatchData] = useState([]);
  const [avaliableBal, setAvaliableBal] = useState("0");
  const [superAgentCount, setSuperAgentCount] = useState(0);
  const [agentCount, setAgentCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    }
    getAllMatches();
    fetchCount();
    getCurrentUserLimit();
  }, []);

  const getCurrentUserLimit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        if (
          resp.data.share_percentage == 0 ||
          resp.data.share_percentage == null
        ) {
          setAvaliableBal("0.00");
        } else {
          setAvaliableBal(resp.data.share_percentage);
        }
      }
    });
  };

  const fetchCount = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(baseUrl + "/api/fetch-master-master-count", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setSuperAgentCount(resp.data[0].userCount);
        }
      });

    axios
      .post(baseUrl + "/api/fetch-agent-sa-count", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setAgentCount(resp.data);
        }
      });

    axios
      .post(baseUrl + "/api/fetch-client-sa-count", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setClientCount(resp.data);
        }
      });
  };

  const avaliableMatchListLive = () => {
    const currentTime = moment();
    const startOfToday = currentTime.clone().startOf("day");

    const matchesToday = matchData.filter((item) => {
      const matchStartTime = moment(item.open_date);

      // Check if the match is today and before the current time
      return (
        matchStartTime.isSameOrBefore(currentTime) &&
        matchStartTime.isSame(startOfToday, "day") &&
        item.decleared === "NO"
      );
    });
    return matchesToday.map((item) => (
      <li className="position-relative" key={item.match_id}>
        <Link to={`/master/client/bets/${item.match_id}`}>
          <div className="img-outer position-relative">
            <img
              src="/assets/img/cricket-img1.jpg"
              alt=""
              className="img-fluid"
            />
            <div className="match-condition position-absolute">
              <p>Live</p>
            </div>
          </div>
          <div className="match-date">
            <p>
              {item.match_name}{" "}
              <small className="d-block">
                {moment(item.open_date).format("lll")}
              </small>
            </p>
          </div>
        </Link>
      </li>
    ));
  };

  const generateShortName = (matchName) => {
    // Example logic to generate short names
    const teams = matchName.split(" v ");

    // Custom logic for short names
    const shortName = teams
      .map((team) => {
        // Remove descriptors like "Women" and split the name into words
        const words = team
          .replace(/(Women|Men)\s*/i, "")
          .trim()
          .split(" ");

        // Take the first two characters of the first word
        let shortTeamName = words[0].slice(0, 2).toUpperCase();

        // If there is a second word, take the first two characters from it
        if (words.length > 1) {
          shortTeamName += words[1].slice(0, 2).toUpperCase();
        } else {
          // If there is no second word, take the next two characters from the first word
          shortTeamName += words[0].slice(2, 4).toUpperCase();
        }

        return shortTeamName;
      })
      .join(" v ");

    return shortName;
  };

  const avaliableMatchList = () => {
    const currentTime = moment();
    const thresholdTime = currentTime.clone().add(1, "hour");
    const liveMatchIds = avaliableMatchListLive().map(
      (liveMatch) => liveMatch.match_id
    );
    return matchData
      .filter((item) => {
        const matchStartTime = moment(item.open_date);
        return (
          matchStartTime.isAfter(thresholdTime) &&
          !liveMatchIds.includes(item.match_id) // Exclude matches that are live
        );
      })
      .map((item) => {
        const shortName = generateShortName(item.match_name);
        return (
          <div className="col-md-4 col-sm-4 col-12 more-space">
            <div className="game-outer">
              <div className="img-outer position-relative">
                <img
                  src="/assets/img/cricket-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="game-type position-absolute">
                  <p>
                    <b>{item.sportName}</b>
                  </p>
                </div>
              </div>
              <div className="content-outer">
                <h4>{shortName}</h4>
                <div className="game-date d-flex justify-content-between align-items-center">
                  <div className="game-team">
                    <p>{item.match_name}</p>
                  </div>
                  <div className="game-date">
                    <p>{moment(item.open_date).format("lll")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };

  const getAllMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setMatchData(resp.data);
      }
    });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <MasterNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Dashboard</h1>
          </div>
          <div className="match-inner">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-12">
                <div className="match-left">
                  <ul className="nav flex-wrap">
                    <li className="position-relative">
                      <img
                        src="/assets/img/poker.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Dragon Tiger</p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></a>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Teen Patti One Day</p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></a>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Teen Patti 2020</p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></a>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/lucky-player.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Lucky 7</p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-7 col-sm-6 col-12">
                <div className="match-right">
                  <ul className="nav flex-wrap">
                  {avaliableMatchListLive()}
                    {/* <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li>
                    <li className="position-relative">
                      <div className="img-outer position-relative">
                        <img
                          src="/assets/img/cricket-img1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="match-condition position-absolute">
                          <p>Live</p>
                        </div>
                      </div>
                      <div className="match-date">
                        <p>
                          AUSTRALIA T10 v INDIA T10{" "}
                          <small className="d-block">Nov 30, 10:30 PM</small>
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="detail-main">
            <div className="row">
              <div className="col-md-3 col-sm-3 col-6">
                <Link to={"/master/sa"}>
                  <div className="detail-inner text-center">
                    <div className="img-outer">
                      <img
                        src="/assets/img/match-detail1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-outer">
                      <p>
                        <b>Master Details</b>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <Link to={"/master/client/sports"}>
                  <div className="detail-inner text-center">
                    <div className="img-outer">
                      <img
                        src="/assets/img/match-detail2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-outer">
                      <p>
                        <b>Sport's Betting</b>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <Link to={"/master/admin/ledger"}>
                  <div className="detail-inner text-center">
                    <div className="img-outer">
                      <img
                        src="/assets/img/match-detail3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-outer">
                      <p>
                        <b>Ledger</b>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <Link to={"/master/sa/final/report"}>
                  <div className="detail-inner text-center">
                    <div className="img-outer">
                      <img
                        src="/assets/img/match-detail4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-outer">
                      <p>
                        <b>All Agent Report</b>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="agent-detail">
            <div className="row mb-3">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="agent-outer">
                  <h5>My Account ({avaliableBal})</h5>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="agent-outer">
                  <h5>Super Agent ({superAgentCount})</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="agent-outer">
                  <h5>Agent ({agentCount})</h5>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="agent-outer">
                  <h5>Client ({clientCount})</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="game-detail">
            <div className="row">{avaliableMatchList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterDashboard;
