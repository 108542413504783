import React, { Fragment, useEffect, useRef, useState } from "react";
import Sidebar from "../Common/Sidebar";
import AdminNav from "../Common/AdminNav";
import toast, { Toaster } from "react-hot-toast";
import ReactDatatable from "@mkikets/react-datatable";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../Common/BaseUrl";

const UnDeclaredSessionBets = () => {
  const params = useParams();
  const [sessionData, setSessionData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [matchName, setMatchName] = useState("");
  const [selectSession, setSelectSession] = useState("all");
  const [selectBet, setSelectBet] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    getSessionBet();
  }, []);

  const getSessionBet = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(
        baseUrl + "/api/get-all-session-bet",
        { matchId: params.matchId },
        config
      )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let match_name = resp.data[0].match_name;
          let filteredData = resp.data.filter(filterSession);
          setSessionData(filteredData);
          setTempData(filteredData); 
          setMatchName(match_name);
        }
      });
  };

  const filterSession = (data) => {
    return data.declared != "YES";
  };

  const handleSelectAll = () => {
    const allChecked = !selectAllChecked;
    setSelectAllChecked(allChecked);
    const allRows = tempData; // Replace with your actual data array
    const selectedRows = allChecked ? allRows : [];

    console.log("selectedRows.length", selectedRows.length);
    if (selectedRows.length > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    setSelectedRows(selectedRows);
  };

  const handleToggleSelection = (row) => {
    const isSelected = selectedRows.some((r) => r.id === row.id);

    if (isSelected) {
      // If row is already selected, remove it
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
    } else {
      // If row is not selected, add it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const columns = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          className="select-checkbox"
          checked={selectedRows.some((r) => r.id === row.id)}
          onChange={() => handleToggleSelection(row)}
        />
      ),
      key: "checkbox",
      text: (
        <div>
          <input
            type="checkbox"
            className="select-all-checkbox"
            checked={selectAllChecked}
            onChange={() => handleSelectAll()}
          />
          Select
        </div>
      ),
      className: "checkbox",
      sortable: false,
    },
    {
      key: "username",
      text: "Client",
      className: "client",
      align: "left",
      sortable: true,
    },

    {
      key: "session",
      text: "Session",
      className: "session",
      align: "left",
      sortable: true,
    },

    {
      key: "amount",
      text: "Amount",
      className: "amount",
      align: "left",
      sortable: true,
    },
    {
      key: "session_rate",
      text: "Rate",
      className: "rate",
      align: "left",
      sortable: true,
    },
    {
      key: "run",
      text: "Run",
      className: "run",
      align: "left",
      sortable: true,
    },
    {
      key: "session_mode",
      text: "Mode",
      className: "mode",
      align: "left",
      sortable: true,
    },

    {
      key: "date",
      text: "Date & Time",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {moment(record.created_at).format("MMMM Do YYYY, h:mm:ss A")}
          </Fragment>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary"
              onClick={() => toggleDelete(record)}
            >
              {record.status == "1" ? "Revoke" : "Delete"}
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleSession = (e) => {
    setSelectSession(e.target.value);
  };

  const handleBets = (e) => {
    setSelectBet(e.target.value);
  };

  const handleChangeAll = (e) => {
    let { name, value } = e.target;

    if (name === "startDate") {
      setStartDate(value);
    }

    if (name === "endDate") {
      setEndDate(value);
    }
  };

  const submitSearch = (e) => {
    if (!startDate && endDate) {
      toast.dismiss();
      toast.error("Start date is required");
      return false;
    }

    if (endDate !== "" && startDate > endDate) {
      toast.dismiss();
      toast.error("Please enter valid start date");
      return false;
    }

    if (
      !startDate &&
      !endDate &&
      (selectSession == "all" || !selectSession) &&
      (selectBet == "all" || !selectBet)
    ) {
      // when nothing is selected
      setTempData(sessionData);
    } else {
      let html = sessionData;
      let dateTypeHtml = [];

      if (selectSession !== "all") {
        html = sessionData.filter((value) => {
          return value.session_id == selectSession;
        });
      }

      if (selectBet !== "all") {
        html = sessionData.filter((value) => {
          return value.status == selectBet;
        });
      }

      if (endDate) {
        for (let j = 0; j < html.length; j++) {
          const value = html[j];

          // Your date string from the database
          const dateString = value.created_at;

          // Parse the input date using moment
          const inputDate = moment(dateString);
          const startDateInputDate = moment(startDate);
          const endDateInputDate = moment(endDate);
          // Format the date in the desired output format (YYYY-MM-DD)
          const desiredFormat = "YYYY-MM-DD hh-mm-ss";
          const startDateFormat = "YYYY-MM-DD hh-mm-ss";
          const endDateFormat = "YYYY-MM-DD hh-mm-ss";

          const outputDateFormatted = inputDate.format(desiredFormat);
          const startDateFormatted = startDateInputDate.format(startDateFormat);
          const endDateFormatted = endDateInputDate.format(endDateFormat);

          if (
            outputDateFormatted >= startDateFormatted &&
            outputDateFormatted <= endDateFormatted
          ) {
            dateTypeHtml.push(value);
          }
        }

        setTempData(dateTypeHtml);
      } else if (startDate && !endDate) {
        for (let k = 0; k < html.length; k++) {
          const value = html[k];

          // Your date string from the database
          const dateString = value.created_at;

          // Parse the input date using moment
          const inputDate = moment(dateString);
          const startDateInputDate = moment(startDate);
          // Format the date in the desired output format (YYYY-MM-DD)
          const desiredFormat = "YYYY-MM-DD hh-mm-ss";
          const startDateFormat = "YYYY-MM-DD hh-mm-ss";

          const outputDateFormatted = inputDate.format(desiredFormat);

          const startDateFormatted = startDateInputDate.format(startDateFormat);

          if (outputDateFormatted >= startDateFormatted) {
            dateTypeHtml.push(value);
          }
        }
        setTempData(dateTypeHtml);
      } else {
        setTempData(html);
      }
    }
  };

  const sessionHtml = () => {
    let html = [];
    const uniqueSessionIds = new Set();
    const filteredSessions = tempData.filter((session) => {
      if (!uniqueSessionIds.has(session.session_id)) {
        uniqueSessionIds.add(session.session_id);
        return true;
      }
      return false;
    });

    if (filteredSessions?.length > 0) {
      filteredSessions.map((value) => {
        return html.push(
          <option value={value.session_id}>{value.session}</option>
        );
      });
    }

    return html;
  };

  const toggleDelete = (data) => {
    let status;

    if (data.status == "0") {
      // open for delete
      status = "1";
    } else {
      status = "0";
    }

    const obj = {
      id: data.id,
      matchId: params.matchId,
      status: status,
    };

    axios.post(baseUrl + "/api/delete-match-bet", obj).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.dismiss();
        toast.success(resp.message);
        getSessionBet();
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };

  const handleChageStatus = (e) => {
    const { value } = e.target;
    setSelectedStatus(value);

    if (!value) {
      toast.dismiss();
      toast.error("Please select status action");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    let data = {
      multipleMatch: selectedRows,
      status: value,
      isAllMatch: isAllSelected,
    };

    axios
      .post(baseUrl + "/api/change-bet-status-multiple", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getSessionBet();
          setSelectSession("all");
          setSelectBet("all");
          setStartDate("");
          setEndDate("");
          setSelectedRows([]);
          setSelectedStatus("");
          setSelectAllChecked(false);
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="match-detail">
          <div className="section-heading text-center">
            <h1>Un Declared Session Bets</h1>
          </div>

          <div className="section-heading text-center">
            <div className="game-date">
              <h4>{matchName}</h4>
            </div>
          </div>

          <div className="row mb-3">
            <div className="leader-main" style={{padding:20}}>
              <div className="leader-outer">
              <div className="row col-12">

              <div className="col-3">
                  <div className="form-group">
                    <label>Sessions</label>
                    <select
                      className="form-select"
                      onChange={(e) => handleSession(e)}
                      value={selectSession}
                    >
                      <option value={"all"}>Select Session</option>
                      {sessionHtml()}
                    </select>
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label> Bets </label>
                    <select
                      className="form-select"
                      onChange={(e) => handleBets(e)}
                      value={selectBet}
                    >
                      <option value={"all"}>All Bets</option>
                      <option value={"1"}>Deleted Bets</option>
                      <option value={"0"}>Fair Bets</option>
                    </select>
                  </div>
                </div>

              </div>
               
              </div>

             <div className="col-12 row">

             <div className="col-3">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    name="startDate"
                    type="datetime-local"
                    step="1"
                    placeholder=" Start Date"
                    className="form-control"
                    onChange={handleChangeAll}
                    value={startDate}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    name="endDate"
                    type="datetime-local"
                    step="1"
                    placeholder=" End Date"
                    className="form-control"
                    onChange={handleChangeAll}
                    value={endDate}
                  />
                </div>
              </div>

             </div>

              <div className="form-group" style={{marginTop:30,marginBottom:20, paddingLeft:10}}>
                <button className="btn btn-primary" onClick={submitSearch}>
                  Search
                </button>
              </div>

              {selectedRows.length > 0 && (
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Action</label>
                    <select
                      value={selectedStatus}
                      onChange={handleChageStatus}
                      className="form-select"
                    >
                      <option value="">Select Action</option>
                      <option value="1">Delete</option>
                      <option value="0">Revoke</option>
                      {/* Add more filter options as needed */}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className="row col-12" style={{marginTop:10}}>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    (window.location.href = `/admin/client/undeclared-bets/${params.matchId}`)
                  }
                >
                  Match Bets
                </button>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    (window.location.href = `/admin/client/undeclared-session-bets/${params.matchId}`)
                  }
                >
                  Session Bets
                </button>
              </div>
            </div>

            </div>
          </div>

          <div className="super-agent">
            <div className="super-outer">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="super-inner super-left">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={tempData ? tempData : sessionData}
                        columns={columns}
                        //   onPageChange={this.pageChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default UnDeclaredSessionBets;
