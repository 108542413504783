import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const Matches = () => {
  const [clientRecords, setClientRecords] = useState([]);

  useEffect(() => {
    getAllMatches();
  }, []);

  const getAllMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const columns = [
    {
      key: "match_name",
      text: "Name",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "open_date",
      text: "Start Date",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.open_date).format("lll")}</Fragment>;
      },
    },
    {
      key: "debit",
      text: "Status",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.is_approved === "Y" ? "Active" : "Inactive"}
          </Fragment>
        );
      },
    },
    {
      key: "balance",
      text: "Action.",
      className: "balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn"
              style={{ marginRight: "5px" }}
              onClick={() => changeStatus(record.match_id, "Y")}
            >
              Enable
            </button>
            <button className="btn" onClick={() => changeStatus(record.match_id, "N")}>
              Disable
            </button>
          </Fragment>
        );
      },
    },
  ];

  const changeStatus = (id, status) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const updateData = {
      status: status,
      id: id,
    };

    axios
      .post(baseUrl + "/api/change-status-match", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getAllMatches();
        } else {
          toast.error(resp.message);
        }
      });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Running Matches</h1>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Matches;
