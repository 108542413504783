import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../Common/BaseUrl";

const ClientNav = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userLimit, setUserLimit] = useState("0.00");
  const [announcements, setAnnouncements] = useState([]);
  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });
  useEffect(() => {
    // Assuming you have the JWT token stored in local storage
    const localRole = localStorage.getItem("s_role");
    const tc = localStorage.getItem("tc_verified");
    if (tc === "false") {
      navigate("/client/tc");
    }

    // Decode the JWT token to get user information, including the role

    // Extract the user role from the decoded token
    const role = localRole ? localRole : null;

    // Set the user role in state
    setUserRole(role);
  }, [userRole]);

  useEffect(() => {
    getUserInfo();
    getAllAccouncment();
  }, []);

  const getUserInfo = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data.username);
        setUserLimit(resp.data.current_limit && resp.data.current_limit);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("s_token") !== "null" &&
          localStorage.getItem("s_token") !== null
        ) {
          localStorage.clear();
          setTimeout(() => navigator("/"), 2000);
        }
      }
    });
  };

  const getAllAccouncment = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(baseUrl + "/api/fetch-announcemtnt-client", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setAnnouncements(resp.data);
        }
      });
  };

  const logout = () => {
    localStorage.clear();
    if (userRole === "CLIENT") {
      navigate("/");
    } else {
      navigate("/admin");
    }
  };

  return (
    <>
      <div className="top-bar">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-3 col-4">
            <div className="topbar-left">
              <Link to={"/client/dashboard"}>
                <img
                  src="/assets/img/person.png"
                  alt="person"
                  width={"55px"}
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-4 text-center">
            <span className="user-name" style={{ color: "white" }}>
              {userName}
            </span>
            <br />
            <span className="user-name" style={{ color: "white" }}>
              Coins:{userLimit}
            </span>
          </div>
          <div className="col-md-2 col-4">
            <div className="topbar-right form-group m-0 text-end">
              <button
                className="btn btn-primary"
                style={{ minWidth: "100px" }}
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      {userLimit < 100 &&
      <div className="marquee-container-alert mb-1">
        <marquee behavior="scroll" direction="left" scrollamount="8">
          <span>
            अपना अकाउंट रिचार्ज कराने के लिए कृपया अपने एजेंट से संपर्क करें ।
          </span>
        </marquee>
      </div>
      }
      <div className="marquee-container">
        {/* Check if there are announcements to display */}
        {announcements.length > 0 && (
          <marquee behavior="scroll" direction="left" scrollamount="8">
            {announcements
              .filter((announcement) => announcement.status === "active")
              .map((announcement, index, array) => (
                <span key={announcement.id}>
                  {announcement.content}
                  {index < array.length - 1 && <span>&emsp;&bull;&emsp;</span>}
                </span>
              ))}
          </marquee>
        )}
      </div>
    </>
  );
};

export default ClientNav;
