import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PowerUserLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaSrc, setCaptchaSrc] = useState("");
  const [captchaVal, setCaptchaVal] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [userIp, setUserIp] = useState("");
  const [respError, setRespError] = useState("");
  //   const navigate = useNavigate();

  useEffect(() => {
    generateCaptcha();
    getUserIpAddress();
  }, []);

  const getUserIpAddress = () => {
    axios.get("https://ipapi.co/json/", {}).then((res) => {
      const resp = res.data;
      setUserIp(resp.ip);
      console.log("red", resp);
    });
  };

  const generateCaptcha = () => {
    axios.post(baseUrl + "/api/create-captha", {}).then((res) => {
      const resp = res.data;
      setCaptchaSrc(
        `data:image/svg+xml;base64,${btoa(
          unescape(encodeURIComponent(resp.captcha))
        )}`
      );
      setCaptchaToken(resp.captchaToken);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    }

    if (name === "password") {
      setPassword(value);
    }

    if (name === "recaptcha") {
      setCaptchaVal(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const loginData = {
      userName: username,
      password: password,
      captchaVal: captchaVal,
      captchaToken: captchaToken,
      userIp: userIp,
    };

    axios.post(baseUrl + "/api/login-p-user", loginData).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        const token = resp.token;
        localStorage.setItem("s_token", token);
        localStorage.setItem("s_role", resp.data.userType);
        toast.success(resp.message);
        setTimeout(() => {
          if (resp.data.userType === "P_USER") {
            window.location.href = "/poweruser/dashboard";
          }
        }, 1500);
      } else {
        setRespError(resp.message);
        setTimeout(() => {
          setRespError("");
        }, 3000);
      }
    });
  };

  return (
    <div className="body-login d-flex justify-content-center align-items-center background-imager-power">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div
            className="col-sm-6 col-md-6 col-lg-4 mx-auto align-middle"
            style={{ zIndex: 10 }}
          >
            <div
              className="card p-4"
              style={{ boxShadow: "0px 0px 30px rgb(0 0 0 / 82%)" }}
            >
              <div className="card-title text-center">
                <div className="card-title text-center p-0 mb-0">
                  <img
                    style={{ width: "20%", borderRadius: "10px" }}
                    src="https://picsum.photos/200"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <input
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={username}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <img
                        src={captchaSrc}
                        style={{ width: "100%", height: "38px" }}
                        alt="captcha"
                      />
                    </div>
                    <div className="col-6">
                      <input
                        autocomplete="off"
                        type="input"
                        name="recaptcha"
                        className="form-control"
                        placeholder="Captcha"
                        maxlength="4"
                        required
                        onChange={handleChange}
                        value={captchaVal}
                      />
                    </div>
                  </div>
                  <div className="row mt-3 mb-2">
                    <div className="col">
                      <button
                        id="login"
                        className="btn btn-primary cstm-btn-sec w-100 g-recaptcha"
                        style={{ width: "100%" }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  {respError && (
                    <p className="alert alert-danger">{respError}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Toaster />
      </div>
    </div>
  );
};

export default PowerUserLogin;
