import React, { Fragment, useEffect, useRef, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import ClientNav from "../Common/ClientNav";
import socketIOClient from "socket.io-client";
import { useParams } from "react-router-dom";
import { baseUrl, socketUrl } from "../../Common/BaseUrl";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";

const ClientBetting = () => {
  const matchId = useParams();
  const clientRef = useRef(null);
  const [scoreResult, setScoreResult] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [bookMakerData, setBookMakerData] = useState([]);
  const [isBookMaker, setIsBookMaker] = useState("");
  const [isSession, setIsSession] = useState("");
  const [isMatchOdds, setIsMatchOdds] = useState("");
  const [matchOdds, setMatchOdds] = useState([]);
  const [show, setShow] = useState(false);
  const [betInfoTitle, setBetInfoTitle] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [bettingAmount, setBettingAmount] = useState(0);
  const [sessionRate, setSessionRate] = useState("");
  const [betType, setBetType] = useState("");
  const [showTeamsModel, setShowTeamsModel] = useState(false);
  const [bookMakerName, setBookMakerName] = useState("");
  const [team, setTeam] = useState("");
  const [makerRate, setMakerRate] = useState("");
  const [bettingAmountmaker, setBettingAmountmaker] = useState(0);
  const [bettingMode, setBettingMode] = useState("");
  const [isMakerBetsShow, setIsMakerBetsShow] = useState(false);
  const [makerBets, setMakerBets] = useState([]);
  const [isSessionBetsShow, setIsSessionBetsShow] = useState(false);
  const [sessionBets, setSessionBets] = useState([]);
  const [sessionRuns, setSessionRuns] = useState("");
  const [declaredSessionBets, setDeclaredSessionBets] = useState([]);
  const [bookMakerMax, setBookMakerMax] = useState(0);
  const [bookMakerMin, setBookMakerMin] = useState(0);
  const [sessionArea, setSessionArea] = useState("");
  const [teamType, setTeamType] = useState("");
  const [showGreen, setShowGreen] = useState("");
  const [showRed, setShowRed] = useState("");

  useEffect(() => {
    if (!clientRef.current) {
      clientRef.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("s_token"),
        },
      });
      clientRef.current.emit("get_match_data_by_id", matchId.matchId);
      clientRef.current.emit("get_scores_by_id", matchId.matchId);
      clientRef.current.emit("get_match_odds_by_id", matchId.matchId);
      clientRef.current.emit("get_match_inner_details", matchId.matchId);
      clientRef.current.emit("get_book_maker", matchId.matchId);
      handleSocketData(clientRef.current);
    }
    getMatchInformation();
    fetchUserMakerBids();
    fetchUserSessionBids();
    fetchUserSessionBidsDeclared();
    return () => {
      if (clientRef.current && !window.location.pathname.includes("/event")) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.disconnect();
      }
    };
  }, []);

  const getMatchInformation = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios.post(baseUrl + "/api/match-info-by-id", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setIsMatchOdds(resp.data.is_matchodds);
        setIsBookMaker(resp.data.is_bookmaker);
        setIsSession(resp.data.is_session);
        setBookMakerMin(resp.data.maker_min);
        setBookMakerMax(resp.data.maker_max);
      }
    });
  };

  const handleSocketData = (socket) => {
    socket.off("result_match_data_by_id");
    socket.on("result_match_data_by_id", (data) => {
      setSessionData(data.data);
    });
    socket.off("result_get_scores_by_id");
    socket.on("result_get_scores_by_id", (data) => {
      console.log("scoreResult", data);
      setScoreResult(data.data);
    });

    socket.off("result_match_odds_by_id");
    socket.on("result_match_odds_by_id", (data) => {
      console.log("oddsResult", data);
    });
    socket.off("result_match_inner_details");
    socket.on("result_match_inner_details", (data) => {
      console.log("someomosmosmsomd", data);
    });

    socket.off("result_book_maker");
    socket.on("result_book_maker", (data) => {
      console.log("result_book_maker", data);
      setBookMakerData(data);
    });
  };

  const scoreBoardMap = () => {
    console.log("sdksndkfjsdnkjansdk", scoreResult);
    return (
      <div className="scoreboard">
        <div className="scoreboard-top scoreboard-inner">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="total-score">
                <span>
                  {scoreResult.teams && scoreResult.teams[1].team_short_name}
                </span>
                <h5>{scoreResult.teams && scoreResult.teams[1].score}</h5>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="total-score">
                <span>
                  {scoreResult.teams && scoreResult.teams[0].team_short_name}
                </span>
                <h5>{scoreResult.teams && scoreResult.teams[0].score}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="scoreboard-middle scoreboard-inner">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="total-score current-rate">
                <span>CRR {scoreResult.currentRunRate}</span>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="total-score bowl-score">
                <ul className="nav score-count">
                  {scoreResult.last24balls &&
                    scoreResult.last24balls.map((item) => {
                      return <li>{item.score_card}</li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="scoreboard-bottom scoreboard-inner">
          <div className="winning-run text-center">
            <p>
              <b>{scoreResult.msg}</b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const convertToDecimal = (value) => {
    // Extract the decimal part and convert to a number
    const decimalPart = parseFloat(
      `0.${value.toString().split(".")[1] || "0"}`
    );
    return decimalPart.toFixed(2);
  };

  const boookMakerTable = () => {
    return bookMakerData.map((item) => {
      const reversedRunners = [...item.runners].reverse();
      console.log("itembookMakerDatabookMakerData", reversedRunners);
      return reversedRunners.map((runer) => {
        const backOdds = convertToDecimal(runer.back[0].price1);
        const layOdds = convertToDecimal(runer.lay[0].price1);

        return (
          <tr>
            <td>
              {runer.runnerName}
              <br />
              <span>
                <small>({parseInt(runer.totalPlusMinus)})</small>
              </span>
            </td>
            <td
              onClick={() => {
                handleTeamBets(runer, "back");
              }}
            >
              {runer.status === "SUSPENDED" ? "SUSPENDED" : backOdds}
            </td>
            <td onClick={() => handleTeamBets(runer, "lay")}>
              {runer.status === "SUSPENDED" ? "SUSPENDED" : layOdds}
            </td>
          </tr>
        );
      });
    });
  };

  const sessioNMap = () => {
    const reversedSessionData = sessionData.slice().reverse();
    return reversedSessionData.map((item, index) => {
      console.log("itesm", item);
      return (
        <tr key={index}>
          <td
            style={
              item.n_rate > 0
                ? {
                    color: "white",
                    backgroundColor: "#438bff",
                    borderColor: "white",
                  }
                : {}
            }
          >
            {item.title}
            <br />
            <span>
              <small>
                MIN:{item.min_amt === null ? 0 : item.min_amt} MAX:
                {item.max_amt === null ? 0 : item.max_amt}
              </small>
            </span>
          </td>
          {item.n_rate == 0 ? (
            <td className="text-center">SUSPENDED</td>
          ) : (
            <td
              className="text-center"
              style={
                item.n_rate > 0 && {
                  color: "white",
                  backgroundColor: "#438bff",
                  borderColor: "white",
                }
              }
              onClick={() => handlePlaceBid(item, "NOT", "lay")}
            >
              {item.n_rate} <span className="small-box">{item.n_run}</span>
            </td>
          )}
          {item.y_rate == 0 ? (
            <td className="text-center">SUSPENDED</td>
          ) : (
            <td
              className="text-center"
              onClick={() => handlePlaceBid(item, "YES", "back")}
              style={
                item.y_rate > 0
                  ? {
                      color: "white",
                      backgroundColor: "#438bff",
                      borderColor: "white",
                    }
                  : {}
              }
            >
              {item.y_rate} <span className="small-box">{item.y_run}</span>
            </td>
          )}
        </tr>
      );
    });
  };

  const matchOddsMap = () => {
    const reversedSessionData = matchOdds.slice().reverse();
    return reversedSessionData.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.RunnerName}</td>
          {item.LayPrice1 == 0 ? (
            <td>SUSPENDED</td>
          ) : (
            <td>
              {item.LayPrice1} <span>{item.LaySize1}</span>
            </td>
          )}
          {item.BackPrice1 == 0 ? (
            <td>SUSPENDED</td>
          ) : (
            <td>
              {item.BackPrice1} <span>{item.BackPrice1}</span>
            </td>
          )}
        </tr>
      );
    });
  };

  const handleClose = () => {
    setShow(false);
    setShowTeamsModel(false);
    setShowGreen("");
    setShowRed("");
    setBettingAmount(0);
    setBettingAmountmaker(0);
  };

  const handlePlaceBid = (item, sessionTy, type) => {
    setShow(true);
    setBetInfoTitle(item.title);
    setSessionName(item.title);
    setSessionId(item.selection_id);
    setSessionArea(sessionTy);
    setBetType("Session");
    if (type === "lay") {
      setSessionRate(item.n_run);
      setSessionRuns(item.n_rate);
    }
    if (type === "back") {
      setSessionRuns(item.y_rate);
      setSessionRate(item.y_run);
    }
  };

  const handleTeamBets = (item, type) => {
    console.log("someitemsthatare", item);
    if (item.status === "SUSPENDED") {
      return;
    } else {
      setShowTeamsModel(true);
      setBookMakerName(item.runnerName);
      setTeam(item.runnerName);
      setTeamType(item.selectionId);
      setBetType("Team");
      if (type === "lay") {
        setBettingMode("K");
        setMakerRate(convertToDecimal(item.lay[0].price1));
      } else {
        setBettingMode("L");
        setMakerRate(convertToDecimal(item.back[0].price1));
      }
    }
  };

  const placeBet = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const data = {
      matchId: matchId.matchId,
      sessionName: sessionName,
      sessionId: sessionId,
      betType: betType,
      sessionRate: sessionRate,
      amount: bettingAmount,
      sessionRun: sessionRuns,
      sessionArea: sessionArea,
    };

    axios.post(baseUrl + "/api/place-bet-session", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        setBettingAmount("");
        setShow(false);
        fetchUserSessionBids();
        fetchUserSessionBidsDeclared();
      } else {
        toast.error(resp.message);
      }
    });
  };

  const placeBetBookMaker = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const data = {
      matchId: matchId.matchId,
      betType: betType,
      amount: bettingAmountmaker,
      team: team,
      makerRate: makerRate,
      bettingMode: bettingMode,
      teamType: teamType,
    };

    axios.post(baseUrl + "/api/place-bet-teams", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success(resp.message);
        setBettingAmount("");
        setShowTeamsModel(false);
        fetchUserMakerBids();
      } else {
        toast.error(resp.message);
      }
    });
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (name === "session_amount") {
      setBettingAmount(value);
    }
    if (name === "teams_amount") {
      setBettingAmountmaker(value);

      if (bettingMode === "K") {
        // makerRate
        setShowGreen(value);
        setShowRed(Math.round(value * Number(makerRate)));
      } else {
        setShowGreen(Math.round(value * Number(makerRate)));
        setShowRed(value);
      }
    }
  };

  const handleChangeSessionAmount = (e, amount) => {
    e.preventDefault();
    let newAmount = Number(bettingAmount) + Number(amount);
    setBettingAmount(newAmount);
  };

  const handleChangeMatchAmount = (e, amount) => {
    e.preventDefault();

    let newAmount = Number(bettingAmountmaker) + Number(amount);

    if (bettingMode === "K") {
      // makerRate
      setShowGreen(newAmount);
      setShowRed(Math.round(newAmount * Number(makerRate)));
    } else {
      setShowGreen(Math.round(newAmount * Number(makerRate)));
      setShowRed(newAmount);
    }

    setBettingAmountmaker(newAmount);
  };

  const fetchUserSessionBids = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios
      .post(baseUrl + "/api/fetch-user-bets-session", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setIsSessionBetsShow(true);
          setSessionBets(resp.data);
        }
      });
  };

  const fetchUserSessionBidsDeclared = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios
      .post(baseUrl + "/api/fetch-user-bets-session-declared", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setIsSessionBetsShow(true);
          setDeclaredSessionBets(resp.data);
        }
      });
  };

  const fetchUserMakerBids = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const data = {
      matchId: matchId.matchId,
    };
    axios
      .post(baseUrl + "/api/fetch-user-bets-maker", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setIsMakerBetsShow(true);
          setMakerBets(resp.data);
        }
      });
  };

  const columns = [
    {
      key: "",
      text: "S.No.",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "rate",
      text: "Rate",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => <Fragment>{parseInt(record.rate * 100)}</Fragment>,
    },

    {
      key: "amount",
      text: "Amount",
      className: "debit",
      align: "left",
      sortable: true,
    },

    {
      key: "mode",
      text: "Mode",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>{record.mode === "L" ? "LAGAI" : "KHAI"}</Fragment>
      ),
    },

    {
      key: "team",
      text: "Team",
      className: "debit",
      align: "left",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Date",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {moment(record.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </Fragment>
      ),
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const columnsSession = [
    {
      key: "",
      text: "S.No.",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "",
      text: "name",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => <Fragment>{record.session}</Fragment>,
    },
    {
      key: "session_rate",
      text: "Rate",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "run",
      text: "Run",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "mode",
      text: "Mode",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {record.session_mode === null ? "YES" : record.session_mode}
        </Fragment>
      ),
    },
    {
      key: "created_at",
      text: "Date",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {moment(record.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </Fragment>
      ),
    },
  ];

  const columnsSessionDeclare = [
    {
      key: "",
      text: "S.No.",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "",
      text: "name",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => <Fragment>{record.session}</Fragment>,
    },
    {
      key: "session_rate",
      text: "Rate",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "run",
      text: "Run",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "session_mode",
      text: "Mode",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {record.session_mode === null ? "YES" : record.session_mode}
        </Fragment>
      ),
    },
    // {
    //   key: "P/L",
    //   text: "P/L",
    //   className: "credit",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     let isProfit;
    //     if (record.session_mode === "YES") {
    //       if (record.decl > record.run) {
    //         isProfit = true;
    //       } else {
    //         isProfit = false;
    //       }
    //     } else {
    //       if (record.decl < record.run) {
    //         isProfit = true;
    //       } else {
    //         isProfit = false;
    //       }
    //     }
    //     return (
    //       <span
    //         style={isProfit === true ? { color: "green" } : { color: "red" }}
    //       >
    //         {record.plus_extra}
    //       </span>
    //     );
    //   },
    // },
    {
      key: "profit",
      text: "Profit & Loss",
      className: "profit",
      align: "left",
      sortable: true,
      cell: (record) => {
        let show = "";
        let showAmount = 0;

        if (record.session_mode == "YES") {
          if (parseInt(record.run) <= parseInt(record.decl)) {
            show = "Green";
            showAmount = record.amount * record.session_rate;
          } else {
            show = "Red";
            showAmount = record.amount;
          }
        } else {
          if (parseInt(record.run) > parseInt(record.decl)) {
            show = "Green";
            showAmount = record.amount;
          } else {
            show = "Red";
            showAmount = record.amount * record.session_rate;
          }
        }

        return <span style={{ color: show }}>{showAmount}</span>;
      },
    },
    {
      key: "decl",
      text: "Decl",
      className: "credit",
      align: "left",
      sortable: true,
    },
  ];

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading text-center">
            <h1>InPlay</h1>
          </div>
          <div className="super-agent">
            <div className="super-outer">
              <div className="row mb-3">
                <div className="col-12">
                  <iframe
                    src="https://www.youtube.com/embed/a3ICNMQW7Ok?si=DLwH5r80rkwsP8TO"
                    title="W3Schools Free Online Web Tutorials"
                    width="100%"
                  ></iframe>
                </div>
              </div>
              {scoreBoardMap()}
              <div className="row mb-3">
                {isBookMaker === "Y" && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th colSpan={3} style={{ textAlign: "center" }}>
                                Max-{bookMakerMax === null ? 0 : bookMakerMax}{" "}
                                Min- {bookMakerMin === null ? 0 : bookMakerMin}
                              </th>
                            </tr>
                            <tr>
                              <th>Team</th>
                              <th>LAGAI</th>
                              <th>KHAI</th>
                            </tr>
                          </thead>
                          <tbody>{boookMakerTable()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {isSession === "Y" && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Session</th>
                              <th>NOT</th>
                              <th>YES</th>
                            </tr>
                          </thead>

                          <tbody>{sessioNMap()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row mb-3">
                {isMatchOdds === "Y" && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Back</th>
                              <th>Lay</th>
                            </tr>
                          </thead>

                          <tbody>{matchOddsMap()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mb-3">
                {isMakerBetsShow === true && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <ReactDatatable
                          config={config}
                          records={makerBets}
                          columns={columns}
                          //   onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isSessionBetsShow === true && (
                  <div className="col-md-6 col-12">
                    <div className="super-inner super-left">
                      <div className="table-responsive">
                        <ReactDatatable
                          config={config}
                          records={sessionBets}
                          columns={columnsSession}
                          //   onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="scoreboard-bottom scoreboard-inner">
          <div className="winning-run text-center">
            <p>
              <b>Declare Bets</b>
            </p>
          </div>
          {declaredSessionBets.length > 0 && (
            <div className="row mb-3">
              {isSessionBetsShow === true && (
                <div className="col-12">
                  <div className="super-inner super-left">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={declaredSessionBets}
                        columns={columnsSessionDeclare}
                        //   onPageChange={this.pageChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Toaster />
        {/* Session model */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{betInfoTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Enter Amount"
                value={bettingAmount}
                onChange={handleChnage}
                name="session_amount"
              />
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 100);
                  }}
                >
                  100
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 200);
                  }}
                >
                  200
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 500);
                  }}
                >
                  500
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 1000);
                  }}
                >
                  1k
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 2000);
                  }}
                >
                  2k
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 3000);
                  }}
                >
                  3k
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 5000);
                  }}
                >
                  5k
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 10000);
                  }}
                >
                  10k
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 20000);
                  }}
                >
                  20k
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeSessionAmount(e, 50000);
                  }}
                >
                  50k
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onChange={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={placeBet}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Session model */}

        {/* Teams model */}
        <Modal show={showTeamsModel} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{bookMakerName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Enter Amount"
                value={bettingAmountmaker}
                onChange={handleChnage}
                name="teams_amount"
              />

              {showGreen && showGreen > 0 ? (
                <p style={{ color: "green" }}> {showGreen} </p>
              ) : (
                ""
              )}
              {showRed && showRed > 0 ? (
                <p style={{ color: "red" }}> {showRed} </p>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 500);
                  }}
                >
                  500
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 1000);
                  }}
                >
                  1K
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 2000);
                  }}
                >
                  2K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 3000);
                  }}
                >
                  3K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 5000);
                  }}
                >
                  5K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 10000);
                  }}
                >
                  10K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 20000);
                  }}
                >
                  20K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 25000);
                  }}
                >
                  25K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 50000);
                  }}
                >
                  50K
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 100000);
                  }}
                >
                  1L
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleChangeMatchAmount(e, 200000);
                  }}
                >
                  2L
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={placeBetBookMaker}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Teams model */}
      </div>
    </div>
  );
};

export default ClientBetting;
