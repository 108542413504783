import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./BaseUrl";

const Nav = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });

  const getAllAccouncment = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/fetch-announcemtnt-super-admin", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setAnnouncements(resp.data);
      }
    });
  };

  useEffect(() => {
    // Assuming you have the JWT token stored in local storage
    const localRole = localStorage.getItem("s_role");

    // Decode the JWT token to get user information, including the role

    // Extract the user role from the decoded token
    const role = localRole ? localRole : null;

    // Set the user role in state
    setUserRole(role);
  }, [userRole]);

  useEffect(() => {
    getUserInfo();
    getAllAccouncment();
  }, []);

  const getUserInfo = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data.username);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("s_token") !== "null" &&
          localStorage.getItem("s_token") !== null
        ) {
          localStorage.clear();
          setTimeout(() => navigator("/"), 2000);
        }
      }
    });
  };

  const onChangeDropDown = (e) => {
    const { value } = e.target;
    if (value === "2") {
      logout();
    }
  };

  const logout = () => {
    localStorage.clear();
    if (userRole === "CLIENT") {
      navigate("/");
    } else {
      navigate("/admin");
    }
  };

  return (
    <>
      <div className="top-bar">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-3 col-4">
            <div className="topbar-left">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div className="col-md-2 col-8">
            <div className="topbar-right form-group m-0">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={onChangeDropDown}
              >
                <option selected value={"selected"}>
                  {userName}
                </option>
                <option value="1">Change Password</option>
                <option value="2">Logout</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="marquee-container">
        {/* Check if there are announcements to display */}
        {announcements.length > 0 && (
          <marquee behavior="scroll" direction="left" scrollamount="8">
            {announcements.map(
              (announcement) =>
                announcement.status === "active" && (
                  <span key={announcement.id}>{announcement.content}</span>
                )
            )}
          </marquee>
        )}
      </div>
    </>
  );
};

export default Nav;
