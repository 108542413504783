import React from "react";

const Footer = () => {
  return (
    <div
      className="footer bg-warning py-1 w-100 text-center d-inline-block"
      style={{ position: "fixed", bottom: "0px", left: "0px", zIndex: "99" }}
    >
      Powered by Betfair
    </div>
  );
};

export default Footer;
