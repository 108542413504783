import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import AdminNav from "../Common/AdminNav";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { Link } from "react-router-dom";

const PuserInPlay = () => {
  const [clientRecords, setClientRecords] = useState([]);
  const [filtredRecords, setFiltredRecords] = useState([]);

  useEffect(() => {
    getAllMatches();
    // getAllSportsList();
  }, []);

  const getAllMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const currentTime = moment();
    const thresholdTime = currentTime.clone().add(30, "minutes");
    axios.post(baseUrl + "/api/get-all-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        const filteredMatches = resp.data.filter((match) => {
          const matchStartTime = moment(match.open_date); // Adjust the format if needed

          // Check if the match is scheduled to start within the next 30 minutes or has already started
          return (
            matchStartTime.isSame(currentTime, "day") &&
            matchStartTime.isBetween(
              currentTime.clone().subtract(30, "minutes"),
              thresholdTime
            )
          );
        });
        setClientRecords(filteredMatches);
      }
    });
  };
  const columns = [
    {
      cell: (row, index) => index + 1,
      key: "checkbox",
      text: "S.No.",
      className: "checkbox",
      sortable: false,
    },
    {
      key: "match_name",
      text: "Name",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={`/admin/in-play-game/${record.match_id}`}>
              {record.match_name}
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "match_id",
      text: "Match Code",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "series_id",
      text: "Sport",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.sportName}</Fragment>;
      },
    },
    {
      key: "open_date",
      text: "Start Date",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.open_date).format("lll")}</Fragment>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>In Play Matches</h1>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuserInPlay;
