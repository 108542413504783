import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import ReactDatatable from "@mkikets/react-datatable";
import { Link, useNavigate } from "react-router-dom";
import SuperAdminNav from "../Common/SuperAdminNav";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

const SportsBettingSA = () => {
  const navigate = useNavigate();
  const [clientRecords, setClientRecords] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    }
    fetchMatches();
  }, []);

  const fetchMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-sports-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const handleDropdownSelect = (event, record) => {
    if (event === "session_bets" || event === "match_bets") {
      navigate(`/admin/client/bets/${record.match_id}`);
    }
  };

  const columns = [
    {
      key: "",
      text: "-",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <Dropdown onSelect={(event) => handleDropdownSelect(event, record)}>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
              ></Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="updateLimit"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Match & Session Plus Minus
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="match_bets"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Display Match Bet
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="session_bets"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  Display Session Bet
                </Dropdown.Item>
                {localStorage.getItem("s_role") === "ADMIN_USER" ||
                localStorage.getItem("s_role") === "SUPER_ADMIN" ? (
                  <Dropdown.Item
                    eventKey="undeclared_bets"
                    style={{ borderBottom: "1px solid #ccc" }}
                    onClick={() => {
                      window.location.href = `/admin/client/undeclared-bets/${record.match_id}`;
                    }}
                  >
                    Un Declared Bet
                  </Dropdown.Item>
                ) : (
                  ""
                )}

                {/* agent/client/undeclared-bets */}

                {/* Add more items as needed */}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        );
      },
    },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{moment(record.open_date).format("lll")}</span>;
      },
    },
    {
      key: "match_name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <Link to={`/sa/client/bets/${record.match_id}`}>
              {record.match_name}
            </Link>
          </span>
        );
      },
    },
    {
      key: "winning_team",
      text: "Winner",
      className: "winner",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_no",
      text: "My Share P/L",
      className: "mobile_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        let profitLoss = 0;
        if (record.parentWon == 0) {
          profitLoss = record.parentLos;
        } else {
          profitLoss = record.parentWon;
        }
        let totalAmount = record.childAmount - profitLoss;
        if (totalAmount > 0) {
          profitLoss = profitLoss;
        } else {
          profitLoss = -profitLoss;
        }
        return (
          <span style={totalAmount > 0 ? { color: "green" } : { color: "red" }}>
            {isNaN(totalAmount) ? profitLoss : parseFloat(totalAmount)}
          </span>
        );
      },
    },

    {
      key: "password",
      text: "Total P/L",
      className: "password",
      align: "left",
      sortable: true,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <SuperAdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsBettingSA;
