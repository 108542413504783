import React from "react";
import Sidebar from "../../Common/Sidebar";
import Nav from "../../Common/Nav";
import { Link } from "react-router-dom";
import ClientNav from "../Common/ClientNav";

const ClientDashboard = () => {
  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Dashboard</h1>
          </div>
          <div className="match-inner casino-inner">
            <div className="row">
              <div className="col-12">
                <div className="match-left">
                  <ul className="nav flex-wrap">
                    <li className="position-relative">
                      <img
                        src="/assets/img/poker.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>In Play</p>
                      </div>
                      <Link
                        to={"/client/inplay"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Upcoming Games</p>
                      </div>
                      <Link
                        to={"/client/all-sports"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Casino Games</p>
                      </div>
                      <Link
                        to={"/client/casino"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/my-profile.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>My Profile</p>
                      </div>
                      <Link
                        to={"/client/client-profile"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/ledger.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>My Ledger</p>
                      </div>
                      <Link
                        to={"/client/ledger"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Complete Games</p>
                      </div>
                      <Link
                        to={"/client/complete-games"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/teen-patti2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Change Password</p>
                      </div>
                      <Link
                        to={"/client/password"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                    <li className="position-relative">
                      <img
                        src="/assets/img/lucky-player.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="content-outer position-absolute">
                        <p>Old Data</p>
                      </div>
                      <Link
                        to={"/client/old"}
                        className="full-link position-absolute top-0 start-0 h-100 w-100"
                      ></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
