import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/AuthPages/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ClientMaster from "./components/Master/ClientMaster";
import SuperAgent from "./components/Master/SuperAgent";
import AgentMaster from "./components/Master/AgentMaster";
import SportsBetting from "./components/Betting/SportsBetting";
import MyLedger from "./components/Ledger/MyLedger";
import AllSuperAgentLedger from "./components/Ledger/AllSuperAgentLedger";
import TotalProfit from "./components/Ledger/TotalProfit";
import SuperAgentLedger from "./components/Ledger/SuperAgentLedger";
import AllSuperAgentReport from "./components/Reports/AllSuperAgentReport";
import AllAgentLedger from "./components/Ledger/AllAgentLedger";
import AgenntLedger from "./components/Ledger/AgentLedger";
import AllAgentReport from "./components/Reports/AllAgentReport";
import AllClientLedger from "./components/Ledger/AllClientLedger";
import ClientLedger from "./components/Ledger/ClientLedher";
import ClientCommission from "./components/Commission/ClientCommission";
import AllClientReport from "./components/Reports/AllClientsReports";
import ClientDashboard from "./components/clientPages/Dashboard/ClientDashboard";
import Inplay from "./components/clientPages/PlayZone/ClientPlay";
import UpComming from "./components/clientPages/PlayZone/UpComming";
import Casino from "./components/clientPages/PlayZone/Casino";
import Profile from "./components/clientPages/Profile/Profile";
import ClientLeadger from "./components/clientPages/Leadger/ClientLeadger";
import CompletedGames from "./components/clientPages/PlayZone/CompletedGames";
import ChangePassword from "./components/clientPages/ChangePassowrd/ChangePassword";
import ClientOldHistory from "./components/clientPages/OldHistory/ClientOldHistory";
import TermsAndCondition from "./components/clientPages/Common/TermsAndCondition";
import Betting from "./components/Betting/Betting";
import Matches from "./components/PowerUser/Matches";
import AddNewUser from "./components/PowerUser/AddNewUser";
import SuperAdmin from "./components/AdminPages/SuperAdmin";
import PowerAdmin from "./components/AdminPages/AdminPower";
import MiniAdmin from "./components/AdminPages/MiniAdmin";
import Master from "./components/AdminPages/Master";
import AdminLeadger from "./components/Ledger/AdminLeadger";
import SuperAdminLeadger from "./components/Ledger/SuperAdminLeadger";
import AllSuperAdminReports from "./components/Reports/AllSuperAdminReports";
import AdminDashboard from "./components/AdminPages/AdminDashboard";
import SuperAdminDashboard from "./components/SuperAdminPages/SuperAdminDashboard";
import MiniAdminDashboard from "./components/MiniAdminPages/MiniDashboard";
import AdminTotalProfit from "./components/AdminPages/AdminTotalProfit";
import AllMiniAdminLeadger from "./components/Ledger/AllMiniAdminLeadger";
import SuperAdminTotalProfit from "./components/SuperAdminPages/SuperAdminTotalProfit";
import AllMasterLeadger from "./components/Ledger/AllMasterLeadger";
import MiniAdminLeadger from "./components/Ledger/MiniAdminLeadger";
import MiniAdminTotalProfit from "./components/MiniAdminPages/MiniAdminTotalProfit";
import MasterLeadger from "./components/Ledger/MasterLeadger";
import AdminLogin from "./components/AuthPages/AdminLogin";
import PowerUserLogin from "./components/AuthPages/PowerUserLogin";
import AdminSuperAgent from "./components/AdminPages/AdminSuperAgent";
import AdminAgents from "./components/AdminPages/AdminAgents";
import AdminClientMaster from "./components/AdminPages/AdminClientMaster";
import AddMiniAdmin from "./components/MiniAdminPages/AddMiniAdmin";
import SuperAdminMiniAdmin from "./components/SuperAdminPages/SuperAdminMiniAdmin";
import MiniAdminMaster from "./components/MiniAdminPages/MiniAdminMaster";
import SuperAdminMaster from "./components/SuperAdminPages/SyperAdminMaster";
import SuperAgentSuperAdmin from "./components/SuperAdminPages/SuperAdminSuperAgent";
import SuperAdminAgents from "./components/SuperAdminPages/SuperAdminAgents";
import SuperAdminClient from "./components/SuperAdminPages/SuperAdminClinet";
import MiniAdminSuperAgent from "./components/MiniAdminPages/MiniAdminSuperAgent";
import MiniAdminAgents from "./components/MiniAdminPages/MiniAdminAgents";
import MiniAdminClient from "./components/MiniAdminPages/MiniAdminClient";
import MasterAgents from "./components/Master/MasterAgentMaster";
import MasterClientMaster from "./components/Master/MasterClientMaster";
import SuperAgentClient from "./components/Master/SuperAgentClient";
import MasterDashboard from "./components/Master/MasterDashboard";
import SuperAgentDashboard from "./components/Master/SuperAgentDashboard";
import AgentDashboard from "./components/Master/AgentDashboard";
import Announcement from "./components/AdminPages/Announcement";
import MiniAnnouncement from "./components/MiniAdminPages/MiniAnnouncement";
import SuperAnnouncement from "./components/SuperAdminPages/SuperAnnouncement";
import SportsBettingSA from "./components/Betting/SportsBettingSA";
import MatchManagmentAdmin from "./components/AdminPages/MatchManagmentAdmin";
import AdminBetting from "./components/AdminPages/AdminBetting";
import SportsMatchList from "./components/AdminPages/SportsMatchList";
import InPplayRecords from "./components/AdminPages/InPplayRecords";
import InPlayRecordsGame from "./components/AdminPages/InPlayRecordsGame";
import PuserInPlay from "./components/PowerUser/PuserInPlay";
import PuserInPlayRecords from "./components/PowerUser/PuserInPlayRecords";
import ClientBetting from "./components/clientPages/BettingPage/ClientBetting";
import NotFound from "./components/clientPages/Common/404";
import CompletedMatchUser from "./components/clientPages/PlayZone/CompletedMatchUser";
import UnDeclaredBets from "./components/AdminPages/UnDeclaredBets";
import UnDeclaredSessionBets from "./components/AdminPages/UnDeclaredSessionBets";
import AdminMatchSession from "./components/AdminPages/AdminMatchSession";

const App = () => {
  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });
  useEffect(() => {
    // Assuming you have the JWT token stored in local storage
    const localRole = localStorage.getItem("s_role");

    // Decode the JWT token to get user information, including the role

    // Extract the user role from the decoded token
    const role = localRole ? localRole : null;

    // Set the user role in state
    setUserRole(role);
  }, [userRole]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/poweruser" element={<PowerUserLogin />} />
        {userRole === "P_USER" && (
          <>
            <Route path="/poweruser/dashboard" element={<Dashboard />} />
            <Route path="/poweruser/matches" element={<Matches />} />
            <Route path="/poweruser/add-user" element={<AddNewUser />} />
            <Route path="/poweruser/in-play" element={<PuserInPlay />} />
            <Route path="/poweruser/announcement" element={<Announcement />} />
            <Route path="/poweruser/match-managment" element={<MatchManagmentAdmin />} />

           <Route path="/poweruser/sports" element={<SportsMatchList />} />
            <Route path="/poweruser/in-play" element={<InPplayRecords />} />  
            <Route
              path="/poweruser/in-play-game/:match_id"
              element={<PuserInPlayRecords />}
            />
          </>
        )}
        {userRole === "ADMIN_USER" && (
          <>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/client" element={<AdminClientMaster />} />
            <Route path="/admin/sa" element={<AdminSuperAgent />} />
            <Route path="/admin/agent" element={<AdminAgents />} />
            <Route path="/admin/client/sports" element={<SportsBetting />} />
            <Route path="/admin/main/ledger" element={<MyLedger />} />
            <Route path="/admin/super-admin" element={<SuperAdmin />} />
            <Route path="/admin/power-admin" element={<PowerAdmin />} />
            <Route path="/admin/mini-admin" element={<MiniAdmin />} />
            <Route path="/admin/master" element={<Master />} />
            <Route path="/admin/sa/settlement" element={<AdminLeadger />} />
            <Route path="/admin/ledger" element={<AdminTotalProfit />} />
            <Route path="/admin/sa/ledger" element={<SuperAdminLeadger />} />
            <Route path="/admin/announcement" element={<Announcement />} />
            <Route path="/admin/sports" element={<SportsMatchList />} />
            <Route path="/admin/in-play" element={<InPplayRecords />} />
            <Route
              path="/admin/in-play-game/:match_id"
              element={<InPlayRecordsGame />}
            />
            
            <Route
              path="/admin/match-managment"
              element={<MatchManagmentAdmin />}
            />
            <Route
              path="/admin/sa/final/report"
              element={<AllSuperAdminReports />}
            />
            <Route
              path="/admin/client/bets/:matchId"
              element={<AdminBetting />}
            />
             <Route
              path="/admin/matchSession/:matchId"
              element={<AdminMatchSession />}
            />
            
            <Route
              path="/admin/client/undeclared-bets/:matchId"
              element={<UnDeclaredBets />}
            />
            <Route
              path="/admin/client/undeclared-session-bets/:matchId"
              element={<UnDeclaredSessionBets />}
            />
          </>
        )}
        {userRole === "S_ADMIN" && (
          <>
            <Route path="/super/dashboard" element={<SuperAdminDashboard />} />
            <Route path="/super/client" element={<SuperAdminClient />} />
            <Route path="/super/sa" element={<SuperAgentSuperAdmin />} />
            <Route path="/super/agent" element={<SuperAdminAgents />} />
            <Route path="/super/client/sports" element={<SportsBettingSA />} />
            <Route path="/super/main/ledger" element={<MyLedger />} />
            <Route path="/super/mini-admin" element={<SuperAdminMiniAdmin />} />
            <Route path="/super/master" element={<SuperAdminMaster />} />
            <Route path="/super/announcement" element={<SuperAnnouncement />} />
            <Route
              path="/super/client/bets/:matchId"
              element={<AdminBetting />}
            />
            <Route
              path="/super/sa/settlement"
              element={<AllMiniAdminLeadger />}
            />
            <Route path="/super/ledger" element={<SuperAdminTotalProfit />} />
            <Route path="/super/sa/ledger" element={<MiniAdminLeadger />} />
            <Route
              path="/super/sa/final/report"
              element={<AllSuperAdminReports />}
            />
          </>
        )}
        {userRole === "M_ADMIN" && (
          <>
            <Route path="/mini/dashboard" element={<MiniAdminDashboard />} />
            <Route path="/mini/client" element={<MiniAdminClient />} />
            <Route path="/mini/sa" element={<MiniAdminSuperAgent />} />
            <Route path="/mini/agent" element={<MiniAdminAgents />} />
            <Route path="/mini/client/sports" element={<SportsBetting />} />
            <Route path="/mini/main/ledger" element={<MyLedger />} />
            <Route path="/mini/master" element={<MiniAdminMaster />} />
            <Route path="/mini/sa/settlement" element={<AllMasterLeadger />} />
            <Route path="/mini/ledger" element={<MiniAdminTotalProfit />} />
            <Route path="/mini/sa/ledger" element={<MasterLeadger />} />
            <Route path="/mini/announcement" element={<MiniAnnouncement />} />
            <Route
              path="/mini/sa/final/report"
              element={<AllSuperAdminReports />}
            />
            <Route
              path="/mini/client/bets/:matchId"
              element={<AdminBetting />}
            />
          </>
        )}
        {userRole === "M_USER" && (
          <>
            <Route path="/master/dashboard" element={<MasterDashboard />} />
            <Route path="/master/client" element={<MasterClientMaster />} />
            <Route path="/master/sa" element={<SuperAgent />} />
            <Route path="/master/agent" element={<MasterAgents />} />
            <Route path="/master/client/sports" element={<SportsBetting />} />
            <Route path="/master/admin/ledger" element={<MyLedger />} />
            <Route
              path="/master/sa/settlement"
              element={<AllSuperAgentLedger />}
            />
            <Route path="/master/ledger" element={<TotalProfit />} />
            <Route path="/master/sa/ledger" element={<SuperAgentLedger />} />
            <Route path="/master/client/bets/:matchId" element={<Betting />} />
            <Route
              path="/master/sa/final/report"
              element={<AllSuperAgentReport />}
            />
          </>
        )}
        {userRole === "S_AGENT" && (
          <>
            <Route path="/sa/dashboard" element={<SuperAgentDashboard />} />
            <Route path="/sa/client" element={<SuperAgentClient />} />
            <Route path="/sa/agent" element={<AgentMaster />} />
            <Route path="/sa/client/sports" element={<SportsBetting />} />
            <Route path="/sa/master/ledger" element={<MyLedger />} />
            <Route path="/sa/agent/settlement" element={<AllAgentLedger />} />
            <Route path="/sa/ledger" element={<TotalProfit />} />
            <Route path="/sa/agent/ledger" element={<AgenntLedger />} />
            <Route path="/sa/agent/final/report" element={<AllAgentReport />} />
            <Route path="/sa/client/bets/:matchId" element={<Betting />} />
          </>
        )}
        {userRole === "AGENT" && (
          <>
            <Route path="/agent/dashboard" element={<AgentDashboard />} />
            <Route path="/agent/client" element={<ClientMaster />} />
            <Route path="/agent/client/sports" element={<SportsBetting />} />
            <Route path="/agent/sa/ledger" element={<MyLedger />} />
            <Route
              path="/agent/client/settlement"
              element={<AllClientLedger />}
            />
            <Route path="/agent/ledger" element={<TotalProfit />} />
            <Route path="/agent/client/ledger" element={<ClientLedger />} />
            <Route path="/agent/commission" element={<ClientCommission />} />
            <Route
              path="/agent/client/final/report"
              element={<AllClientReport />}
            />
            <Route path="/agent/client/bets/:matchId" element={<Betting />} />
          </>
        )}
        {userRole === "CLIENT" && (
          <>
            <Route path="/client/dashboard" element={<ClientDashboard />} />
            <Route path="/client/inplay" element={<Inplay />} />
            <Route path="/client/all-sports" element={<UpComming />} />
            <Route path="/client/casino" element={<Casino />} />
            <Route path="/client/client-profile" element={<Profile />} />
            <Route path="/client/ledger" element={<ClientLeadger />} />
            <Route path="/client/complete-games" element={<CompletedGames />} />
            <Route path="/client/password" element={<ChangePassword />} />
            <Route path="/client/old" element={<ClientOldHistory />} />
            <Route path="/client/tc" element={<TermsAndCondition />} />
            <Route path="/client/event/:matchId" element={<ClientBetting />} />
            <Route
              path="/client/complete-games/:matchId"
              element={<CompletedMatchUser />}
            />
          </>
        )}
        <Route path="*" exact={true} element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
