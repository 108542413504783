import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Nav from "../Common/Nav";
import { baseUrl } from "../Common/BaseUrl";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const AddNewUser = () => {
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [clientRecords, setClientRecords] = useState([]);
  const [nameErro, setNameErro] = useState("");

  useEffect(() => {
    getAgent();
    generateUserName();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    }

    if (name === "password") {
      setPassword(value);
    }
    if (name === "mobile") {
      setMobile(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name && !password && !mobile) {
      setNameErro("This filed is require");
      setMobileErr("This filed is require");
      setPasswordErr("This filed is require");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const agentData = {
      username: userName,
      name: name,
      password: password,
      mobile: mobile,
    };

    axios
      .post(baseUrl + "/api/add-power-user", agentData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          getAgent();
          generateUserName();
          toast.success(resp.message);
          setUserName("");
          setName("");
          setPassword("");
          setMobile("");
        } else {
          toast.error(resp.message);
        }
      });
  };

  const handleFormChange = () => {};

  const getAgent = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-power-user", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const generateUserName = () => {
    axios.get(baseUrl + "/api/generate-username-poweruser", {}).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data);
      }
    });
  };

  const columns = [
    {
      key: "",
      text: "S. No.",
      className: "action",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "username",
      text: "User",
      className: "user",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_no",
      text: "Mobile No.",
      className: "mobile_no",
      align: "left",
      sortable: true,
    },

    {
      key: "unc_pass",
      text: "Password",
      className: "password",
      align: "left",
      sortable: true,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <Nav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Power User</h1>
              <div className="client-tab">
                <nav>
                  <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <button
                      className="btn btn-primary"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Create
                    </button>
                  </div>
                </nav>
              </div>
            </div>
            <div className="client-form mb-5">
              <div
                className="tab-content p-3 border bg-light"
                id="nav-tabContent"
              >
                <div
                  className={"tab-pane fade"}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            onChange={handleChange}
                            name="username"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            name="name"
                            value={name}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            name="password"
                            value={password}
                          />
                          {passwordErr && (
                            <span className="" style={{ color: "red" }}>
                              {passwordErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile"
                            onChange={handleChange}
                            name="mobile"
                            value={mobile}
                          />
                          {mobileErr && (
                            <span className="" style={{ color: "red" }}>
                              {mobileErr}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={handleFormChange}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success bg-dark p-3"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={clientRecords}
              columns={columns}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default AddNewUser;
