import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import Nav from "../../Common/Nav";
import ClientNav from "../Common/ClientNav";
import ReactDatatable from "@mkikets/react-datatable";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import moment from "moment";

const CompletedGames = () => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    } else {
      getMatches();
    }
  }, []);

  const columns = [
    {
      key: "",
      text: "SR No.",
      className: "action",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "match_id",
      text: "Match Id",
      className: "match_id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            <Link to={"/client/complete-games/" + record.match_id}>
              {record.match_id}
            </Link>
          </span>
        );
      },
    },
    {
      key: "match_name",
      text: "Match Name",
      className: "match_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.match_name}</span>;
      },
    },

    {
      key: "total_match_bets",
      text: "Match Bet",
      className: "total_match_bets",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.total_match_bets}</span>;
      },
    },
    {
      key: "total_session_bets",
      text: "Session Bet",
      className: "total_session_bets",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.total_session_bets}</span>;
      },
    },

    {
      key: "total",
      text: "Overall Total",
      className: "total",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            {parseFloat(record.total_session_bets) +
              parseFloat(record.total_match_bets)}{" "}
          </span>
        );
      },
    },

    {
      key: "declared",
      text: "Declared",
      className: "declared",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>Declared</span>;
      },
    },
    {
      key: "won",
      text: "Won By",
      className: "won",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>Won</span>;
      },
    },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{moment(record.created_at).format("lll")}</span>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const getMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    axios
      .post(baseUrl + "/api/gat-completed-matched-by-user", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setRecords(resp.data);
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Completed Games</h1>
          </div>
          <div className="leader-main">
            <div className="leader-outer">
              <ReactDatatable
                config={config}
                records={records}
                columns={columns}
                //   onPageChange={this.pageChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedGames;
