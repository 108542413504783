import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

const Sidebar = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userSetting, setUserSetting] = useState(null);
  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });

useEffect(()=>{
  getUserInfo();
  
},[])

const getUserInfo = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("s_token")}`,
    },
  };
  axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
    const resp = res.data;
    if (resp.status === true) {
      console.log("DATA==>"+JSON.stringify(resp.data.id));
      if(userRole === "P_USER"){
        getPowerUserPermission(resp.data.id);

      }
   
    } else {
      if (
        resp.key === "logout" &&
        localStorage.getItem("s_token") !== "null" &&
        localStorage.getItem("s_token") !== null
      ) {
        localStorage.clear();
        setTimeout(() => navigator("/"), 2000);
      }
    }
  });
};


const getPowerUserPermission = async (id) => {

  let data = JSON.stringify({
    "power_user_id": id
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/get-power-permission`,
    headers: { 
      Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      'Content-Type': 'application/json'
    },
    data : data
  };
  try{
    let response = await axios.request(config);
    // alert(JSON.stringify(response.data.data));
    setUserSetting(response.data.data)
  }catch(error){
    console.log(error);
  }
};


  return (
    <div
      className="sidebar-main position-fixed show"
      id="navbarSupportedContent"
    >
      <div className="logo-outer">
        <h1>11leoexch</h1>
      </div>
      <nav className="navbar">
        <div className="navbar-collapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              {userRole === "P_USER" && (
                <Link className="nav-link active" to={"/poweruser/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
              {userRole === "ADMIN_USER" && (
                <Link className="nav-link active" to={"/admin/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
              {userRole === "S_ADMIN" && (
                <Link className="nav-link active" to={"/super/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              )}
              {userRole === "M_ADMIN" && (
                <Link className="nav-link active" to={"/mini/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
              {userRole === "P_USER" && (
                <li className="nav-item">
                  <Link className="nav-link" to={"/poweruser/matches"}>
                    <img
                      src="/assets/img/report.png"
                      alt=""
                      className="img-fluid"
                    />
                    All Matches
                  </Link>
                </li>
              )}
              {userRole === "P_USER" && (
                <li className="nav-item">
                  <Link className="nav-link" to={"/poweruser/add-user"}>
                    <img
                      src="/assets/img/master.png"
                      alt=""
                      className="img-fluid"
                    />
                    Create New Power User
                  </Link>
                </li>
              )}
              {userRole === "M_USER" && (
                <Link className="nav-link active" to={"/master/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
              {userRole === "S_AGENT" && (
                <Link className="nav-link active" to={"/sa/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
              {userRole === "AGENT" && (
                <Link className="nav-link active" to={"/agent/dashboard"}>
                  <svg
                    class="svg-inline--fa fa-desktop"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="desktop"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                    ></path>
                  </svg>
                  Dashboard
                </Link>
              )}
            </li>
            {userRole === "M_ADMIN" ||
            userRole === "M_USER" ||
            userRole === "S_AGENT" ||
            userRole === "ADMIN_USER" ||
            userRole === "S_ADMIN" ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    class="svg-inline--fa fa-list"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="list"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z"
                    ></path>
                  </svg>
                  Masters
                </a>
                <ul className="dropdown-menu">
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/super-admin"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Super Admin
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/power-admin"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Power Admin
                      </Link>
                    </li>
                  )}
                  {/* {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/super-admin"}>
                        Super Admin
                      </Link>
                    </li>
                  )} */}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/mini-admin"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Mini Admin
                      </Link>
                    </li>
                  )}
                  {/* {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/mini-admin"}>
                        Mini Admin
                      </Link>
                    </li>
                  )} */}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/mini-admin"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Mini Admin
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/master"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/master"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/master"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Master
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/sa"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Super Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/sa"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Super Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/sa"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Super Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/agent"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/agent"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/agent"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/master/sa"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Super Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/master/agent"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/sa/agent"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Agent Master
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/client"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Client Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/client"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Client Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/client"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Client Master
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/master/client"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Client Master
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/sa/client"}>
                        <img
                          src="/assets/img/list-menue.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Client Master
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : (
              <li className="nav-item">
                <Link className="nav-link" to={"/agent/client"}>
                  <img
                    src="/assets/img/client.png"
                    alt=""
                    className="img-fluid leager-img"
                  />
                  Client Master
                </Link>
              </li>
            )}
            {userRole === "ADMIN_USER" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/admin/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "S_ADMIN" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/super/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "M_ADMIN" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/mini/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "M_USER" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/master/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "S_AGENT" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/sa/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "AGENT" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/agent/client/sports"}>
                  <svg
                    class="svg-inline--fa fa-coins"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="coins"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z"
                    ></path>
                  </svg>
                  Sport's Betting
                </Link>
              </li>
            )}
            {userRole === "M_ADMIN" ||
            userRole === "S_ADMIN" ||
            userRole === "ADMIN_USER" ||
            userRole === "M_USER" ||
            userRole === "S_AGENT" ||
            userRole === "AGENT" ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    class="svg-inline--fa fa-file-lines"
                    style={{ width: "20px", marginRight: "5px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-lines"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                    ></path>
                  </svg>
                  Ledger
                </a>
                <ul className="dropdown-menu">
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/main/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "20px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/main/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/main/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/master/admin/ledger"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/sa/master/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/agent/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        My Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/sa/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Super Admin Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/super/sa/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Mini Admin Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/mini/sa/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Master Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/master/sa/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Super Agent Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/sa/agent/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Agent Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "AGENT" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/agent/client/settlement"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        All Agent Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/master/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/agent/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Total Profit
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Super Admin Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/super/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Mini Admin Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Master Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "M_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/master/sa/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Super Agent Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "S_AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/sa/agent/ledger"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Agent Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "AGENT" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/agent/client/ledger"}
                      >
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        Client Ledger
                      </Link>
                    </li>
                  )}
                  {userRole === "AGENT" && (
                    <li>
                      <Link className="dropdown-item" to={"/agent/commissionr"}>
                        <svg
                          class="svg-inline--fa fa-file-lines"
                          style={{ width: "15px", marginRight: "5px" }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-lines"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                          ></path>
                        </svg>
                        कमीशन लेन देन
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
            {userRole === "M_ADMIN" ||
            userRole === "S_ADMIN" ||
            userRole === "ADMIN_USER" ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Settings
                </a>
                <ul className="dropdown-menu">
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/announcement"}
                      >
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/match-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Match Managment
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/super/announcement"}
                      >
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/announcement"}>
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {
            userRole === "P_USER" && userSetting!=null && userSetting.setting==1 ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Settings
                </a>
                <ul className="dropdown-menu">
                  {userRole === "P_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/poweruser/announcement"}
                      >
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                  {userRole === "P_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/poweruser/match-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Match Managment
                      </Link>
                    </li>
                  )}
                  {userRole === "S_ADMIN" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/super/announcement"}
                      >
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                  {userRole === "M_ADMIN" && (
                    <li>
                      <Link className="dropdown-item" to={"/mini/announcement"}>
                        <img
                          src="/assets/img/anc.png"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Announcement
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}


            {userRole === "ADMIN_USER" && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Api Managment
                </a>

                <ul className="dropdown-menu">
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/sports"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Sports
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/in-play"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        In Play
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/api-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Casino
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {userRole === "P_USER" && userSetting!=null && userSetting.default_setting==1 && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Default Setting
                </a>

                <ul className="dropdown-menu">
                  {userRole === "P_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/poweruser/sports"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Cricket
                      </Link>
                    </li>
                  )}
                  {userRole === "P_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/poweruser/in-play"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Soccer
                      </Link>
                    </li>
                  )}
                  {userRole === "P_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/poweruser/api-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Tennis
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {userRole === "P_USER" && userSetting!=null && userSetting.api_mangement==1 && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Api Managment
                </a>

                <ul className="dropdown-menu">
                  {userRole === "P_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/poweruser/sports"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Sports
                      </Link>
                    </li>
                  )}
                  {userRole === "P_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/poweruser/in-play"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        In Play
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/api-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Casino
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {userRole === "ADMIN_USER" && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/settings.png"
                    alt=""
                    className="img-fluid"
                  />
                  Default Setting
                </a>

                <ul className="dropdown-menu">
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/sports"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Cricket
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link className="dropdown-item" to={"/admin/in-play"}>
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Soccer
                      </Link>
                    </li>
                  )}
                  {userRole === "ADMIN_USER" && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/admin/api-managment"}
                      >
                        <img
                          src="/assets/img/match-managment.webp"
                          alt=""
                          className="img-fluid leager-img"
                        />
                        Tennis
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {userRole === "ADMIN_USER" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/admin/sa/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Super Admin Report
                </Link>
              </li>
            )}
            {userRole === "S_ADMIN" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/super/sa/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Mini Admin Report
                </Link>
              </li>
            )}
            {userRole === "M_ADMIN" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/mini/sa/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Mini Admin Report
                </Link>
              </li>
            )}
            {userRole === "M_USER" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/master/sa/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Super Agent Report
                </Link>
              </li>
            )}
            {userRole === "S_AGENT" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/sa/agent/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Agent Report
                </Link>
              </li>
            )}
            {userRole === "AGENT" && (
              <li className="nav-item">
                <Link className="nav-link" to={"/agent/client/final/report"}>
                  <img
                    src="/assets/img/report.png"
                    alt=""
                    className="img-fluid"
                  />
                  All Client Report
                </Link>
              </li>
            )}
            {userRole === "M_ADMIN" ||
            userRole === "S_ADMIN" ||
            userRole === "ADMIN_USER" ||
            userRole === "M_USER" ||
            userRole === "S_AGENT" ||
            userRole === "AGENT" ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/assets/img/data.png"
                    alt=""
                    className="img-fluid"
                  />
                  OLD DATA
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Sep 2023
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      May 2023
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Feb 2023
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Oct 2022
                    </a>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
