import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import AdminNav from "../Common/AdminNav";
import ReactDatatable from "@mkikets/react-datatable";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const SportsMatchList = () => {
  const [clientRecords, setClientRecords] = useState([]);
  const [filtredRecords, setFiltredRecords] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sportsList, setSportsList] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAll = () => {
    const allChecked = !selectAllChecked;
    setSelectAllChecked(allChecked);
    const allRows = clientRecords; // Replace with your actual data array
    const selectedRows = allChecked ? allRows : [];
    if (selectedRows.length > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    setSelectedRows(selectedRows);
  };

  const handleToggleSelection = (row) => {
    const isSelected = selectedRows.some((r) => r.match_id === row.match_id);

    if (isSelected) {
      // If row is already selected, remove it
      setSelectedRows(selectedRows.filter((r) => r.match_id !== row.match_id));
    } else {
      // If row is not selected, add it
      setSelectedRows([...selectedRows, row]);
    }
  };
  
  const columns = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          className="select-checkbox"
          checked={selectedRows.some((r) => r.match_id === row.match_id)}
          onChange={() => handleToggleSelection(row)}
        />
      ),
      key: "checkbox",
      text: (
        <div>
          <input
            type="checkbox"
            className="select-all-checkbox"
            checked={selectAllChecked}
            onChange={() => handleSelectAll()}
          />
          S.No
        </div>
      ),
      className: "checkbox",
      sortable: false,
    },
    {
      key: "match_name",
      text: "Name",
      className: "credit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={`/admin/in-play-game/${record.match_id}`}>
              {record.match_name}
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "match_id",
      text: "Match Code",
      className: "credit",
      align: "left",
      sortable: true,
    },
    {
      key: "open_date",
      text: "Start Date",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.open_date).format("lll")}</Fragment>;
      },
    },
    {
      key: "series_id",
      text: "Sport",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{record.sportName}</Fragment>;
      },
    },
    {
      key: "winner",
      text: "Winner",
      className: "debit",
      align: "left",
      sortable: true,
    },
    {
      key: "debit",
      text: "Status",
      className: "debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.is_approved === "Y" ? "Active" : "Inactive"}
          </Fragment>
        );
      },
    },
    {
      key: "balance",
      text: "Action.",
      className: "balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn"
              style={{ marginRight: "5px" }}
              onClick={() => changeStatus(record.match_id, "Y")}
            >
              Enable
            </button>
            <button
              className="btn"
              onClick={() => changeStatus(record.match_id, "N")}
            >
              Disable
            </button>
          </Fragment>
        );
      },
    },
  ];

  const changeStatus = (id, status) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    const updateData = {
      status: status,
      id: id,
    };

    axios
      .post(baseUrl + "/api/change-status-match", updateData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getAllMatches();
        } else {
          toast.error(resp.message);
        }
      });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  useEffect(() => {
    getAllMatches();
    getAllSportsList();
  }, []);

  const getAllMatches = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-matches", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientRecords(resp.data);
      }
    });
  };

  const getAllSportsList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-all-sports", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setSportsList(resp.data);
      }
    });
  };

  const handleFilterChange = (e) => {
    const selectedSport = e.target.value;
    var html = [];

    clientRecords.map((value) => {
      if (e.target.value == value.sportName) {
        html.push(value);
      }

      if (e.target.value == value.is_approved) {
        html.push(value);
      }

      if (e.target.value == "all") {
        html.push(value);
      }
    });
    setSelectedFilter(selectedSport);
    setFiltredRecords(html);
  };

  const fetchMatchDataNew = () => {
    axios.get(baseUrl + "/api/fetchMatchesAsPerSport", {}).then((res) => {
      const resp = res.data;
    });
  };

  const handleChageStatus = (e) => {
    const { value } = e.target;
    setSelectedStatus(value);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    let data;
    if (isAllSelected === true) {
      data = {
        status: value,
        isAllMatch: isAllSelected,
      };
    } else {
      data = {
        multipleMatch: selectedRows,
        status: value,
        isAllMatch: isAllSelected,
      };
    }

    axios
      .post(baseUrl + "/api/change-match-multiple", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          getAllMatches();
          setSelectedRows([]);
          setSelectedStatus("");
        }
      });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <AdminNav />
        <div className="client-master-outer">
          <div className="container-fluid">
            <div className="page-heading d-flex flex-wrap aligh-items-center justify-content-between pt-3">
              <h1>Sport Matches</h1>
              {selectedRows.length > 0 && (
                <div>
                  <select
                    value={selectedStatus}
                    onChange={handleChageStatus}
                    className="form-select"
                  >
                    <option value="">Select Action</option>
                    <option value="Y">Enable</option>
                    <option value="N">Disable</option>
                    {/* Add more filter options as needed */}
                  </select>
                </div>
              )}
              <div>
                <select
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  className="form-select"
                >
                  <option value="all">All</option>
                  <option value="Y">Active</option>
                  <option value="N">In Active</option>
                  {/* Add more filter options as needed */}
                </select>
              </div>
              <div className="mb-2">
                <button className="btn btn-primary" onClick={fetchMatchDataNew}>
                  Fetch Data
                </button>
              </div>
              <div className="filter-options">
                <select
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  className="form-select"
                >
                  <option value="all">All</option>
                  {sportsList.map((item) => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                  {/* Add more filter options as needed */}
                </select>
              </div>
            </div>
            <ReactDatatable
              config={config}
              records={filtredRecords ? filtredRecords : clientRecords}
              columns={columns}
              show_pagination={true}
              //   onPageChange={this.pageChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default SportsMatchList;
