import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import Nav from "../../Common/Nav";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import ClientNav from "../Common/ClientNav";

const Profile = () => {
  const [clientCode, setClientCode] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    getClientInformation();
  }, []);

  const getClientInformation = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/get-client", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setClientCode(resp.data.username);
        setName(resp.data.name);
        setMobile(resp.data.mobile_no);
        setCreatedAt(resp.data.createdAt);
      }
    });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Profile</h1>
          </div>
          <div className="profile-main">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="profile-left">
                  <img
                    src="/assets/img/my-profile.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-12">
                <div className="profile-right">
                  <div className="row">
                    <div className="col-md-6 col-12 more-space">
                      <div className="rate-info">
                        <h5>Rate Difference : </h5>
                        <form>
                          <div className="form-group">
                            <select className="form-select">
                              <option>1</option>
                              <option selected="selected">2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </select>
                          </div>
                          <div className="update-btn">
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 more-space">
                      <div className="personal-info company-info">
                        <h5>Personal Information</h5>
                        <ul className="nav">
                          <li>
                            Client Code : <b>{clientCode}</b>
                          </li>
                          <li>
                            Client Name : <b>{name}</b>
                          </li>
                          <li>
                            Contact No. : <b>{mobile}</b>
                          </li>
                          <li>
                            Date Of Joining : <b>{createdAt}</b>
                          </li>
                          <li>
                            Address : <b>India</b>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="personal-info">
                        <h5>Company Information</h5>
                        <ul className="nav">
                          <li>
                            Help Line No. : <b>+91 9451236687</b>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
