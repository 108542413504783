import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./BaseUrl";
import { Modal, Button, Table, Dropdown } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
const AdminNav = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState(null);

  const [announcements, setAnnouncements] = useState([]);
  const [showModal, setShowModal]=useState(false)
  const [limit, setLimit] = useState("");
  const [limitError,setLitmitError]=useState("");

  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem("s_role");
  });

  const getAllAccouncment = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios
      .post(baseUrl + "/api/fetch-announcemtnt-super-admin", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setAnnouncements(resp.data);
        }
      });
  };

  useEffect(() => {
    // Assuming you have the JWT token stored in local storage
    const localRole = localStorage.getItem("s_role");

    // Decode the JWT token to get user information, including the role

    // Extract the user role from the decoded token
    const role = localRole ? localRole : null;

    // Set the user role in state
    setUserRole(role);
  }, [userRole]);

  useEffect(() => {
    getUserInfo();
    getAllAccouncment();
  }, []);

  const getUserInfo = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };
    axios.post(baseUrl + "/api/user-info", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setUserName(resp.data.username);
        setUserData(resp.data);
        setLimit(resp.data.current_limit);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("s_token") !== "null" &&
          localStorage.getItem("s_token") !== null
        ) {
          localStorage.clear();
          setTimeout(() => {
            if (userRole === "CLIENT") {
              navigate("/");
            } else {
              navigate("/admin");
            }

          }, 2000);
          
          // setTimeout(() => navigator("/"), 2000);
        }
      }
    });
  };

  const onChangeDropDown = (e) => {
    const { value } = e.target;
  
    if (value === "2") {
      logout();
    }
    if(value=="3"){
      setShowModal(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!limit){
      setLitmitError("This field is required");
      return;
    }
  }

  const logout = () => {
    localStorage.clear();
    if (userRole === "CLIENT") {
      navigate("/");
    } else {
      navigate("/admin");
    }
  };
  const handleCloseModal=()=>{

    setShowModal(false);
  }
  const handleFormChange = (e, actionType) => {
    if (actionType === "create") {
     
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("s_token")}`,
        },
      };
      const data = {
        clientId: userData.id,
        total_limit: "16590.00000000",
        current_limit:limit
      };
      axios
        .post(baseUrl + "/api/update-limit-admin", data, config)
        .then((res) => {
          const resp = res.data;
          console.log("update ====>"+JSON.stringify(resp.message))
          
          if (resp.success === true) {
            // alert(resp.message)
            getUserInfo();
            toast.success(resp.message);
            setLimit('');
            setLitmitError("");
            handleCloseModal();
           
          }
        });

    
    }else if (actionType === "close") {
      setLimit('');
      setLitmitError("");
      handleCloseModal();
    }

  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "limit") {
      setLimit(value);
      setLitmitError("");
    }
  }
  return (
    <>
      <div className="top-bar">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-3 col-4">
            <div className="topbar-left">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div className="col-md-2 col-8">
            <div className="topbar-right form-group m-0">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={onChangeDropDown}
              >
                <option selected value={"selected"}>
                  {userName}
                </option>
                <option value="1">Change Password</option>
                <option value="3">Update Limit</option>
                <option value="2">Logout</option>
              </select>
            </div>
          </div>
        </div>
       
      </div>
      <div className="marquee-container">
        {/* Check if there are announcements to display */}
        {announcements.length > 0 && (
          <marquee behavior="scroll" direction="left" scrollamount="8">
            {announcements
              .filter((announcement) => announcement.status === "active")
              .map((announcement, index, array) => (
                <span key={announcement.id}>
                  {announcement.content}
                  {index < array.length - 1 && <span>&emsp;&bull;&emsp;</span>}
                </span>
              ))}
          </marquee>
        )}
      </div>
         <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{'UPDATE LIMIT'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                  
                      <div className="col-md-12 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Limit"
                            onChange={handleChange}
                            name="limit"
                            value={limit}
                          />
                          {limitError && (
                            <span className="" style={{ color: "red" }}>
                              {limitError}
                            </span>
                          )}
                        </div>
                     
                    
                    
                      </div>
                      <div className="col-12">
                        <div className="form-button d-flex justify-content-end">
                          <button
                            className="btn btn-primary me-3"
                            onClick={(e) => handleFormChange(e, "close")}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            onClick={(e) => handleFormChange(e, "create")}
                            className="btn btn-success bg-dark p-3"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </Modal.Body>       
                  </Modal>
                  <Toaster/>
    </>
  );
};

export default AdminNav;
