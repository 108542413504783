import React, { useState } from "react";
import Nav from "../../Common/Nav";
import Sidebar from "../../Common/Sidebar";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import ClientNav from "../Common/ClientNav";

const ChangePassword = () => {
  const [odlPassword, setOdlPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldpassword") {
      setOdlPassword(value);
    }
    if (name === "newpassword") {
      setNewPassword(value);
    }
    if (name === "confirmpassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    const data = {
      odlPassword: odlPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    axios.post(baseUrl + "/api/change-password", data, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setOdlPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div className="match-detail">
          <div className="section-heading">
            <h1>Change Password</h1>
          </div>
          <div className="change-pass">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label>Old Password</label>
                  <input
                    type="password"
                    name="oldpassword"
                    placeholder="Enter Old Password"
                    className="form-control"
                    value={odlPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="password"
                    name="newpassword"
                    placeholder="Enter New Password"
                    className="form-control"
                    onChange={handleChange}
                    value={newPassword}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    name="confirmpassword"
                    placeholder="Confirm New Password"
                    className="form-control"
                    onChange={handleChange}
                    value={confirmPassword}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="pass-btn text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
