import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import ClientNav from "../Common/ClientNav";
import ReactDatatable from "@mkikets/react-datatable";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import moment from "moment";
const ClientLeadger = () => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("s_token");
    if (!token) {
      navigate("/");
    } else {
      getLedger();
    }
  }, []);

  const columns = [
    {
      key: "",
      text: "SR No.",
      className: "action",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "match_name",
      text: "Match Name",
      className: "match_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.match_name}</span>;
      },
    },
    {
      key: "won_by",
      text: "Won By",
      className: "won_by",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.won_by}</span>;
      },
    },
    {
      key: "won",
      text: "Won",
      className: "won",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.won}</span>;
      },
    },
    {
      key: "lost",
      text: "Lost",
      className: "lost",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.lost}</span>;
      },
    },

    {
      key: "balance",
      text: "Balance",
      className: "balance",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{parseFloat(record.balance)}</span>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const getLedger = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("s_token")}`,
      },
    };

    axios.post(baseUrl + "/api/get-user-ledger", {}, config).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setRecords(resp.data);
      }
    });
  };

  return (
    <div className="main">
      <Sidebar />
      <div className="main-right">
        <ClientNav />
        <div class="match-detail">
          <div class="section-heading">
            <h1>Ledger</h1>
          </div>
          <div class="leader-main">
            <div class="leader-outer">
              <ReactDatatable
                config={config}
                records={records}
                columns={columns}
                //   onPageChange={this.pageChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLeadger;
